import cx from 'classnames';
import { createColumnHelper } from '@tanstack/react-table';
import ChipGroup from 'components/shared/ChipGroup';
import ExternalUrl from 'components/shared/ExternalUrl';
import AllFiltersChip from 'components/shared/newfilters/AllFiltersChip';
import FilterChip from 'components/shared/newfilters/FilterChip';
import BooleanFilterChip from 'components/shared/newfilters/BooleanFilterChip';
import { DataTableContent, useFilteredRows, useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import DataTable from 'components/shared/Table/DataTable';
import { CurrencyCell, InputCell, IntegerCell, PercentCell, RouteLinkCell } from 'components/shared/Table/Cells';
import { dataTableMeta, enableEditing, selectRowOnClick } from 'components/shared/Table/table.helpers';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import { formatInteger } from 'components/utils';
import { compact, mean, set, sumBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import InventorySideNav, { SideNavRail, useCurrentSideNavPanel } from 'components/inventory/InventorySideNav';
import DataTableConfigPane from './DataTableConfigPane';
import HomeBuilderFilter from './HomeBuilderFilter';
import { useFetchSubdivisionsQuery, useUpdateSubdivisionsMutation } from '../../../redux/subdivisionApiSlice';
import { cityColumn, enabledColumn, homeBuilderNameColumn, marketColumn, NEW_BUILD_DEFAULT_COLUMN, stateColumn, subdivisionNameColumn, zipColumn } from './newBuildInventory';
import { DetailField, DetailFields, DetailPaneHeading, DetailPaneNoData } from './NewBuildInventoryDetail';
import SideNavPaneContainer from './SideNavPaneContainer';
import SubdivisionMarketFilter from './SubdivisionMarketFilter';
import TableEditControls from './TableEditControls';
import PublishedFilter from './PublishedFilter';

const TABLE_ID = 'newBuildInventorySubdivisions';

const finiteOrUndefined = (value) => (Number.isFinite(value) ? value : undefined);

const columnHelper = createColumnHelper();

const amenitiesMutator = (row, value) => {
  set(row, 'amenities', value.split(',').map(amenitity => amenitity.trim()));
};

const columns = [
  columnHelper.display({
    id: 'subdivisionLink',
    cell: RouteLinkCell,
    meta: {
      linkPath: '/subdivisions/:id',
      ...dataTableMeta.disableTableConfig,
    },
  }),
  // TODO: link to home model page with filter set to current subdivision
  enabledColumn(),
  homeBuilderNameColumn('homeBuilder'),
  marketColumn(),
  subdivisionNameColumn(),
  cityColumn(),
  stateColumn(),
  zipColumn(),
  columnHelper.accessor('annualHoa', {
    header: 'Annual HOA',
    cell: CurrencyCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('taxRate', {
    header: 'Tax Rate',
    cell: PercentCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'percent', precision: 2 }),
      ...dataTableMeta.textRight,
      precision: 2,
    },
  }),
  columnHelper.accessor(row => row.homeModels.length, {
    id: 'numHomeModels',
    header: 'Home Models',
    cell: IntegerCell,
    meta: {
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor(row => finiteOrUndefined(mean(compact(row.homeModels.map(hm => hm.priceMin)))), {
    id: 'avgPrice',
    header: 'Avg Price',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor(row => finiteOrUndefined(mean(compact(row.homeModels.map(hm => hm.marketRent)))), {
    id: 'avgRent',
    header: 'Avg Rent',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor(row => finiteOrUndefined(mean(compact(row.homeModels.map(hm => hm.stabilizedYield)))), {
    id: 'avgYield',
    header: 'Avg Yield',
    cell: PercentCell,
    meta: {
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('elementarySchoolRating', {
    header: 'Elementary School Rating',
    cell: IntegerCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'number' }),
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('juniorHighSchoolRating', {
    header: 'Junior High School Rating',
    cell: IntegerCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'number' }),
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('highSchoolSchoolRating', {
    header: 'High School School Rating',
    cell: IntegerCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'number' }),
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 3 }),
    },
  }),
  columnHelper.accessor('restrictions', {
    header: 'Restrictions',
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 3 }),
    },
  }),
  columnHelper.accessor(({ amenities }) => amenities.join(', '), {
    header: 'Amenities',
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({
        cell: InputCell,
        inputType: 'text-area',
        rows: 3,
      }),
      mutatorFn: amenitiesMutator,
    },
  }),
  columnHelper.accessor('internalNotes', {
    header: 'Internal Notes',
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 3 }),
    },
  }),
];

function StatItem({ label, value }) {
  return (
    <div className="px-4 text-center">
      <div className="text-lg">{value || '-'}</div>
      <div className="text-xs font-light">{label}</div>
    </div>
  );
}

const calculateStats = data => [{
  label: '# of Subdivisions',
  value: formatInteger(data.length),
}, {
  label: '# Published',
  value: formatInteger(data.filter(d => d.enabled).length),
}, {
  label: '# Home Models',
  value: formatInteger(sumBy(data, d => d.homeModels.length)),
}];

function SummaryStats({ className }) {
  const rows = useFilteredRows();
  const stats = useMemo(() => (rows ? calculateStats(rows.map(d => d.original)) : []), [rows]);

  return (
    <div className={cx('flex gap-x-4', className)}>
      {stats.map(stat => (
        <StatItem key={stat.label} {...stat} />
      ))}
    </div>
  );
}

function SubdivisionDetailPane() {
  const [row] = useFilteredSelectedRows() ?? [];
  const {
    original: {
      name,
      city,
      state,
      zip,
      enabled,
      market,
      restrictions,
      description,
      amenities,
      homeBuilder = {},
    } = {},
  } = row ?? {};

  if (useCurrentSideNavPanel() !== 'detail') {
    return null;
  }

  return row ? (
    <>
      <DetailPaneHeading subheading={`${city}, ${state} ${zip}`}>
        {name}
      </DetailPaneHeading>

      <DetailFields>
        <DetailField label="Enabled">{enabled ? 'Enabled' : 'Disabled'}</DetailField>
        <DetailField label="Market">{market}</DetailField>
        <DetailField label="Restrictions">{restrictions}</DetailField>
        <DetailField label="Description">{description}</DetailField>
        <DetailField label="Amenities">{amenities?.join(', ') || '-'}</DetailField>
        <DetailField label="Builder">{homeBuilder.name}</DetailField>
        <DetailField label="Builder Website">
          {homeBuilder.website && (
            <ExternalUrl href={homeBuilder.website} className="max-w-full truncate">
              {homeBuilder.website}
            </ExternalUrl>
          )}
        </DetailField>
        <DetailField label="Description">{homeBuilder.description}</DetailField>
      </DetailFields>
    </>
  ) : (
    <DetailPaneNoData />
  );
}

function SubdivisionTable({ edit, children }) {
  const { currentData, isFetching, isUninitialized } = useFetchSubdivisionsQuery({ include: ['homeBuilder', 'homeModels'] });

  return (
    <DataTable
      virtual
      columns={columns}
      data={currentData}
      defaultColumn={NEW_BUILD_DEFAULT_COLUMN}
      isLoading={isUninitialized || isFetching}
      tableContainerClassName="flex-1 whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      onRowClick={selectRowOnClick}
      enableMultiRowSelection={false}
      enableEditing={edit}
    >
      {children}
    </DataTable>
  );
}

function EditControls({ isEditMode, setIsEditMode, className }) {
  const [
    updateSubdivisions,
    {
      isLoading,
      isSuccess,
      isError,
      error,
      reset: resetMutation,
    },
  ] = useUpdateSubdivisionsMutation();

  const onEditToggle = useCallback(() => {
    resetMutation();
  }, [resetMutation]);

  const onSave = useCallback((subdivisions) => {
    updateSubdivisions({ subdivisions });
  }, [updateSubdivisions]);

  return (
    <TableEditControls
      className={className}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      isUpdating={isLoading}
      updateSucceeded={isSuccess}
      updateFailed={isError}
      updateError={error?.data?.error}
      onEditToggle={onEditToggle}
      onSave={onSave}
    />
  );
}

export default function NewBuildInventorySubdivisions() {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <SubdivisionTable edit={isEditMode}>
      <div className="flex justify-between items-center py-1.5 px-3 border-b">
        <div>
          <ReactTableFilter>
            <ChipGroup>
              <ReactTableColumnFilter
                label="Builder"
                columnId="homeBuilder_name"
                displayValue="name"
              >
                <FilterChip>
                  <HomeBuilderFilter />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Market"
                columnId="market"
              >
                <FilterChip>
                  <SubdivisionMarketFilter />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Published"
                staticDisplayValue="Published"
                columnId="enabled"
              >
                <BooleanFilterChip>
                  <PublishedFilter />
                </BooleanFilterChip>
              </ReactTableColumnFilter>
              <AllFiltersChip />
            </ChipGroup>
          </ReactTableFilter>
        </div>
        <SummaryStats className="grow justify-center" />
        <EditControls isEditMode={isEditMode} setIsEditMode={setIsEditMode} className="ml-auto justify-self-end" />
      </div>
      <div className="flex flex-row h-0 flex-1">
        <DataTableContent />
        <InventorySideNav>
          <SideNavPaneContainer>
            <SubdivisionDetailPane />
            <DataTableConfig tableId={TABLE_ID}>
              <DataTableConfigPane />
            </DataTableConfig>
          </SideNavPaneContainer>
          <SideNavRail page="subdivision" />
        </InventorySideNav>
      </div>
    </SubdivisionTable>
  );
}
