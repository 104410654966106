import { Outlet, useOutletContext } from 'react-router-dom';
import RightNav from 'components/RightNav/Index';
import ModalDisplayer from './ModalDisplayer';
import Navigation from './Navigation';

export default function DealLayout() {
  const context = useOutletContext();
  const { data: { currentUser } } = context;
  const canTakeDealActions = !currentUser.organization.config.importOffMarketMarketplace;

  return (
    <>
      <Navigation canTakeDealActions={canTakeDealActions} />
      <div className="w-full flex">
        <Outlet context={context} />
        {canTakeDealActions && (
          <div className="flex-none">
            <RightNav />
          </div>
        )}
      </div>
      <ModalDisplayer />
    </>
  );
}
