import { DetailPaneHeading, DetailPaneNoData } from 'components/Import/NewBuild/NewBuildInventoryDetail';
import { useCurrentSideNavPanel } from 'components/inventory/InventorySideNav';
import { useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import OffMarketListingNotes from './OffMarketListingNotes';

export default function ListingNotesPane() {
  const show = useCurrentSideNavPanel() === 'notes';
  const [row] = useFilteredSelectedRows() ?? [];

  if (!show) {
    return null;
  }

  return (
    <>
      <DetailPaneHeading>Notes</DetailPaneHeading>
      {row ? (
        <div className="size-full overflow-y-auto overflow-x-clip">
          <OffMarketListingNotes listingId={row.id} organizationId={row.getValue('marketplaceOrganization_id')} />
        </div>
      ) : (
        <DetailPaneNoData>
          Select a listing to see notes
        </DetailPaneNoData>
      )}
    </>
  );
}
