import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function TabPanel({ heading, children }) {
  const panelRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    panelRef.current?.scroll(0, 0);
  }, [location.pathname]);

  return (
    <div ref={panelRef} className="flex-none w-200 h-full overflow-auto">
      <div className="my-4">{heading}</div>
      {children}
    </div>
  );
}
