import Modal from 'components/Modal';
import { ListingContent } from 'components/listing/Details';
import RoundButton from 'components/common/RoundButton';
import { X } from 'components/icons';
import { formatListingSource } from 'components/utils';

export default function ListingModal({ dismiss, listing, isLease }) {
  const { listingId, source, sourceId, streetAddress } = listing;

  return (
    <Modal
      fullScreen
      show
      backgroundColor="bg-transparent"
      showCloseAction={false}
      onClose={dismiss}
    >
      <div className="flex w-auto h-full p-6 items-center justify-center" onClick={dismiss}>
        <div className="w-10/12 h-full overflow-y-scroll bg-white rounded-xl" onClick={(e) => e.stopPropagation()}>
          <div className="flex justify-between items-center w-full p-6">
            <div>
              <div className="text-xl text-gray-900">{streetAddress}</div>
              <div className="pt-1 text-gray-500 text-xs">{`${formatListingSource(source)} #${listingId || sourceId}`}</div>
            </div>
            <div className="h-12 w-12 align-top">
              <RoundButton name="outputModalButton" padding="p-2 border border-neutral-dark border-opacity-12" onClick={dismiss}>
                <X className="w-7" />
              </RoundButton>
            </div>
          </div>
          <ListingContent listing={listing} isLease={isLease} />
        </div>
      </div>
    </Modal>
  );
}
