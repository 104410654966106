import { createColumnHelper } from '@tanstack/react-table';
import Button from 'components/shared/NewButton';
import { isNil, snakeCase } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from 'components/Alert';
import { Check, X } from 'components/icons';
import { EnumCell, InputCell } from 'components/shared/Table/Cells';
import DataTable from 'components/shared/Table/DataTable';
import { DataTableContent, useGetUpdatedRowData } from 'components/shared/Table/DataTableContext';
import { dataTableMeta, enableEditing } from 'components/shared/Table/table.helpers';
import { formatCurrency } from 'components/utils';
import {
  useCommitOffMarketMarketplaceImportMutation,
  useFetchOffMarketMarketplaceImportJobQuery,
  useImportOffMarketMarketplaceCsvMutation,
  usePrepareOffMarketMarketplaceImportMutation,
} from '../../redux/offMarketMarketplaceImportJobApi';

const STEP_UPLOAD = Symbol('');
const STEP_VALIDATE = Symbol('');
const STEP_CONFIRM = Symbol('');

const NEXT_STEP = {
  [STEP_UPLOAD]: STEP_VALIDATE,
  [STEP_VALIDATE]: STEP_CONFIRM,
  [STEP_CONFIRM]: STEP_UPLOAD,
};

const PREV_STEP = {
  [STEP_VALIDATE]: STEP_UPLOAD,
  [STEP_CONFIRM]: STEP_VALIDATE,
};

const CSV_DATA_ID_COL = snakeCase('lineNumber');
const PREPARED_DATA_ID_COL = '_line_number';

const columnHelper = createColumnHelper();

const openTemplateUrl = () => {
  window.open('/off_market_marketplace/import_template.csv', '_blank');
};

/** @type {import('@tanstack/react-table').TableOptions['getRowId']} */
const getCsvDataRowId = (originalRow) => originalRow[CSV_DATA_ID_COL];

/** @type {import('@tanstack/react-table').TableOptions['getRowId']} */
const getPreparedDataRowId = (originalRow) => originalRow[PREPARED_DATA_ID_COL];

const useFilePicker = () => {
  /** @type {import('react').RefObject<HTMLInputElement>} */
  const inputRef = useRef();
  const showFilePicker = useCallback(() => {
    inputRef.current.value = null;
    inputRef.current.showPicker();
  }, []);

  return useMemo(() => ({ inputRef, showFilePicker }), [showFilePicker]);
};

function ImportUpload({ forward, setCsvData }) {
  const { inputRef, showFilePicker } = useFilePicker();

  const [trigger, { isLoading, isSuccess, data, error, reset }] = useImportOffMarketMarketplaceCsvMutation();
  const [validationError, setValidationError] = useState();

  /** @type {import('react').ChangeEventHandler<HTMLInputElement>} */
  const fileOnChange = useCallback((evt) => {
    reset();
    setValidationError(undefined);

    if (evt.target.files.length !== 1) {
      setValidationError('Please upload a single CSV at a time.');
      return;
    }

    const file = evt.target.files[0];
    if (file.type !== 'text/csv' || file.size === 0) {
      setValidationError('Upload file must be a CSV file.');
      return;
    }

    trigger({ file });
  }, [reset, trigger]);

  useEffect(() => {
    setCsvData(data);
  }, [data, setCsvData]);

  useEffect(() => {
    if (isSuccess) {
      forward();
    }
  }, [isSuccess, forward]);

  const errMsg = validationError ?? error?.error ?? error?.data?.error;

  return (
    <div className="flex flex-col gap-y-4 w-128">
      <p className="text-sm text-gray-700">Import Off-Market Listings</p>
      <p className="leading-relaxed font-light mb-6">Use the <i>Download Template</i> button to download an empty template of the required import format. Import files must be of CSV file type. Reach out to the Honeycomb team for any additional questions on the import process.</p>
      {errMsg && <Alert className="whitespace-pre-line" type="danger" text={errMsg} />}
      <div className="flex justify-between items-center">
        <Button
          outlined
          label="Download Template"
          onClick={openTemplateUrl}
        />
        <Button
          filled
          label="Import"
          isLoading={isLoading}
          onClick={showFilePicker}
        />

        <input
          ref={inputRef}
          type="file"
          accept="text/csv"
          className="hidden"
          onChange={fileOnChange}
        />
      </div>
    </div>
  );
}

function ImportValidate({ forward, back, csvData: { csvRows, userColumnOrder }, setCsvData, setPreparedData }) {
  const columns = useMemo(() => [
    columnHelper.accessor(CSV_DATA_ID_COL, { header: 'Line', meta: { ...dataTableMeta.textRight } }),
    ...Object.entries((userColumnOrder ?? {})).map(([key, value]) => (
      columnHelper.accessor(key, {
        header: value,
        meta: {
          ...enableEditing({ cell: InputCell, inputType: 'text' }),
        },
      })
    )),
  ], [userColumnOrder]);

  const [trigger, { isLoading, isSuccess, data, error, reset }] = usePrepareOffMarketMarketplaceImportMutation();
  const { currentData: preparedData, isFetching: importJobIsFetching } = useFetchOffMarketMarketplaceImportJobQuery({ id: data?.id }, {
    skip: !isSuccess,
    // update every second
    pollingInterval: 1000,
  });

  const importPrepareProcessing = isLoading || importJobIsFetching || (isSuccess && preparedData?.importJob?.status !== 'Done');
  const importPrepareCompleted = preparedData?.importJob?.status === 'Done';

  const prepareImport = useCallback((updatedRows) => {
    reset();
    const updatedRowsById = Object.fromEntries(updatedRows.map((row) => [row[CSV_DATA_ID_COL], row]));

    setCsvData((prev) => {
      const updated = prev.csvRows.map((row) => updatedRowsById[row[CSV_DATA_ID_COL]] ?? row);
      trigger({ listings: updated });
      return { ...prev, csvRows: updated };
    });
  }, [reset, trigger, setCsvData]);

  useEffect(() => {
    if (importPrepareCompleted) {
      setPreparedData(preparedData);
      forward();
    }
  }, [importPrepareCompleted, forward, setPreparedData, preparedData]);

  const errMsg = error?.error
    ?? error?.data?.error
    // this importJob should only indicate failure when something unexpected happens, i.e., api down
    ?? (preparedData?.importJob?.status === 'Failed' ? 'Failed to process listings' : undefined);

  return (
    <DataTable
      virtual
      enableEditing
      columns={columns}
      data={csvRows}
      getRowId={getCsvDataRowId}
      tableContainerClassName="whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
    >
      <div className="flex flex-col gap-y-4 h-0 flex-1">
        {errMsg && <Alert className="whitespace-pre-line" type="danger" text={errMsg} />}
        <DataTableContent />

        <div className="flex justify-between items-center">
          <Button
            outlined
            label="Back"
            onClick={back}
          />
          <ValidateButton
            filled
            label="Validate"
            isLoading={importPrepareProcessing}
            prepareImport={prepareImport}
          />
        </div>
      </div>
    </DataTable>
  );
}

function ValidateButton({ prepareImport, isLoading, ...props }) {
  const [isPending, startTransition] = useTransition();
  const getUpdatedRows = useGetUpdatedRowData();

  const onClick = useCallback(() => {
    startTransition(() => {
      const updatedRows = getUpdatedRows({ rowSelector: ([row]) => row });
      prepareImport(updatedRows);
    });
  }, [getUpdatedRows, prepareImport]);

  return (
    <Button
      {...props}
      isLoading={isPending || isLoading}
      onClick={onClick}
    />
  );
}

/**
 * @type {import('@tanstack/react-table').ColumnDefBase['columns']}
 */
const RESULT_METADATA_COLUMNS = [
  columnHelper.accessor('_valid', {
    header: null,
    cell: EnumCell,
    meta: {
      enumDisplayValues: {
        [true]: <Check className="text-success-300 h-5 w-auto" />,
        [false]: <X className="text-error-300 h-5 w-auto" />,
      },
    },
  }),
  columnHelper.accessor('_line_number', {
    header: 'Line',
    invertSorting: true,
    sortDescFirst: true,
  }),
  columnHelper.accessor('_status', {
    header: 'Status',
    cell: EnumCell,
    meta: {
      enumDisplayValues: {
        new: 'New',
        modified: 'Updated',
        unmodified: 'Unmodified',
      },
      enumFallbackValue: '',
    },
  }),
];

const CURRENCY_FIELDS = [
  'AssociationFee',
  'ListPrice',
  'RenovationEstimate',
];

const formatResult = (data, column) => {
  const { id } = column;

  if (CURRENCY_FIELDS.includes(id)) {
    return formatCurrency(data);
  } else if (id.includes('YN')) {
    // show a string value for boolean fields otherwise false returns an empty string
    return data.toString();
  }
  return data;
};

function ResultCell({ getValue, column }) {
  const { data, errors } = getValue() ?? {};
  if (errors) {
    return (
      <div className="[td:has(>&)]:ring-4 [td:has(>&)]:ring-error-300 [td:has(>&)]:ring-inset">
        {data}
        <ul className="text-error-800">
          {errors.map((errMsg, idx) => (
            <li key={idx}>{errMsg}</li>
          ))}
        </ul>
      </div>
    );
  }

  if (isNil(data)) return null;

  return formatResult(data, column);
}

function ImportConfirm({ back, csvData: { userColumnOrder }, preparedData }) {
  const navigate = useNavigate();
  const columns = useMemo(() => [
    ...RESULT_METADATA_COLUMNS,
    ...Object.entries((userColumnOrder ?? {})).map(([key, value]) => (
      columnHelper.accessor(key, {
        header: value,
        cell: ResultCell,
      })
    )),
  ], [userColumnOrder]);

  const [trigger, { isLoading, isSuccess, error, reset }] = useCommitOffMarketMarketplaceImportMutation();
  const commitImport = useCallback(() => {
    reset();
    trigger({ id: preparedData.id });
  }, [preparedData.id, reset, trigger]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/off_market_marketplace/inventory');
    }
  }, [navigate, isSuccess]);

  const { resultData: [listings] } = preparedData;
  const [errorRowCount, totalRowCount] = listings.reduce(([err, total], { _valid: isValid }) => (
    [isValid ? err : err + 1, total + 1]
  ), [0, 0]);

  const errMsg = error?.error ?? error?.data?.error;

  return (
    <div className="flex flex-col gap-y-4 h-0 flex-1">
      {errMsg && <Alert className="whitespace-pre-line" type="danger" text={errMsg} />}
      <div className="flex items-center">
        <span className="font-semibold">Summary</span>
        <div className="inline-flex items-center h-6 ml-3.5 text-xs">
          {totalRowCount - errorRowCount}
          <Check className="text-success-300 h-3/4 w-auto mr-0.5" />
          {errorRowCount}
          <X className="text-error-300 h-3/4 w-auto" />
        </div>
      </div>
      <DataTable
        virtual
        columns={columns}
        data={listings}
        getRowId={getPreparedDataRowId}
        tableContainerClassName="whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      />

      <div className="flex justify-between items-center">
        <Button
          outlined
          label="Back"
          onClick={back}
        />
        <Button
          filled
          label="Confirm"
          disabled={errorRowCount > 0}
          isLoading={isLoading}
          onClick={commitImport}
        />
      </div>
    </div>
  );
}

export default function OffMarketImport() {
  const [importFlowStep, setImportFlowStep] = useState(STEP_UPLOAD);
  const [csvData, setCsvData] = useState();
  const [preparedData, setPreparedData] = useState();

  const forward = useCallback(() => {
    setImportFlowStep((prev) => NEXT_STEP[prev]);
  }, []);

  const back = useCallback(() => {
    setImportFlowStep((prev) => PREV_STEP[prev]);
  }, []);

  let flowNode;
  switch (importFlowStep) {
    case STEP_UPLOAD:
      flowNode = <ImportUpload forward={forward} setCsvData={setCsvData} />;
      break;
    case STEP_VALIDATE:
      flowNode = <ImportValidate forward={forward} back={back} csvData={csvData} setCsvData={setCsvData} setPreparedData={setPreparedData} />;
      break;
    case STEP_CONFIRM:
      flowNode = <ImportConfirm back={back} csvData={csvData} preparedData={preparedData} />;
      break;
    default:
      throw new Error('Invalid step');
  }

  return (
    <div className="flex flex-col gap-y-12 w-max max-w-container h-max max-h-container p-6 mx-auto [*:has(>&)]:pt-16 [*:has(>&)]:pb-8 [*:has(>&)]:pr-4 bg-white rounded">
      <div className="text-title-md text-neutral-dark">
        Off-Market Marketplace Import
      </div>

      {flowNode}
    </div>
  );
}
