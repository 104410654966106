import { PhotoLibrary } from 'components/icons';
import { NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';
import { InventoryNavItem } from './InventorySideNav';

export default function InventoryPhotosNavItem() {
  return (
    <InventoryNavItem name="photos">
      <NavItemIcon>
        <PhotoLibrary filled={false} className="block group-aria-selected/nav:hidden" />
        <PhotoLibrary className="hidden group-aria-selected/nav:block" />
      </NavItemIcon>
      <NavItemLabel>Photos</NavItemLabel>
    </InventoryNavItem>
  );
}
