import { useState } from 'react';
import { Menu } from '@headlessui/react';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { matchPath } from 'react-router-dom';
import IconLogoPath from 'assets/icon_logo.png';
import { Provider } from 'react-redux';
import { BarChart, DashboardIcon, Filter, Folder, HomePlus, Map, Task, User } from 'components/icons';
import {
  organizationsIndexPath,
  sidekiqPath,
  userPath,
} from 'components/routes';
import { camelCaseKeys } from 'components/utils';
import store from 'components/Apps/store';
import Notifications from 'components/Features/Notification/Index';
import NavigationRail, { LinkNavItem, NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';

function OrganizationLogo({ organization }) {
  if (organization.logoUrl) {
    return (
      <a href="/">
        <img
          src={organization.logoUrl}
          id="org-logo"
          alt="logo"
          className="p-1 hover:opacity-80"
        />
      </a>
    );
  }
  return (
    <a href="/">
      <img
        src={IconLogoPath}
        alt="logo"
        className="p-3"
      />
    </a>
  );
}

function SideLink({ icon, active, path, title }) {
  return (
    <LinkNavItem href={path} active={active}>
      <NavItemIcon>{icon}</NavItemIcon>
      <NavItemLabel>{title}</NavItemLabel>
    </LinkNavItem>
  );
}

function UserLink({ path, label, newTab = false }) {
  return (
    <a
      href={path}
      className="block px-4 py-2 text-sm hover:bg-gray-100 transition ease-in-out duration-150"
      target={newTab ? '_blank' : ''}
      rel="noreferrer"
    >
      {label}
    </a>
  );
}

export default function SideNavigation(props) {
  const { currentUser, notificationCount, organization } = camelCaseKeys(props);
  const [showNotification, setShowNotification] = useState(false);
  const canEditOrg = (user) => user.admin || user.role === 'org_admin';
  const currentPage = window.location.pathname;
  const isActivePage = (page) => !showNotification && matchPath({ path: page, caseSensitive: false, end: false }, currentPage);
  const {
    assetManagementEnabled,
    config: { importNewBuild, importOffMarketMarketplace, newBuildOnly },
    name: organizationName,
  } = organization;
  const { admin, email, organizationId, settings: { offMarketBroker } } = currentUser;
  const marketplaceOnly = importOffMarketMarketplace || newBuildOnly;

  return (
    <RollbarProvider instance={window.Rollbar}>
      <ErrorBoundary>
        <Provider store={store}>
          <div className="relative h-screen">
            <NavigationRail variant="brand" className="fixed h-full z-30 pt-8">
              <OrganizationLogo organization={organization} />
              {!importOffMarketMarketplace && (
                <>
                  <SideLink
                    path="/pipeline"
                    title="Pipeline"
                    icon={<Filter filled={isActivePage('/pipeline')} />}
                    active={isActivePage('/pipeline')}
                  />
                  <SideLink
                    path="/deal_sourcing"
                    title="Sourcing"
                    icon={<Map filled={isActivePage('/deal_sourcing')} />}
                    active={isActivePage('/deal_sourcing')}
                  />
                  <SideLink
                    path="/tasks"
                    title="Tasks"
                    icon={<Task />}
                    active={isActivePage('/tasks')}
                  />
                </>
              )}
              {(importOffMarketMarketplace || offMarketBroker) && (
                <SideLink
                  path="/off_market_marketplace"
                  title="Marketplace"
                  icon={<HomePlus filled={isActivePage('/off_market_marketplace')} />}
                  active={isActivePage('/off_market_marketplace')}
                />
              )}
              {!marketplaceOnly && (
                <SideLink
                  path="/portfolios"
                  title="Portfolios"
                  icon={<Folder className="fill-white" filled={isActivePage('/portfolios')} />}
                  active={isActivePage('/portfolios')}
                />
              )}
              {assetManagementEnabled && (
                <SideLink
                  path="/asset_management"
                  title="Asset Mgmt"
                  icon={<BarChart />}
                  active={isActivePage('/asset_management')}
                />
              )}
              {!marketplaceOnly && (
                <SideLink
                  path="/dashboards"
                  title="Dashboards"
                  icon={<DashboardIcon filled={isActivePage('/dashboards')} />}
                  active={isActivePage('/dashboards')}
                />
              )}
              <Notifications
                notificationCount={notificationCount}
                showNotification={showNotification}
                setShowNotification={setShowNotification}
              />
            </NavigationRail>

            <div className="absolute left-4 bottom-4 z-40">
              <Menu>
                {({ open }) => (
                  <>
                    <Menu.Button
                      className={`${
                        open ? 'bg-gray-400 ' : ''
                      } rounded-full p-2 hover:bg-gray-600 focus:outline-none`}
                      user-action-data-title="Account Actions"
                    >
                      <User className="h-8 w-8" />
                    </Menu.Button>
                    <Menu.Items className="absolute">
                      <div className="bottom-16 left-3 origin-top-right absolute mt-2 -mr-1 w-72 text-gray-900 rounded-md shadow-lg">
                        <div className="py-1 rounded-md bg-white shadow-xs border-2">
                          <div className="px-4 py-2 text-md cursor-default">
                            <div>{email}</div>
                            <div className="text-gray-500 text-sm font-medium">
                              {organizationName}
                            </div>
                          </div>
                          <hr />
                          {admin && (
                            <>
                              <UserLink path={sidekiqPath} label="Sidekiq" />
                              <hr />
                              <UserLink
                                path="/organizations"
                                label="Manage Organizations"
                              />
                            </>
                          )}
                          {importNewBuild && (
                            <UserLink path="/new_builds/inventory" label="Manage New Builds" />
                          )}
                          {canEditOrg(currentUser) && (
                            <UserLink
                              path={organizationsIndexPath(organizationId)}
                              label="Edit Organization"
                            />
                          )}
                          {!marketplaceOnly && (
                            <>
                              <UserLink
                                path="/email_templates"
                                label="Email Templates"
                              />
                              <UserLink
                                path="/import/deals"
                                label="Import Deals"
                              />
                            </>
                          )}
                          <hr />
                          <UserLink
                            path={userPath(currentUser)}
                            label="Edit User Settings"
                          />
                          <UserLink
                            path="https://truliv-public.s3.us-west-1.amazonaws.com/uploads/HoneycombUserGuide.pdf"
                            label="User Guide"
                            newTab
                          />
                          <hr />
                          <UserLink path="/logout" label="Logout" />
                        </div>
                      </div>
                    </Menu.Items>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}
