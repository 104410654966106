import { useCallback, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Dialog } from '@headlessui/react';
import { noop } from 'lodash';
import { X } from 'components/icons';
import { fileSize } from 'components/utils';
import FileUploadArea from 'components/FileUploadArea';
import Button from 'components/shared/NewButton';

export default function FileUploadModel({ toggleModal, isLoading, onUpload, title, accept }) {
  const [files, setFiles] = useState([]);

  const onFileUpload = useCallback((newFiles) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      newFiles
        // use name, size, and mtime as a heuristic to check for duplicated files
        .filter((newFile) => !updatedFiles.some(([otherFile]) => (
          newFile.name === otherFile.name
          && newFile.size === otherFile.size
          && newFile.lastModified === otherFile.lastModified
        )))
        .forEach((file) => updatedFiles.push([file, nanoid()]));

      return updatedFiles;
    });
  }, []);

  const onFileRemove = useCallback((evt) => {
    const idx = parseInt(evt.currentTarget.value, 10);
    setFiles((prevFiles) => prevFiles.toSpliced(idx, 1));
  }, []);

  const uploadOnClick = () => onUpload?.(files.map(([file]) => file));

  return (
    <Dialog open onClose={isLoading ? noop : toggleModal}>
      <div className="fixed inset-0 z-50 bg-black/25" />
      <div className="fixed inset-0 z-50 p-4 content-center overflow-clip">
        <Dialog.Panel className="relative flex flex-col h-max max-h-full w-max max-w-full rounded-2xl mx-auto py-6 *:px-6 bg-white">
          <div className="sticky inset-x-0 top-0 flex flex-row gap-x-3 pb-6 text-xl bg-inherit cursor-default">
            <Dialog.Title>{title}</Dialog.Title>
          </div>

          <div className="flex flex-col gap-y-3 pb-px h-0 flex-1 overflow-auto" inert={isLoading ? '' : undefined}>
            {files.map(([file, fileId], index) => (
              <div key={fileId} className="w-full flex justify-between items-center gap-x-2 text-sm">
                <div className="flex-grow truncate max-w-prose" title={file.name}>{file.name}</div>
                <div className="text-gray-500">{fileSize(file.size)}</div>
                <button type="button" value={index} onClick={onFileRemove} className="focus:outline-none">
                  <X className="w-5 text-red-500 hover:text-red-400" />
                </button>
              </div>
            ))}
            <FileUploadArea accept={accept} onFileUpload={onFileUpload} />
          </div>

          <div className="flex flex-row gap-x-2 justify-end pt-6 border-t mt-auto">
            <Button filled label="Upload" isLoading={isLoading} disabled={!files.length} onClick={uploadOnClick} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
