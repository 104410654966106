import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePreviousValue from 'hooks/usePreviousValue';
import { useCoreRowModel, useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import { selectRowOnClick } from 'components/shared/Table/table.helpers';
import { useCurrentSideNavPanel, useSetCurrentSideNavPanel } from './InventorySideNav';

const useUpdateSearchParam = ({ key, value, defaultValue = undefined }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key) ?? defaultValue;

  const prevValue = usePreviousValue(value, defaultValue);
  const valueChanged = value !== prevValue;
  const desiredValue = valueChanged ? value : paramValue;

  useEffect(() => {
    if (valueChanged) {
      setSearchParams((prev) => {
        const updated = new URLSearchParams(prev);
        if (!isNil(desiredValue) && desiredValue !== defaultValue) {
          updated.set(key, desiredValue);
        } else {
          updated.delete(key);
        }

        return updated;
      }, { replace: true });
    }
  }, [defaultValue, desiredValue, key, setSearchParams, valueChanged]);

  return desiredValue;
};

export function SelectLinkedRow() {
  const [selectedRow] = useFilteredSelectedRows() ?? [];
  const selectedRowId = selectedRow?.id?.toString();

  const desiredRowId = useUpdateSearchParam({ key: 'id', value: selectedRowId });
  const rowModel = useCoreRowModel();
  const row = desiredRowId ? rowModel.rowsById[Number.parseInt(desiredRowId, 10)] : null;

  // TODO: scroll to row
  // TODO: if default filters are added, clear filters so the linked row is visible

  useEffect(() => {
    if (row) {
      selectRowOnClick(row);
    }
  }, [row]);

  return null;
}

export function OpenLinkedPanel() {
  const currentPanel = useCurrentSideNavPanel();
  const setCurrentSideNavPanel = useSetCurrentSideNavPanel();
  const panel = useUpdateSearchParam({ key: 'panel', value: currentPanel, defaultValue: '' });

  useEffect(() => {
    setCurrentSideNavPanel(panel);
  }, [setCurrentSideNavPanel, panel]);

  return null;
}
