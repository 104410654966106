import { closestCenter, DndContext, DragOverlay, useDndMonitor } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';
import { sortBy } from 'lodash';
import { forwardRef, startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DragIndicator, LoadingIndicator, Plus, Trash } from 'components/icons';
import Button from 'components/shared/NewButton';
import { stopEventPropagation } from 'components/utils';
import FileUploadModal from 'components/shared/FileUploadModal';
import { useFetchImageQuery } from '../../redux/imagesApiSlice';

// eslint-disable-next-line prefer-arrow-callback -- use function to preserve function name
const Row = forwardRef(function Row({ className, children, ...props }, ref) {
  return (
    <li
      {...props}
      ref={ref}
      className={cx(
        'group/row grid col-span-full grid-cols-subgrid grid-flow-col items-center gap-x-1 pl-0.5 pr-1 py-1 border rounded select-none',
        className,
      )}
    >
      {children}
    </li>
  );
});

function Photo({ image: { id, url, imageProcessing }, onProcessingComplete }) {
  const [isPolling, setIsPolling] = useState(imageProcessing);
  const { currentData } = useFetchImageQuery({ id }, {
    skip: !isPolling,
    // refetch every 10s
    pollingInterval: 10_000,
  });

  useEffect(() => {
    if (currentData?.imageProcessing === false) {
      // stop polling once image processing is complete
      setIsPolling(false);
      onProcessingComplete?.();
    }
  }, [currentData?.imageProcessing, onProcessingComplete]);

  const isProcessing = isPolling ? (currentData?.imageProcessing ?? imageProcessing) : false;

  return (
    <>
      <div className={cx('size-20 rounded overflow-clip', isProcessing && 'animate-tw-pulse bg-gray-200')}>
        {!isProcessing && (
          <img
            src={url}
            alt=""
            className="size-full object-cover object-center bg-white"
          />
        )}
      </div>
      <div className="text-neutral-dark text-label-lg">
        {isProcessing && 'Processing…'}
      </div>
    </>
  );
}

function InventoryImage({ inventoryImage, useDeleteInventoryImageMutation, useInvalidateInventoryImages }) {
  // invalidate cache to re-fetch inventory table with updated displayable image count
  const invalidateInventoryImages = useInvalidateInventoryImages();

  const [triggerDelete, { isLoading }] = useDeleteInventoryImageMutation();
  const onDelete = useCallback(() => {
    triggerDelete(inventoryImage);
  }, [inventoryImage, triggerDelete]);

  return (
    <>
      <DragIndicator className="h-6 w-auto fill-current text-neutral-dark/75 group-aria-disabled/row:invisible group-aria-disabled/row:w-0 overflow-clip" />
      <Photo image={inventoryImage.image} onProcessingComplete={invalidateInventoryImages} />
      <div className="justify-self-end">
        <DeleteImageButton onDelete={onDelete} isLoading={isLoading} />
      </div>
    </>
  );
}

function DeleteImageButton({ onDelete, isDeleting }) {
  const removeListenerRef = useRef();
  useEffect(() => (() => { removeListenerRef.current?.(); }), []);

  const [confirm, setConfirm] = useState(false);
  const onDeleteClick = useCallback((evt) => {
    setConfirm(true);

    const deleteBtn = evt.currentTarget;
    const listenerAbortController = new AbortController();
    removeListenerRef.current = () => { listenerAbortController.abort(); };

    // register a listener to cancel deletion when clicked outside of this button
    document.addEventListener('pointerdown', (pointerEvt) => {
      if (!pointerEvt.composedPath()
        .includes(deleteBtn)) {
        setConfirm(false);
        // note that the user could pointerdown within the button but pointerup elsewhere, which doesn't trigger onClick
        listenerAbortController.abort();
      }
    }, { capture: true, passive: true, signal: listenerAbortController.signal });
  }, []);

  return (
    <Button
      filled={confirm}
      outlined={!confirm}
      danger
      small
      leadingIcon={<Trash className="size-full" />}
      label={confirm ? 'Confirm' : 'Delete'}
      isLoading={isDeleting}
      onClick={confirm ? onDelete : onDeleteClick}
      // capture and stop propagation to prevent dnd-kit from dragging
      onPointerDownCapture={stopEventPropagation}
    />
  );
}

function SortablePhoto({ image, children }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    active,
  } = useSortable({ id: image.id, data: { image } });

  /** @type {import('react').CSSProperties} */
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Row
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      className={cx(
        'bg-white cursor-grab aria-disabled:cursor-[unset] [&:not([aria-disabled="true"])]:hover:bg-primary-hover',
        isDragging && 'invisible',
      )}
      inert={(active !== null) ? '' : undefined}
    >
      {children}
    </Row>
  );
}

/**
 * @param {import('@dnd-kit/core').DragOverlayProps} props
 */
function ActiveItemOverlay({ useDeleteInventoryImageMutation, useInvalidateInventoryImages, ...props }) {
  const [activeItem, setActiveItem] = useState();

  /** @type {import('@dnd-kit/core').DndMonitorListener}  */
  const listener = useMemo(() => ({
    onDragStart({ active }) {
      setActiveItem(active);
    },
    onDragEnd() {
      setActiveItem(null);
    },
  }), []);
  useDndMonitor(listener);

  return createPortal(
    <DragOverlay {...props}>
      {activeItem ? (
        <Row className="cursor-grabbing bg-primary-hover">
          <InventoryImage
            inventoryImage={activeItem.data.current.image}
            useDeleteInventoryImageMutation={useDeleteInventoryImageMutation}
            useInvalidateInventoryImages={useInvalidateInventoryImages}
          />
        </Row>
      ) : null}
    </DragOverlay>,
    document.body,
  );
}

function AddInventoryImage({ id, isListReady, className, useCreateInventoryImagesMutation }) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowUploadModal((prev) => !prev);
  }, []);

  const [triggerCreate, { isUninitialized, isLoading, isSuccess, reset }] = useCreateInventoryImagesMutation();
  const onUpload = useCallback((files) => {
    triggerCreate({ id, files });
  }, [triggerCreate, id]);

  // include isListReady to wait for parent to finish re-fetching after a successful upload before dismissing modal
  const isUploading = !isUninitialized && (isLoading || !isListReady);
  if (isSuccess && !isUploading) {
    setShowUploadModal(false);
  }

  // reset hook after dismissing modal
  if (!showUploadModal && !isUninitialized) {
    reset();
  }

  return (
    <>
      <Plus className="h-5 w-auto text-neutral-dark justify-self-center" />
      <button
        type="button"
        className={cx(
          'h-20 text-neutral-dark text-label-lg justify-self-start col-start-2 col-span-full',
          String.raw`[.group\/row:has(&)]:relative after:absolute after:inset-0 [.group\/row:has(&:hover)]:bg-primary-hover`,
          className,
        )}
        onClick={toggleModal}
      >
        Upload Photos
      </button>

      {showUploadModal && (
        <FileUploadModal
          toggleModal={toggleModal}
          isLoading={isUploading}
          onUpload={onUpload}
          title="Upload Photos"
          accept="image/*"
        />
      )}
    </>
  );
}

export default function InventoryPhotosOrder({
  id,
  useFetchInventoryImagesQuery,
  useUpdateInventoryImagesMutation,
  useCreateInventoryImagesMutation,
  useDeleteInventoryImageMutation,
  useInvalidateInventoryImages,
}) {
  const { currentData: images, isFetching } = useFetchInventoryImagesQuery({ id });

  const [order, setOrder] = useState(() => images?.map(({ id: imageId }) => imageId) ?? []);
  useEffect(() => {
    if (images?.length) {
      setOrder(images.map(({ id: imageId }) => imageId));
    } else {
      setOrder([]);
    }
  }, [images]);

  const [triggerUpdate] = useUpdateInventoryImagesMutation();

  /** @type {import('@dnd-kit/core').DndContextProps['onDragEnd']} */
  const onDragEnd = useCallback(({ active, over }) => {
    if (!over || active.id === over.id) {
      return;
    }

    setOrder((prevOrder) => {
      const oldIndex = prevOrder.indexOf(active.id);
      const newIndex = prevOrder.indexOf(over.id);

      const updatedOrder = arrayMove(prevOrder, oldIndex, newIndex);
      startTransition(() => triggerUpdate({ id, orderedIds: updatedOrder }));
      return updatedOrder;
    });
  }, [id, triggerUpdate]);

  const displayOrder = sortBy(images, (photo) => {
    const orderIdx = order.indexOf(photo.id);
    // sort by order, if the element is not in order, then put at the end
    // lodash sortBy is stable so those elements at the end should be sorted by their original server-side sorted order
    return orderIdx === -1 ? Number.POSITIVE_INFINITY : orderIdx;
  });

  const gridClassName = 'grid grid-cols-[auto_auto_1fr_auto] auto-rows-fr gap-y-1 bg-white';
  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
    >
      <ol className={cx(gridClassName, 'aria-pressed:cursor-grabbing')}>
        {(isFetching && !images) && (
          <Row>
            <LoadingIndicator className="h-5 w-auto text-neutral-dark justify-self-center" />
            <div className="text-neutral-dark text-label-lg col-start-2 col-span-full">
              Loading
            </div>
          </Row>
        )}
        <SortableContext
          disabled={images?.length === 1}
          items={displayOrder}
          strategy={verticalListSortingStrategy}
        >
          {displayOrder.map((image) => (
            <SortablePhoto key={image.id} image={image}>
              <InventoryImage
                inventoryImage={image}
                useDeleteInventoryImageMutation={useDeleteInventoryImageMutation}
                useInvalidateInventoryImages={useInvalidateInventoryImages}
              />
            </SortablePhoto>
          ))}
        </SortableContext>
        <Row>
          <AddInventoryImage
            id={id}
            isListReady={!isFetching}
            useCreateInventoryImagesMutation={useCreateInventoryImagesMutation}
          />
        </Row>
      </ol>
      <ActiveItemOverlay
        useDeleteInventoryImageMutation={useDeleteInventoryImageMutation}
        useInvalidateInventoryImages={useInvalidateInventoryImages}
        wrapperElement="ul"
        className={gridClassName}
      />
    </DndContext>
  );
}
