// NOTE: you must update the version in users#accept_agreements as well when updating VERSION
export const VERSION = '20241126';
export const HTML = (
  <>
    <h1 className="font-medium mb-4">MUTUAL CONFIDENTIALITY AGREEMENT</h1>
    <p>THIS MUTUAL CONFIDENTIALITY AGREEMENT (this “<u>Agreement</u>”) is made between Nhimble, Inc. and the user.</p>
    <h2 className="font-medium my-3">RECITALS</h2>
    <p className="my-2">&emsp;A.&emsp;The parties have entered into or may enter into discussions to explore one or more business relationships or transactions between them (the “<u>Transaction</u>”).</p>
    <p className="my-2">&emsp;B.&emsp;In the course of discussions regarding such Transaction, it may be necessary for one party (a “<u>Disclosing Party</u>”) to disclose to the other party (a “<u>Receiving Party</u>”) certain proprietary and confidential information that the Disclosing Party wants to remain confidential.</p>
    <p className="my-2">&emsp;C.&emsp;Each party is willing to provide such information to the other party, subject to the terms and conditions set forth in this Agreement.</p>
    <p className="my-3">The parties therefore agree as follows:</p>
    <p className="my-3">1.&emsp;<u>Confidential Information</u>.</p>
    <p className="my-2">&emsp;(a)&emsp;“<u>Confidential Information</u>” means all written, electronic, oral or other information concerning the Disclosing Party, its business or any of its affiliates, whether prepared by the Disclosing Party or its advisors, that is provided by the Disclosing Party to the Receiving Party.  Confidential Information includes but is not limited to:</p>
    <p className="my-2">&emsp;&emsp;(i)&emsp;Data or other information relating to products, inventions, plans, methods, processes, raw materials, ingredients, formulae, compositions, compounds, know-how, developmental or experimental work, intellectual property licenses, products and proposed products, computer programs, databases, systems, software (including object code and source code), concepts, performance features, schedules, original works of authorship, customer lists (including the names, buying habits or practices of an customers), the names of vendors or suppliers, marketing and business methods and the like, reports, analyses, business plans, financial information, statistical information, apparatus, manufacturing technology, contracts, clinical and pre-clinical data and dossiers, business relationships, structures, models, techniques, samples, and any other data, materials, subject matter and the like pertaining to the business of the Disclosing Party that is disclosed to the Receiving Party, including any third-party confidential information that may be properly disclosed by the Disclosing Party to the Receiving Party; and including any other information that may be considered to be proprietary to or a trade secret of the Disclosing Party, whether or not such information is considered a trade secret within the meaning of applicable law.</p>
    <p className="my-2">&emsp;&emsp;(ii)&emsp;Any such data or other information that is learned by the Receiving Party from the Disclosing Party or through the inspection or evaluation of the Disclosing Party’s technical or business information; all data and information obtained by the Receiving Party upon visiting the Disclosing Party’s facilities, whether or not such information is owned by the Disclosing Party; notes, analyses, studies or other documents prepared by the Receiving Party that contain or are based on such information or material relating to the information disclosed by the Disclosing Party.</p>
    <p className="my-2">&emsp;&emsp;(iii)&emsp;The existence and the terms and conditions of this Agreement and the Transaction, and the existence and the substance of the discussion between the parties relating to the subject matter of this Agreement and the Transaction.</p>
    <p className="my-2">&emsp;(b)&emsp;The term “<u>Confidential Information</u>” does not include:</p>
    <p className="my-2">&emsp;&emsp;(i)&emsp;Information that is already in or enters into the public domain other than as a consequence of the breach of the terms of this Agreement.</p>
    <p className="my-2">&emsp;&emsp;(ii)&emsp;Information that is already properly and lawfully in the possession of the Receiving Party prior to its being furnished by the Disclosing Party; but only on the condition that such information was properly and lawfully in the possession of the Receiving Party and that the source of such information was not bound by a contractual, legal or fiduciary obligation of confidentiality to any other party with respect to it.</p>
    <p className="my-2">&emsp;&emsp;(iii)&emsp;Information that becomes available to the Receiving Party on a non-confidential basis from a third party other than the Disclosing Party; but only on the condition that such information was properly and lawfully in the possession of such third party and that such third party was not bound by a contractual, legal or fiduciary obligation of confidentiality to any other party with respect to it.</p>
    <p className="my-2">&emsp;&emsp;(iv)&emsp;Information that the Receiving Party developed independently without reference to or use of any Confidential Information made available to the Receiving Party by the Disclosing Party.</p>
    <p className="my-3">2.&emsp;<u>Restricted Use of Confidential Information</u>.  The Receiving Party and each of its directors, officers, employees, affiliates and advisors (including, without limitation, attorneys, accountants, consultants, banks and financial advisors) (collectively, “<u>Representatives</u>”) shall (a) hold and maintain in confidence all Confidential Information; (b) not disclose any Confidential Information to any person or entity other than to those Representatives of the Receiving Party who need to know the Confidential Information for purposes of evaluating or consummating the Transaction; (c)&nbsp;use at least as great a standard of care in protecting the Confidential Information as the Receiving Party uses to protect Receiving Party’s own information of like character, but in any event no less than a reasonable degree of care; and (d) not use any Confidential Information for any purpose other than to evaluate or consummate the Transaction.  The Receiving Party will promptly notify the Disclosing Party upon discovery of any unauthorized use or disclosure of Confidential Information in breach of this Agreement.  The Receiving Party shall be responsible for the breach of any term of this Agreement by any of its Representatives to whom the Receiving Party discloses any Confidential Information.</p>
    <p className="my-3">3.&emsp;<u>Copies; Return and Destruction of Confidential Information</u>.  The Receiving Party and its Representatives shall not copy any part of the Confidential Information, except as required for  the Transaction.  Upon request of the Disclosing Party, the Receiving Party shall destroy or return to the Disclosing Party all tangible materials containing or reflecting the Confidential Information, regardless of whether prepared by the Disclosing Party or the Receiving Party or any of their respective Representatives, and all copies, extracts or other reproductions of such material.  The destruction or redelivery of any such material shall not relieve the Receiving Party from its obligations under this Agreement.  Nothing in this Agreement shall require the alteration, modification or destruction of back-up tapes or other back-up media made for archival purposes in the ordinary course of the Receiving Party’s or its Representative’s business, provided that the materials retained under this sentence shall remain subject to the confidentiality obligations under this Agreement.</p>
    <p className="my-3">4.&emsp;<u>Notice of Legal Process</u>.  If the Receiving Party is requested or legally compelled (by oral questions, interrogatories, requests for information or documents, subpoena, warrant, civil or criminal investigative demand, or similar process) or is required by any regulatory body to disclose any Confidential Information, the Receiving Party shall promptly notify the Disclosing Party of any such request, legal compulsion or requirement so that the Disclosing Party may seek an appropriate protective order or other appropriate remedy.  The Receiving Party shall cooperate with the Disclosing Party, at the Disclosing Party’s expense, in any attempt of the Disclosing Party to obtain a protective order or other assurance that confidential treatment shall be accorded the Confidential Information.  Subject to the foregoing, the Receiving Party may furnish that portion (and only that portion) of the Confidential Information that, in the written opinion of its counsel reasonably acceptable to the Disclosing Party, the Receiving Party is requested, legally compelled or is otherwise required to disclose or else stand liable for contempt or suffer other material censure or material penalty, and on the condition that the Receiving Party and its Representatives use reasonable efforts to obtain reliable assurance that confidential treatment will be accorded any Confidential Information so disclosed.</p>
    <p className="my-3">5.&emsp;<u>No Representation or Warranty; No License</u>.  The Disclosing Party retains the right to determine, in its sole discretion, what information, properties, and personnel it wishes to make available to the Receiving Party.  The Receiving Party understands and acknowledges that all Confidential Information is provided “as is” and that neither the Disclosing Party nor any of its Representatives (a) is making any representation or warranty, express or implied, as to the accuracy or completeness of the Confidential Information, including, without limitation, warranties of title and infringement or (b) shall have any liability to the Receiving Party resulting from the Receiving Party’s use of the Confidential Information.  The Receiving Party further understands and acknowledges that no license is granted, conveyed or implied with respect to the Confidential Information.</p>
    <p className="my-3">6.&emsp;<u>Remedies</u>.</p>
    <p className="my-2">&emsp;(a)&emsp;The Receiving Party agrees to indemnify and hold the Disclosing Party and its Representatives harmless from any damages, loss, cost, or liability (including legal fees and the cost of enforcing this indemnity) arising out of or resulting from any unauthorized use or disclosure by the Receiving Party or its Representatives of the Confidential Information or other violation of this Agreement.</p>
    <p className="my-2">&emsp;(b)&emsp;The Receiving Party agrees that in the event of a breach of this Agreement by the Receiving Party or its Representatives, the Disclosing Party will be irreparably harmed and money damages would be inadequate and difficult or impossible to measure.  Accordingly, the Receiving Party agrees that, in the event of any such breach or threatened breach of this Agreement, the Disclosing Party will also be entitled, without the requirement of posting a bond or other security, to seek equitable relief, including injunctive relief and specific performance.  Such remedies will not be the exclusive remedies for any breach of this Agreement but will be in addition to all other remedies available at law or equity to the Disclosing Party.</p>
    <p className="my-3">7.&emsp;<u>Obligations to Subsidiaries or Affiliates</u>.  The Receiving Party and its Representatives have the same obligations under this Agreement with respect to the Confidential Information of any subsidiary or affiliate of the Disclosing Party.  Any such subsidiary or affiliate of the Disclosing Party will have the rights and remedies as provided in this Agreement, and at law or in equity, for the violation of such obligations with respect to any such subsidiary or affiliate’s Confidential Information.</p>
    <p className="my-3">8.&emsp;<u>Term</u>.  This Agreement, and all rights and obligations hereunder, will terminate on the 2nd annual anniversary of the date of this Agreement.</p>
    <p className="my-3">9.&emsp;<u>General Provisions</u>.</p>
    <p className="my-2">&emsp;(a)&emsp;<i>Integrated Agreement; Modification</i>.  This Agreement constitutes the entire agreement of the parties relating to the subject matter of this Agreement and supersedes all other oral or written agreements or policies relating thereto.  No amendment of this Agreement will be effective unless it is in writing and signed by the parties.</p>
    <p className="my-2">&emsp;(b)&emsp;<i>Severability</i>.  In the event any term, provision or covenant contained in this Agreement shall be adjudicated by a court to be partially or totally invalid or unenforceable for any reason, then such term, provision or covenant shall be reformed to the minimum extent necessary to render it valid and enforceable.  If a court refuses to so modify any such term, provision or covenant, such term, provision or covenant shall be eliminated from this Agreement but each of the remaining terms, provisions and covenants shall remain in full force and effect. The terms, provisions and covenants contained in this Agreement are deemed separate and severable.</p>
    <p className="my-2">&emsp;(c)&emsp;<i>Notices</i>. All notices which are required to be given under this Agreement must be in writing and delivered to the address of the recipient set forth below its signature hereto or such other address as the recipient may designate by notice given in accordance with the provisions of this paragraph.  Any such notice must be delivered by hand or by certified first className mail return receipt requested, postage prepaid, and will be deemed to have been served on the date delivered by hand or three days after mailing.</p>
    <p className="my-2">&emsp;(d)&emsp;<i>Parties in Interest; Third Party Benefit</i>.  All the terms and provisions of this Agreement are binding upon, inure to the benefit of, and may be enforced by and against, the parties hereto and their respective Representatives, successors and permitted assignees, and to the subsidiaries and affiliates of the Disclosing Party as stated in Section 7.  Except as stated in the previous sentence, nothing in this Agreement, expressed or implied, is intended to confer on any other person or entity any rights or remedies under or by reason of this Agreement.</p>
    <p className="my-2">&emsp;(e)&emsp;<i>Costs.</i>  The non-prevailing party shall be responsible for all costs, fees and expenses (including attorneys fees) incurred by the prevailing party in connection with any action or suit arising out of this Agreement.</p>
    <p className="my-2">&emsp;(f)&emsp;<i>No Waiver</i>. No waiver of breach of this Agreement, or satisfaction of a condition or nonperformance of an obligation under this Agreement, will be effective unless it is in writing and signed by the party granting the waiver, and no such waiver will constitute a waiver of any other breach, or of satisfaction of any other condition or nonperformance of any other obligation.  No forbearance, delay or indulgence by either party in enforcing the provisions of this Agreement will prejudice or restrict the rights of that party.</p>
    <p className="my-2">&emsp;(g)&emsp;<i>Governing Law</i>.  The laws of the State of Arizona, without giving effect to principles of conflict of laws, govern all matters arising under this agreement, including all tort claims.</p>
    <p className="my-2">&emsp;(h)&emsp;<i>Counterpart Execution</i>.  The parties may sign this Agreement in several counterparts, each of which will be deemed an original, but all of which together will constitute one instrument.  The parties may transmit their signatures to this Agreement by facsimile or by electronic mail.</p>
  </>
);
