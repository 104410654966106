import { Fragment, useState } from 'react';
import { chunk, groupBy, isBoolean, isNil, isString, last, omitBy, pickBy, remove, sortBy } from 'lodash';
import { calcDaysOnMarketFromListing, formatCurrency, formatDate, formatInteger, formatListingSource, isListingClosed, titleCase } from 'components/utils';
import { LoadingIndicator } from 'components/icons';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';
import RecordPhotos from 'components/property/components/RecordPhotos';
import PhotoModal from 'components/property/components/PhotoModal';
import RecordGrid, { RecordGridCell, RecordGridColumn } from 'components/property/components/RecordGrid';
import listingDetailFields from 'components/listing/standardizedListingFields';
import { useFetchListingDataQuery } from 'redux/apiSlice';
import Disclaimer from 'components/property/components/Disclaimer';
import Input from 'components/Input';

const SQ_FT_PER_ACRE = 43560;

function Header({ listing }) {
  const { listingId, source, sourceId } = listing;

  return (
    <div className="pb-4">
      <div className="text-xl text-black">Listing Details</div>
      <div className="pt-1 text-gray-500 text-xs">{`${formatListingSource(source)} #${listingId || sourceId}`}</div>
    </div>
  );
}

function Summary({ listing }) {
  const {
    bathroomsFull,
    bathroomsHalf,
    bathroomsTotalInteger,
    bedroomsTotal,
    buildingAreaTotal,
    closeDate,
    closePrice,
    listPrice,
    listingContractDate,
    listingId,
    livingArea,
    lotSizeAcres,
    lotSizeSquareFeet,
    modificationTimestamp,
    offMarketDate,
    onMarketDate,
    originalListPrice,
    propertySubType,
    propertyType,
    source,
    status,
    standardStatus,
    yearBuilt,
  } = listing;

  let lotSize = null;
  if (lotSizeSquareFeet) {
    lotSize = lotSizeSquareFeet;
  } else if (lotSizeAcres) {
    lotSize = lotSizeAcres * SQ_FT_PER_ACRE;
  }

  return (
    <>
      <div className="w-full flex justify-between items-center border-b py-2">
        <span>Summary</span>
      </div>
      <div className="py-6 border-b">
        <RecordGrid>
          <RecordGridColumn>
            <RecordGridCell label="MLS" value={formatListingSource(source)} />
            <RecordGridCell label="Listing ID" value={listingId} />
            <RecordGridCell label="Last Update" value={modificationTimestamp} />
            <RecordGridCell label="Status" value={standardStatus || status} />
            <RecordGridCell label="Property Type" value={propertyType} />
            <RecordGridCell label="Property SubType" value={propertySubType} />
          </RecordGridColumn>
          <RecordGridColumn>
            <RecordGridCell label="Original List Price" value={formatCurrency(originalListPrice)} />
            <RecordGridCell label="List Price" value={formatCurrency(listPrice)} />
            {isListingClosed(listing) ? (
              <RecordGridCell label="Close Price" value={formatCurrency(closePrice)} />
            ) : null}
            <RecordGridCell label="Days On Market" value={calcDaysOnMarketFromListing(listing)} />
            <RecordGridCell label="On Market Date" value={onMarketDate || listingContractDate} />
            {isListingClosed(listing) ? (
              <RecordGridCell label="Close Date" value={closeDate} />
            ) : offMarketDate ? <RecordGridCell label="Off Market Date" value={offMarketDate} /> : null}
          </RecordGridColumn>
          <RecordGridColumn>
            <RecordGridCell label="Bedrooms" value={bedroomsTotal} />
            <RecordGridCell label="Bathrooms" value={bathroomsTotalInteger} />
            <RecordGridCell label="Bathrooms Full" value={bathroomsFull} />
            <RecordGridCell label="Bathrooms Half" value={bathroomsHalf} />
            {livingArea ? (
              <RecordGridCell label="Living Area" value={`${formatInteger(livingArea)} sqft`} />
            ) : (
              <RecordGridCell label="Building Area" value={`${formatInteger(buildingAreaTotal)} sqft`} />
            )}
            <RecordGridCell label="Lot Size" value={`${formatInteger(lotSize)} sqft`} />
            <RecordGridCell label="Year Built" value={yearBuilt} />
          </RecordGridColumn>
        </RecordGrid>
      </div>
    </>
  );
}

const TIMELINE_DATE_FIELDS = {
  onMarketDate: 'On Market',
  offMarketDate: 'Off Market',
  priceChangeTimestamp: 'Price Change',
  contractStatusChangeDate: 'Contract Status Change',
  contingentDate: 'Contingent',
  purchaseContractDate: 'In Contract',
  listingContractDate: 'Listed',
  cancellationDate: 'Cancelled',
  withdrawnDate: 'Withdrawn',
  expirationDate: 'Expired',
  closeDate: 'Sold',
};
// ordered by priority to determine which event name is shown in case of same day events
// (lower in list is higher priority)
const TIMELINE_DATE_PRIORITY = Object.keys(TIMELINE_DATE_FIELDS);
const TIMELINE_EVENT_SUBVALUE = {
  listingContractDate: listing => formatCurrency(listing.originalListPrice),
  priceChangeTimestamp: listing => formatCurrency(listing.previousListPrice),
  purchaseContractDate: listing => formatCurrency(listing.listPrice),
  closeDate: listing => formatCurrency(listing.closePrice),
};

function TimelineEvent({ field, listing }) {
  const subValue = TIMELINE_EVENT_SUBVALUE[field]?.(listing);
  return (
    <div className="px-6 text-center text-sm">
      <div className="mb-1 text-gray-500">{TIMELINE_DATE_FIELDS[field]}</div>
      <div>{formatDate(listing[field])}</div>
      {subValue && <div className="mt-0.5">{subValue}</div>}
    </div>
  );
}

function Timeline({ listing }) {
  const timelineObj = pickBy(listing, (value, key) => !isNil(value) && TIMELINE_DATE_PRIORITY.includes(key));
  const timelineEvents = sortBy(Object.entries(timelineObj), arr => arr[1]);
  // prune entries to avoid duplicate dates
  const groupedEvents = groupBy(timelineEvents, arr => arr[1]);
  const prunedEvents = Object.values(groupedEvents).map(values => last(sortBy(values, arr => TIMELINE_DATE_PRIORITY.indexOf(arr[0]))));
  // do not include price change event if no previous list price
  remove(prunedEvents, arr => arr[0] === 'priceChangeTimestamp' && !listing.previousListPrice);

  return (
    <div className="py-8">
      <div className="uppercase tracking-wide text-xs pb-2">Listing Timeline</div>
      <div className="py-6 w-full flex items-center justify-between">
        {prunedEvents.map((timelineEntry, index) => (
          <Fragment key={timelineEntry[0]}>
            <TimelineEvent field={timelineEntry[0]} listing={listing} />
            {(index < (prunedEvents.length - 1)) ? <div className="h-0.5 flex-grow-1 bg-gray-500" /> : null}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function Remarks({ listing }) {
  const {
    listingPrivateRemarks,
    remarks,
    publicRemarks,
    privateRemarks,
  } = listing;

  return (
    <div className="py-8">
      <div className="flex justify-between items-start">
        <div className="w-1/2 h-full pr-3 flex flex-col justify-start items-start">
          <div className="uppercase tracking-wide text-xs pb-2">Public Remarks</div>
          <p className="text-xs text-gray-600 leading-5">
            {remarks || publicRemarks}
          </p>
        </div>
        <div className="w-1/2 h-full pl-3 flex flex-col justify-start items-start">
          <div className="uppercase tracking-wide text-xs pb-2">Private Remarks</div>
          <p className="text-xs text-gray-600 leading-5">
            {listingPrivateRemarks || privateRemarks}
          </p>
        </div>
      </div>
    </div>
  );
}

const ARRAY_FIELDS = [
  'appliances',
  'architecturalStyle',
  'associationAmenities',
  'associationFeeIncludes',
  'basement',
  'buyerFinancing',
  'constructionMaterials',
  'cooling',
  'fencing',
  'fireplaceFeatures',
  'flooring',
  'foundationDetails',
  'heating',
  'interiorFeatures',
  'laundryFeatures',
  'listingTerms',
  'lotFeatures',
  'otherStructures',
  'parkingFeatures',
  'patioAndPorchFeatures',
  'possession',
  'roadResponsibility',
  'roof',
  'rooms',
  'sewer',
  'showingRequirements',
  'specialListingConditions',
  'waterSource',
  'windowFeatures',
];
const HIDDEN_FIELDS = [
  'coListAgentKey',
  'details',
  'id',
  'listAgentKey',
  'listingKey',
  'listOfficeKey',
  'media',
  'source',
  'sourceId',
  'offMarketMarketplace',
  'marketplaceOrganizationId',
];

const formatDetailField = (field, listing) => {
  if (ARRAY_FIELDS.includes(field) && listing[field] && (listing[field].substring(0, 2) === '["')) {
    try {
      // eslint-disable-next-line quotes
      return JSON.parse(listing[field]).join("\n");
    } catch (error) {
      console.error(error);
    }
  }
  if (isBoolean(listing[field])) {
    return listing[field] ? 'True' : 'False';
  }
  return listing[field];
};

function DetailsListing({ listing, isLoading }) {
  const [hideEmpty, setHideEmpty] = useState(true);
  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-6"><LoadingIndicator className="w-8 text-blue-400" /></div>
    );
  }

  if (!listing) {
    return (
      <div className="flex justify-center items-center uppercase tracking-wide text-xs py-6">Unable to fetch detailed listing information</div>
    );
  }

  // create obj with all potential listing fields
  const listingData = {
    ...Object.fromEntries(listingDetailFields.map(field => [field, null])),
    ...listing,
  };
  const listingFieldsArr = Object.keys(hideEmpty ? omitBy(listingData, val => isNil(val) || (val === '[]')) : listingData).filter(field => !HIDDEN_FIELDS.includes(field)).toSorted();
  const colSize = Math.ceil(listingFieldsArr.length / 3);
  const chunks = chunk(listingFieldsArr, colSize);

  return (
    <>
      <div className="w-full flex justify-between items-center border-b pb-2">
        <span>Details</span>
        <div className="flex items-center gap-x-2">
          <label className="text-xs text-gray-500" htmlFor="hideEmpty">Show Empty Fields</label>
          <Input name="hideEmpty" type="checkbox" value={!hideEmpty} onChange={() => setHideEmpty(!hideEmpty)} />
        </div>
      </div>
      <div className="py-6">
        <RecordGrid>
          {chunks.map((ch, i) => (
            <RecordGridColumn key={i}>
              {ch.map(field => (
                <RecordGridCell
                  key={field}
                  className="whitespace-pre-wrap"
                  label={titleCase(field)}
                  value={formatDetailField(field, listing)}
                />
              ))}
            </RecordGridColumn>
          ))}
        </RecordGrid>
      </div>
    </>
  );
}

export function ListingContent({ listing, isLease }) {
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [primaryPhotoIndex, setPrimaryPhotoIndex] = useState(0);

  const { data: apiData, isLoading } = useFetchListingDataQuery({ listingId: listing?.id, isLease }, { skip: !listing?.id });
  const combinedListingData = { ...listing, ...apiData };

  let photos = combinedListingData?.pictures || combinedListingData?.media || [];
  if (isString(photos)) {
    try {
      photos = JSON.parse(photos);
    } catch (err) {
      photos = [];
    }
  }
  // standardize url field name
  photos.forEach(photo => (photo.url ??= photo.mediaurl));

  return (
    <>
      <div className="flex flex-col w-full px-6 h-min max-h-fit bg-white rounded-xl">
        <RecordPhotos
          photos={photos}
          setShowPhotoModal={setShowPhotoModal}
          setPrimaryPhotoIndex={setPrimaryPhotoIndex}
        />
        <Summary listing={combinedListingData} />
        <Remarks listing={combinedListingData} />
        <Timeline listing={combinedListingData} />
        <DetailsListing listing={apiData} isLoading={isLoading} />
      </div>
      {showPhotoModal && (
        <PhotoModal
          photos={photos}
          primaryPhotoIndex={primaryPhotoIndex}
          setPrimaryPhotoIndex={setPrimaryPhotoIndex}
          showModal={showPhotoModal}
          setShowModal={setShowPhotoModal}
        />
      )}
      <Disclaimer listing={listing} />
    </>
  );

}

export default function ListingDetails({ context }) {
  const { data: { listing } } = context;

  return (
    <PropertyLayoutContainer>
      <Header listing={listing} />
      <ListingContent listing={listing} />
    </PropertyLayoutContainer>
  );
}
