import { Fragment } from 'react';
import cx from 'classnames';
import { compact, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useUpdateTaskMutation } from 'redux/apiSlice';
import {
  createTaskAttachmentModal,
  destroyTaskAttachmentModal,
  destroyTaskModal,
  sendTaskEmailModal,
  submitTaskResponseModal,
  updateTaskModal,
} from 'actions/deals';
import { Paperclip, Pencil, SendEmailIcon, Trash, X } from 'components/icons';
import { formatDate, userDisplay } from 'components/utils';
import DateElement from 'components/common/DateElement';
import RoundButton from 'components/common/RoundButton';
import { TASK_DATE_FORMAT, transformTransactionInfo } from 'components/dashboard/milestone';

function TaskCardContainer({ completedAt, isBlocked, title, children, fade = false }) {
  return (
    <div
      className={(
        cx(
          'flex flex-col w-full border rounded-xl border-black border-opacity-12 text-gray-900 px-6 pt-4 pb-6 mb-2',
          { 'bg-opacity-70': fade || completedAt },
          { 'bg-green-50': completedAt },
          { 'bg-gray-50': isBlocked },
          { 'bg-white': !completedAt && !isBlocked },
        )
      )}
    >
      {title}
      {children}
    </div>
  );
}

function TaskCardTitle({ deal, task }) {
  const dispatch = useDispatch();
  const [updateTaskMutation] = useUpdateTaskMutation();
  const {
    canCreateTaskAttachment,
    canDestroy,
    canUpdate,
    canUpdateCompletedAt,
    completedAt,
    dealId,
    dueDate,
    fields,
    id,
    isBlocked,
    name,
  } = task;
  const transactionInfo = transformTransactionInfo(deal.transactionInfo).fields;

  let primaryAction, primaryActionOnClick, isPrimaryActionReadonly;
  if (!isEmpty(fields)) {
    primaryAction = completedAt ? 'View Response' : 'Submit Response';
    primaryActionOnClick = () => dispatch(submitTaskResponseModal(task, transactionInfo));
    isPrimaryActionReadonly = !!completedAt;
  } else {
    primaryAction = completedAt ? 'Mark Incomplete' : 'Mark Complete';
    primaryActionOnClick = async () => {
      // TODO: handle response
      await updateTaskMutation({
        id,
        dealId,
        completedAt: completedAt ? null : (dueDate || new Date()),
        // skip canSpecifyCompletedAt check and fallback to canUpdateCompletedAt
        // anyone who's allowed to update completed at can use the toggle
        canSpecifyCompletedAt: canUpdateCompletedAt,
      });
    };
    isPrimaryActionReadonly = false;
  }
  const primaryActionDisabled = !isPrimaryActionReadonly && ((!completedAt && isBlocked) || !canUpdate);


  return (
    <div className="flex items-center gap-x-2">
      <div className="flex-grow text-lg leading-10 font-semibold">{name}</div>
      <div className="flex gap-x">
        <button
          type="button"
          className={cx(
            'px-4 py-2.5 text-sm font-medium mr-2 rounded focus:outline-none select-none',
            (completedAt || primaryActionDisabled) ? 'text-gray-700' : 'text-primary',
            {
              'hover:bg-black hover:bg-opacity-8': completedAt && !primaryActionDisabled,
              'hover:bg-primary hover:bg-opacity-12': !completedAt && !primaryActionDisabled,
              'cursor-not-allowed': primaryActionDisabled,
            },
          )}
          onClick={primaryActionOnClick}
          disabled={primaryActionDisabled}
        >
          {primaryAction}
        </button>
      </div>
      <RoundButton
        padding="p-2"
        onClick={() => dispatch(updateTaskModal(task, false))}
        disabled={!canUpdate}
      >
        <Pencil className="h-6 w-auto text-gray-700" />
      </RoundButton>
      <RoundButton
        padding="p-2"
        onClick={() => dispatch(sendTaskEmailModal(task))}
        disabled={!canUpdate}
      >
        <SendEmailIcon className="h-6 w-auto text-gray-700" />
      </RoundButton>
      <RoundButton
        padding="p-2"
        onClick={() => dispatch(createTaskAttachmentModal(task))}
        disabled={!canCreateTaskAttachment}
      >
        <Paperclip className="h-6 w-auto text-gray-700" />
      </RoundButton>
      <RoundButton
        padding="p-2"
        onClick={() => dispatch(destroyTaskModal(task))}
        disabled={!canDestroy}
      >
        <Trash className={`h-6 w-auto ${!canDestroy ? 'text-gray-300' : 'text-gray-700'}`} />
      </RoundButton>
    </div>
  );
}

function TaskDetailLabel({ className, children }) {
  return (
    <div className={cx('col-start-1 h-5', className)}>
      {children}
    </div>
  );
}

function TaskDetailValue({ className, missingValue, children }) {
  return (
    <div className={cx('h-auto w-96', children ? 'font-semibold' : 'text-gray-900 text-opacity-54', className)}>
      {children || missingValue}
    </div>
  );
}

export default function TaskCard({ deal, task, fade = false, usersById }) {
  const dispatch = useDispatch();
  const {
    attachments,
    completedAt,
    completedById,
    dueDate,
    extra,
    isBlocked,
    notes,
    userId,
  } = task;

  const taskGrid = [];
  if (completedAt) {
    taskGrid.push(
      ['Completed', <TaskDetailValue>{formatDate(completedAt, TASK_DATE_FORMAT)}</TaskDetailValue>],
      ['Completed By', <TaskDetailValue missingValue="Unknown">{userDisplay(usersById[completedById])}</TaskDetailValue>],
      notes && ['Notes', <TaskDetailValue>{notes}</TaskDetailValue>],
    );
  } else {
    taskGrid.push(
      ['Due', (
        <TaskDetailValue missingValue="No Due Date">
          {dueDate && (
            <DateElement
              showDate
              showRelative
              date={dueDate}
              dateFormat={TASK_DATE_FORMAT}
              props={(diff) => ({ className: diff < 3 ? 'text-red-700' : '' })}
            />
          )}
        </TaskDetailValue>
      )],
      ['Assigned To', <TaskDetailValue missingValue="Unassigned">{userDisplay(usersById[userId])}</TaskDetailValue>],
      extra.state && ['Status', <TaskDetailValue>{extra.state}</TaskDetailValue>],
      notes && ['Notes', <TaskDetailValue>{notes}</TaskDetailValue>],
    );
  }

  if (attachments?.length) {
    attachments.forEach(attachment => {
      const { filename, url } = attachment;

      taskGrid.push(['Attached', (
        <TaskDetailValue className="flex items-center gap-x-0.5">
          {attachment.canDownload ? (
            <a href={url} target="_blank" rel="noreferrer">{filename}</a>
          ) : (
            <span className="cursor-not-allowed">{filename}</span>
          )}
          {attachment.canDestroy && (
            <RoundButton padding="ml-0.5 p-1" onClick={() => dispatch(destroyTaskAttachmentModal(task, attachment))}>
              <X className="h-4 w-4" />
            </RoundButton>
          )}
        </TaskDetailValue>
      )]);
    });
  }

  return (
    <TaskCardContainer
      fade={fade}
      title={<TaskCardTitle deal={deal} task={task} />}
      completedAt={completedAt}
      isBlocked={isBlocked}
    >
      <div className="grid auto-cols-max grid-flow-col gap-y-2 gap-x-4 mt-4 text-sm text-gray-700">
        {compact(taskGrid).map(([label, element], index) => (
          <Fragment key={index}>
            <TaskDetailLabel>{label}</TaskDetailLabel>
            {element}
          </Fragment>
        ))}
      </div>
    </TaskCardContainer>
  );
}
