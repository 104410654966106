import MentionedInNoteNotification from './MentionedInNoteNotification';

export default function MentionedInListingNoteNotification({ notification }) {
  const { listingNote: { body, listing: { id: listingId, offMarketMarketplace } }, property: { address, city, state } } = notification.attributes;

  if (!offMarketMarketplace) {
    // listing notes are only supported for off-market listings
    return null;
  }

  return (
    <MentionedInNoteNotification notification={notification} noteBody={body}>
      <div className="mt-3 h-6 flex gap-x-2 items-center">
        <a
          href={`/off_market_marketplace/inventory?${new URLSearchParams({ id: listingId, panel: 'notes' })}`}
          className="after:absolute after:inset-0 text-neutral-dark font-medium text-base text-nowrap focus:outline-none focus-visible:outline-none"
        >
          {[address, state && city, city && state].filter(Boolean).join(', ')}
        </a>
      </div>
    </MentionedInNoteNotification>
  );
}
