import { createContext, useCallback, useContext, useState } from 'react';
import { Config, DocumentsFilled, DocumentsOutlined } from 'components/icons';
import NavigationRail, { ButtonNavItem, NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';

const InventorySideNavContext = createContext(undefined);
InventorySideNavContext.displayName = 'InventorySideNavContext';

/**
 * @return {() => void}
 */
export const useCloseSideNav = () => {
  const [, setSideNavPanel] = useContext(InventorySideNavContext);
  return useCallback(() => {
    setSideNavPanel('');
  }, [setSideNavPanel]);
};

/**
 * @return {string}
 */
export const useCurrentSideNavPanel = () => {
  const [currentPanel] = useContext(InventorySideNavContext);
  return currentPanel ?? '';
};

/**
 * @return {() => void}
 */
export const useToggleOnClick = () => {
  const [, setSideNavPanel] = useContext(InventorySideNavContext);
  return useCallback((evt) => {
    const panel = evt.currentTarget.name;
    setSideNavPanel((prev) => (prev === panel ? '' : panel));
  }, [setSideNavPanel]);
};

export const useSetCurrentSideNavPanel = () => {
  const [, setSideNavPanel] = useContext(InventorySideNavContext);
  return useCallback((panel) => {
    setSideNavPanel(panel || '');
  }, [setSideNavPanel]);
};

/**
 * @param {string} name
 * @param {import('react').ReactNode} children
 */
export function InventoryNavItem({ name, children }) {
  const currentPanel = useCurrentSideNavPanel();
  const onClick = useToggleOnClick();

  return (
    <ButtonNavItem name={name} active={currentPanel === name} onClick={onClick}>
      {children}
    </ButtonNavItem>
  );
}

function DetailsNavItem() {
  return (
    <InventoryNavItem name="detail">
      <NavItemIcon>
        <DocumentsOutlined className="block group-aria-selected/nav:hidden" />
        <DocumentsFilled className="hidden group-aria-selected/nav:block" />
      </NavItemIcon>
      <NavItemLabel>Details</NavItemLabel>
    </InventoryNavItem>
  );
}

function ConfigNavItem() {
  return (
    <InventoryNavItem name="config">
      <NavItemIcon>
        <Config className="size-4" />
      </NavItemIcon>
      <NavItemLabel>Config</NavItemLabel>
    </InventoryNavItem>
  );
}

/**
 * @param {'homeModel' | 'subdivision' | 'homeBuilder' | 'offMarket'} page
 * @param {import('react').ReactNode} children
 */
export function SideNavRail({ page, children }) {
  return (
    <NavigationRail className="h-full z-10 border-l border-gray-200">
      {(page === 'homeModel' || page === 'subdivision' || page === 'homeBuilder') && (
        <DetailsNavItem />
      )}
      {children}

      {/* config button should be kept at the end */}
      {(page === 'homeModel' || page === 'subdivision' || page === 'offMarket') && (
        <ConfigNavItem />
      )}
    </NavigationRail>
  );
}

export default function InventorySideNav({ children }) {
  const sideNavState = useState('');

  return (
    <InventorySideNavContext.Provider value={sideNavState}>
      {children}
    </InventorySideNavContext.Provider>
  );
}
