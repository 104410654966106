import { isEmpty, intersection } from 'lodash';
import { Check, X } from 'components/icons';
import FloatingTooltip from 'components/shared/FloatingTooltip';
import { titleCase } from 'components/utils';

const RETURN_METRIC_FIELDS = [
  'minimum_gross_yield',
  'minimum_net_yield',
];

export default function ReturnsMatchBuyBoxCell({ getValue, row: { original: { netYield } } }) {
  // show empty if a yield is not calculated
  if (!netYield) return null;

  const missReasons = intersection(getValue(), RETURN_METRIC_FIELDS);

  let buyBoxIndicator = null;
  if (isEmpty(missReasons)) {
    buyBoxIndicator = <Check className="text-success-500 size-5" />;
  } else {
    const tooltipContents = (
      <ul className="text-sm">
        {missReasons.map(reason => <li key={reason}>{titleCase(reason)}</li>)}
      </ul>
    );
    buyBoxIndicator = (
      <FloatingTooltip contents={tooltipContents}>
        <X className="text-error-500 size-5" />
      </FloatingTooltip>
    );
  }

  return (
    <div className="flex justify-center">
      {buyBoxIndicator}
    </div>
  );
}
