import { createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import ChipGroup from 'components/shared/ChipGroup';
import ExternalUrl from 'components/shared/ExternalUrl';
import AllFiltersChip from 'components/shared/newfilters/AllFiltersChip';
import BooleanFilterChip from 'components/shared/newfilters/BooleanFilterChip';
import FilterChip from 'components/shared/newfilters/FilterChip';
import RangeFilter from 'components/shared/newfilters/RangeFilter';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import useRangeDisplayValue from 'components/shared/newfilters/useRangeDisplayValue';
import {
  CurrencyCell,
  DatetimeCell,
  InputCell,
  IntegerCell,
  PercentCell,
  RouteLinkCell,
  TouchDatetimeCell,
  ZonedDatetimeCell,
} from 'components/shared/Table/Cells';
import DataTable from 'components/shared/Table/DataTable';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import { EMPTY_PRESET, getDefaultPresetId } from 'components/shared/Table/dataTableConfig/utils';
import { DataTableContent, useFilteredRows, useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import { dateRangeFilter, rangeFilter, valueRangeRangeFilter } from 'components/shared/Table/table.filterFns';
import {
  dataTableMeta,
  enableConfigPresets,
  enableEditing,
  selectRowOnClick,
  tableConfigMeta,
} from 'components/shared/Table/table.helpers';
import {
  formatCurrency,
  formatCurrencyAbbreviated,
  formatDate,
  formatInteger,
  formatPercentage,
} from 'components/utils';
import { compact, get, isArray, mean, sumBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { InformationIcon } from 'components/icons';
import InventorySideNav, { InventoryNavItem, SideNavRail, useCurrentSideNavPanel } from 'components/inventory/InventorySideNav';
import { NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';
import InventoryPhotosPane from 'components/inventory/InventoryPhotosPane';
import InventoryPhotosNavItem from 'components/inventory/InventoryPhotosNavItem';
import { useFetchHomeModelsQuery, useUpdateHomeModelsMutation } from '../../../redux/homeModelApiSlice';
import {
  useCreateHomeModelImagesMutation,
  useDeleteHomeModelImageMutation,
  useFetchHomeModelImagesQuery,
  useInvalidateHomeModelImages,
  useUpdateHomeModelImagesMutation,
} from '../../../redux/homeModelImagesApiSlice';
import DataTableConfigPane from './DataTableConfigPane';
import EditDeliveryScheduleCell, { formatDeliverySchedule } from './DeliveryScheduleCell';
import HomeBuilderFilter from './HomeBuilderFilter';
import { EditInventoryAddressCell, InventoryAddressCell } from './InventoryAddressCell';
import {
  cityColumn,
  enabledColumn,
  homeBuilderNameColumn,
  marketColumn,
  NEW_BUILD_DEFAULT_COLUMN,
  stateColumn,
  subdivisionNameColumn,
  zipColumn,
} from './newBuildInventory';
import { DetailField, DetailFields, DetailPaneHeading, DetailPaneNoData } from './NewBuildInventoryDetail';
import PublishedFilter from './PublishedFilter';
import SideNavPaneContainer from './SideNavPaneContainer';
import SubdivisionFilter from './SubdivisionFilter';
import SubdivisionMarketFilter from './SubdivisionMarketFilter';
import TableEditControls from './TableEditControls';
import UnderwritableFilter from './UnderwritableFilter';

const HOME_MODEL_INFO_COL = 'info';
const columnHelper = createColumnHelper();

const infoAccessor = (key) => (
  ({ [HOME_MODEL_INFO_COL]: { [key]: value } = {} } = {}) => {
    if (Array.isArray(value)) {
      return value.join('\n');
    }

    return value;
  }
);

/**
 * @param {string} key
 * @param {import('@tanstack/react-table').IdentifiedColumnDef} [columnDef]
 * @return {import('@tanstack/react-table').ColumnDef}
 */
const infoColumn = (key, columnDef = {}) => {
  const parsedColumnDef = columnDef ?? {};
  parsedColumnDef.meta = {
    mutatorFn: (d, v) => Object.assign(d.info, { [key]: v.split('\n') }),
    ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 1 }),
    ...parsedColumnDef.meta,
  };

  return columnHelper.accessor(infoAccessor(key), {
    id: `${HOME_MODEL_INFO_COL}_${key}`,
    header: key,
    ...parsedColumnDef,
  });
};

const hiddenColumns = [
  columnHelper.accessor('requiresUnderwriting', {
    filterFn: 'equals',
    meta: { ...dataTableMeta.disableTableConfig },
  }),
  columnHelper.accessor(({ priceMin, priceMax }) => [priceMin, priceMax], {
    id: '[priceMin,priceMax]',
    filterFn: valueRangeRangeFilter,
    meta: { ...dataTableMeta.disableTableConfig },
  }),
];

const columns = [
  columnHelper.display({
    id: 'homeModelLink',
    cell: RouteLinkCell,
    meta: {
      linkPath: '/home_models/:id',
      ...tableConfigMeta({ visibility: false, order: 'hidden' }),
    },
  }),
  enabledColumn('subdivision'),
  columnHelper.accessor('inventoryUpdatedAt', {
    header: 'Inventory Updated At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      toggleSection: 'Inventory',
      datetimeFormat: 'MMM d, yyyy',
      datetimeTimeZone: 'America/Phoenix',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: TouchDatetimeCell, impliedChanges: ['numAvailable', 'futureDeliveries'] }),
    },
    filterFn: dateRangeFilter,
  }),
  columnHelper.accessor('plan', {
    header: 'Plan',
    meta: { toggleSection: 'Plan', ...enableEditing({ cell: InputCell, inputType: 'text' }) },
  }),
  subdivisionNameColumn('subdivision'),
  homeBuilderNameColumn('homeBuilder'),
  marketColumn('subdivision'),
  cityColumn('subdivision'),
  stateColumn('subdivision'),
  zipColumn('subdivision'),
  columnHelper.accessor('priceMin', {
    header: 'Min. Price',
    cell: CurrencyCell,
    meta: {
      toggleSection: 'Price',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
    },
  }),
  columnHelper.accessor('priceMax', {
    header: 'Max. Price',
    cell: CurrencyCell,
    meta: {
      toggleSection: 'Price',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
    },
  }),
  columnHelper.accessor('bedrooms', {
    header: 'Bed',
    meta: {
      toggleSection: 'Plan',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10 }),
    },
  }),
  columnHelper.accessor('bathroomsFull', {
    header: 'Full Bath',
    meta: {
      toggleSection: 'Plan',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10 }),
    },
  }),
  columnHelper.accessor('bathroomsHalf', {
    header: 'Half Bath',
    meta: {
      toggleSection: 'Plan',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10 }),
    },
  }),
  columnHelper.accessor('sqft', {
    header: 'Sqft',
    cell: IntegerCell,
    meta: {
      toggleSection: 'Plan',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number' }),
    },
  }),
  columnHelper.accessor('numAvailable', {
    header: 'Available Now',
    meta: {
      toggleSection: 'Inventory',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number' }),
    },
  }),
  columnHelper.accessor('futureDeliveries', {
    header: 'Delivery Schedule',
    cell: ({ getValue }) => formatDeliverySchedule(getValue()),
    meta: {
      toggleSection: 'Inventory',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: EditDeliveryScheduleCell }),
    },
  }),
  columnHelper.accessor('totalAvailable', {
    header: 'Available Total',
    meta: { ...dataTableMeta.textRight, toggleSection: 'Inventory' },
  }),
  columnHelper.accessor('inventoryAddresses', {
    header: 'Addresses',
    cell: InventoryAddressCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: EditInventoryAddressCell }),
    },
  }),
  columnHelper.accessor('honeycombRent', {
    header: 'Honeycomb Rent',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
      toggleSection: 'Valuation',
    },
  }),
  columnHelper.accessor('marketRent', {
    header: 'Rent',
    cell: CurrencyCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
      ...dataTableMeta.textRight,
      toggleSection: 'Valuation',
    },
  }),
  columnHelper.accessor('annualHoa', {
    header: 'Annual HOA',
    cell: CurrencyCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
      ...dataTableMeta.textRight,
      toggleSection: 'Valuation',
    },
  }),
  columnHelper.accessor('taxRate', {
    header: 'Tax Rate',
    cell: PercentCell,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'percent', precision: 2 }),
      ...dataTableMeta.textRight,
      precision: 2,
      toggleSection: 'Valuation',
    },
  }),
  columnHelper.accessor('yield', {
    cell: PercentCell,
    header: 'Yield',
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight, precision: 2, toggleSection: 'Valuation' },
  }),
  columnHelper.accessor('homeType', {
    header: 'Property Type',
    meta: { ...enableEditing({ cell: InputCell, inputType: 'text' }) },
  }),
  columnHelper.accessor('averageSchoolRating', {
    header: 'Avg School Rating',
    cell: ({ getValue }) => getValue()?.toFixed(1),
    meta: { ...dataTableMeta.textRight, toggleSection: 'Location' },
  }),
  infoColumn('Elevation'),
  infoColumn('Spec Level'),
  infoColumn('Lot Size', { meta: { ...dataTableMeta.textRight, toggleSection: 'Plan' } }),
  infoColumn('Bonus Room', { meta: { toggleSection: 'Plan' } }),
  columnHelper.accessor('garageSpaces', {
    header: 'Garage',
    meta: {
      toggleSection: 'Plan',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 9 }),
    },
  }),
  columnHelper.accessor('stories', {
    header: 'Stories',
    meta: {
      toggleSection: 'Plan',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 9 }),
    },
  }),
  infoColumn('Pool', { meta: { toggleSection: 'Plan' } }),
  infoColumn('Basement', { meta: { toggleSection: 'Plan' } }),
  infoColumn('Solar', { meta: { toggleSection: 'Plan' } }),
  infoColumn('Gated'),
  infoColumn('Water/Well', { meta: { toggleSection: 'Plan' } }),
  infoColumn('Sewer/Septic', { meta: { toggleSection: 'Plan' } }),
  infoColumn('Special Assessments', { meta: { toggleSection: 'Valuation' } }),
  columnHelper.accessor(({ images }) => images.length, {
    id: 'imagesCount',
    header: 'Photos',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('internalNotes', {
    header: 'Internal Notes',
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 3 }),
    },
  }),
  columnHelper.accessor('asOfDate', {
    header: 'Last Tape Date',
    cell: DatetimeCell,
    sortDescFirst: true,
    meta: { datetimeFormat: 'MMM d, yyyy', ...dataTableMeta.textRight, toggleSection: 'Inventory' },
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Updated At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy',
      datetimeTimeZone: 'America/Phoenix',
      ...dataTableMeta.textRight,
    },
  }),
  ...hiddenColumns,
];

/** @type {import('@tanstack/react-table').InitialTableState} */
const tableInitialState = {
  columnVisibility: {
    'requiresUnderwriting': false,
    '[priceMin,priceMax]': false,
  },
};

const TABLE_ID = 'newBuildInventoryHomeModels';
const defaultPresetId = getDefaultPresetId({ tableId: TABLE_ID });
const inventoryPresetId = `${TABLE_ID}.inventory`;

const TABLE_PRESETS = Object.freeze({
  [defaultPresetId]: Object.freeze({
    ...EMPTY_PRESET,
    id: defaultPresetId,
  }),
  [inventoryPresetId]: Object.freeze({
    ...EMPTY_PRESET,
    id: inventoryPresetId,
    name: 'Inventory',
    defaultVisibility: false,
    visibility: Object.freeze({
      enabled: true,
      inventoryUpdatedAt: true,
      numAvailable: true,
      futureDeliveries: true,
      totalAvailable: true,
      plan: true,
      subdivision_name: true,
      subdivision_city: true,
      subdivision_state: true,
      homeBuilder_name: true,
      lastTapeDate: true,
      internalNotes: true,
    }),
  }),
});

const tableMeta = Object.freeze({ ...enableConfigPresets({ presets: TABLE_PRESETS }) });

function HomeModelDetailPane() {
  const [row] = useFilteredSelectedRows() ?? [];
  const {
    original: {
      plan,
      info: { 'Property Address': addresses, 'Lot #': lots, 'MLS #': mls } = {},
      subdivision = {},
      homeBuilder = {},
    } = {},
  } = row ?? {};

  const show = useCurrentSideNavPanel() === 'detail';

  if (!show) {
    return null;
  }

  return row ? (
    <>
      <DetailPaneHeading subheading={`${subdivision.name}`}>
        {plan}
      </DetailPaneHeading>

      <DetailFields>
        <DetailField label="Property Addresses">{(isArray(addresses) ? addresses.join('\n') : addresses) || '—'}</DetailField>
        <DetailField label="Lot #">{(isArray(lots) ? lots.join('\n') : lots) || '—'}</DetailField>
        <DetailField label="MLS #">{(isArray(mls) ? mls.join('\n') : mls) || '—'}</DetailField>
        <DetailField label="Community">{subdivision.name}</DetailField>
        <DetailField label="Community Address">{`${subdivision.city}, ${subdivision.state} ${subdivision.zip}`}</DetailField>
        <DetailField label="Enabled">{subdivision.enabled ? 'Enabled' : 'Disabled'}</DetailField>
        <DetailField label="Market">{subdivision.market}</DetailField>
        <DetailField label="Restrictions">{subdivision.restrictions}</DetailField>
        <DetailField label="Description">{subdivision.description}</DetailField>
        <DetailField label="Amenities">{subdivision.amenities?.join(', ') || '—'}</DetailField>
        <DetailField label="Builder">{homeBuilder.name}</DetailField>
        <DetailField label="Builder Website">
          {homeBuilder.website && (
            <ExternalUrl href={homeBuilder.website} className="max-w-full truncate">
              {homeBuilder.website}
            </ExternalUrl>
          )}
        </DetailField>
        <DetailField label="Description">{homeBuilder.description}</DetailField>
      </DetailFields>
    </>
  ) : (
    <DetailPaneNoData />
  );
}

const presenceCheck = field => row => !!get(row, field);
const lengthCheck = field => row => get(row, field).length;
const infoCheck = field => row => !!get(row, 'info')[field];
const DATA_COMPLETENESS_CHECKS = [
  ['annualHoa', presenceCheck, 'HOA'],
  ['honeycombRent', presenceCheck, 'Honeycomb Rent'],
  ['images', lengthCheck, 'Images'],
  ['marketRent', presenceCheck, 'Rent'],
  ['taxRate', presenceCheck, 'Tax Rate'],
  ['subdivision.amenities', lengthCheck, 'Amenities'],
  ['subdivision.description', presenceCheck, 'Subdivision Description'],
  ['subdivision.elementarySchoolRating', presenceCheck, 'Elementary School Rating'],
  ['subdivision.juniorHighSchoolRating', presenceCheck, 'Junior High School Rating'],
  ['subdivision.highSchoolSchoolRating', presenceCheck, 'High School School Rating'],
  ['Property Address', infoCheck, 'Addresses'],
  ['Lot Size', infoCheck],
  ['Bonus Room', infoCheck],
  ['Garage', infoCheck],
  ['Stories', infoCheck],
  ['Pool', infoCheck],
  ['Basement', infoCheck],
  ['Solar', infoCheck],
  ['Gated', infoCheck],
  ['Water/Well', infoCheck],
  ['Sewer/Septic', infoCheck],
];

function DataCompletenessPane() {
  const rows = useFilteredRows();
  const show = useCurrentSideNavPanel() === 'data';

  const completeness = useMemo(() => {
    if (rows.length) {
      return DATA_COMPLETENESS_CHECKS.map(tuple => {
        const rowsThatPass = rows.filter(row => tuple[1](tuple[0])(row.original));
        return [tuple[2] || tuple[0], rowsThatPass.length];
      });
    }
    return null;
  }, [rows]);

  if (!show) {
    return null;
  }

  return (
    <div className="mt-12 px-3 h-full overflow-auto">
      {completeness?.map(tuple => (
        <div key={tuple[0]} className="w-full flex justify-between py-1 border-b">
          <div className="text-sm">{tuple[0]}</div>
          <div>{formatPercentage(tuple[1] / rows.length)}</div>
        </div>
      ))}
    </div>
  );
}

function HomeModelTable({ children, edit }) {
  const { currentData, isFetching, isUninitialized } = useFetchHomeModelsQuery();

  return (
    <DataTable
      virtual
      columns={columns}
      data={currentData}
      initialState={tableInitialState}
      defaultColumn={NEW_BUILD_DEFAULT_COLUMN}
      isLoading={(isUninitialized || isFetching) && !currentData}
      tableContainerClassName="flex-1 whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      onRowClick={selectRowOnClick}
      enableMultiRowSelection={false}
      enableEditing={edit}
      meta={tableMeta}
    >
      {children}
    </DataTable>
  );
}

function EditControls({ isEditMode, setIsEditMode, className }) {
  const [
    updateHomeModels,
    {
      isLoading,
      isSuccess,
      isError,
      error,
      reset: resetMutation,
    },
  ] = useUpdateHomeModelsMutation();

  const onEditToggle = useCallback(() => {
    resetMutation();
  }, [resetMutation]);

  const onSave = useCallback((homeModels) => {
    updateHomeModels({ homeModels });
  }, [updateHomeModels]);

  return (
    <TableEditControls
      className={className}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      isUpdating={isLoading}
      updateSucceeded={isSuccess}
      updateFailed={isError}
      updateError={error?.data?.error}
      onEditToggle={onEditToggle}
      onSave={onSave}
    />
  );
}

function StatItem({ label, value }) {
  return (
    <div className="px-4 text-center">
      <div className="text-lg">{value || '-'}</div>
      <div className="text-xs font-light">{label}</div>
    </div>
  );
}

const calculateStats = data => [{
  label: '# of Plans',
  value: formatInteger(data.length),
}, {
  label: '# Published',
  value: formatInteger(data.filter(d => d.subdivision.enabled).length),
}, {
  label: 'Available Now',
  value: formatInteger(sumBy(data, 'numAvailable')),
}, {
  label: 'Available Total',
  value: formatInteger(sumBy(data, 'totalAvailable')),
}, {
  label: 'Avg. Price',
  value: formatCurrencyAbbreviated(mean(compact(data.map(d => d.priceMin)))),
}, {
  label: 'Avg. Rent',
  value: formatCurrency(mean(compact(data.map(d => d.marketRent)))),
}, {
  label: 'Avg. Yield',
  value: formatPercentage(mean(compact(data.map(d => d.yield))), 2),
}];

function SummaryStats({ className }) {
  const rows = useFilteredRows();
  const stats = useMemo(() => (rows ? calculateStats(rows.map(d => d.original)) : []), [rows]);

  return (
    <div className={cx('flex gap-x-4', className)}>
      {stats.map(stat => (
        <StatItem key={stat.label} {...stat} />
      ))}
    </div>
  );
}

export default function NewBuildInventoryHomeModels() {
  const [isEditMode, setIsEditMode] = useState(false);
  const priceRangeDisplayValue = useRangeDisplayValue({ formatter: formatCurrencyAbbreviated });
  const pctRangeDisplayValue = useRangeDisplayValue({ formatter: formatPercentage });
  const dateRangeDisplayValue = useRangeDisplayValue({ formatter: formatDate });

  return (
    <HomeModelTable edit={isEditMode}>
      <div className="flex justify-between items-center py-1.5 px-3 border-b">
        <div>
          <ReactTableFilter>
            <ChipGroup>
              <ReactTableColumnFilter
                label="Builder"
                columnId="homeBuilder_name"
                displayValue="name"
              >
                <FilterChip>
                  <HomeBuilderFilter />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Market"
                columnId="subdivision_market"
              >
                <FilterChip>
                  <SubdivisionMarketFilter />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Community"
                columnId="subdivision_name"
                displayValue="name"
              >
                <FilterChip>
                  <SubdivisionFilter />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Price"
                columnId="[priceMin,priceMax]"
                displayValue={priceRangeDisplayValue}
              >
                <FilterChip>
                  <RangeFilter type="currency" />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Yield"
                columnId="yield"
                displayValue={pctRangeDisplayValue}
              >
                <FilterChip>
                  <RangeFilter type="percent" />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Inventory Updated At"
                columnId="inventoryUpdatedAt"
                displayValue={dateRangeDisplayValue}
              >
                <FilterChip>
                  <RangeFilter type="date" />
                </FilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Published"
                staticDisplayValue="Published"
                columnId="enabled"
              >
                <BooleanFilterChip>
                  <PublishedFilter />
                </BooleanFilterChip>
              </ReactTableColumnFilter>
              <ReactTableColumnFilter
                label="Needs Underwriting"
                staticDisplayValue="Needs Underwriting"
                columnId="requiresUnderwriting"
              >
                <BooleanFilterChip>
                  <UnderwritableFilter />
                </BooleanFilterChip>
              </ReactTableColumnFilter>
              <AllFiltersChip />
            </ChipGroup>
          </ReactTableFilter>
        </div>
        <SummaryStats className="grow justify-center" />
        <EditControls isEditMode={isEditMode} setIsEditMode={setIsEditMode} className="justify-self-end" />
      </div>
      <div className="flex flex-row h-0 flex-1">
        <DataTableContent />
        <InventorySideNav>
          <SideNavPaneContainer>
            <HomeModelDetailPane />
            <DataCompletenessPane />
            <InventoryPhotosPane
              useFetchInventoryImagesQuery={useFetchHomeModelImagesQuery}
              useUpdateInventoryImagesMutation={useUpdateHomeModelImagesMutation}
              useCreateInventoryImagesMutation={useCreateHomeModelImagesMutation}
              useDeleteInventoryImageMutation={useDeleteHomeModelImageMutation}
              useInvalidateInventoryImages={useInvalidateHomeModelImages}
            />
            <DataTableConfig tableId={TABLE_ID}>
              <DataTableConfigPane />
            </DataTableConfig>
          </SideNavPaneContainer>
          <SideNavRail page="homeModel">
            <InventoryNavItem name="data">
              <NavItemIcon>
                <InformationIcon />
              </NavItemIcon>
              <NavItemLabel>Data Check</NavItemLabel>
            </InventoryNavItem>

            <InventoryPhotosNavItem />
          </SideNavRail>
        </InventorySideNav>
      </div>
    </HomeModelTable>
  );
}
