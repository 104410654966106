// NOTE: you must update the version in users#accept_agreements as well when updating VERSION
export const VERSION = '20241208';
export const HTML = (
  <>
    <h1 className="font-medium mb-4">NON-CIRCUMVENTION, NON-DISCLOSURE, BROKERAGE AND WORKING AGREEMENT</h1>
    <p>This Non-Circumvention, Non-Disclosure, Brokerage and Working Agreement (“Agreement”) is between Nhimble Homes LLC (“Nhimble Homes”) and the user (“Receiving Party”).</p>
    <h2 className="font-medium my-3">RECITALS</h2>
    <p className="my-2">WHEREAS, Nhimble Homes possesses certain information relating to potential purchasers (“Purchase Sources”) not known by Receiving Party;</p>
    <p className="my-2">WHEREAS, the Receiving Party is interested in procuring real estate transactions with such Purchase Sources; and</p>
    <p className="my-2">WHEREAS, Nhimble Homes and Receiving Party wish to enter into this agreement to define certain parameters of their future legal obligations.</p>
    <h2 className="font-medium my-3">AGREEMENT</h2>
    <p className="my-3">NOW, THEREFORE, in consideration of mutual promises and other valuable considerations, the parties hereby mutually and voluntarily agree as follows:</p>
    <p className="my-2">1.&emsp;<u>Purchase Source Identification.</u> Nhimble Homes will identify certain Purchase Sources not known by Receiving Party which shall be set forth on Exhibit A, attached hereto and incorporated herein. Within ten(10) days of identifying a Purchase Source, Nhimble Homes shall amend Exhibit A to include such Purchase Source and deliver a copy of the amended Exhibit A to Receiving Party. The Receiving Party shall have two (2) business days to object to the amendment to Exhibit A by providing documentation that completely and conclusively evidences Receiving Party's prior material relationship with the Purchase Source added to Exhibit A. If Receiving Party fails to object or provide such evidence documenting Receiving Party's prior material relationship with the Purchase Source, the revised Exhibit A shall be deemed automatically amended and incorporated into this Agreement.</p>
    <p className="my-2">2.&emsp;<u>Non-Solicitation; Non-Circumvention.</u> Neither the Receiving Party (nor any of its affiliates, subsidiaries, partners, interest holders, members, managers, directors, employees, officers, employees, agents, brokers, or representatives) shall, in any manner access, contact, solicit or conduct any real estate transaction with a Purchase Source that has been made available by and through Nhimble Homes. The Receiving Party shall not in any way whatsoever circumvent or attempt to circumvent Nhimble Homes and shall not enter into direct or indirect offers, negotiations or transactions with a Purchase Source revealed by Nhimble Homes. For purposes of this provision, a Purchase Source shall also include any affiliates, subsidiaries, partners, interest holders, members, managers, directors, employees, officers, employees, agents, brokers, successors or assigns of such Purchase Source.</p>
    <p className="my-2">3.&emsp;<u>Non-Disclosure.</u> The parties must keep each other’s business and affiliates confidential and only disclose information about the Purchase Sources as permitted by Nhimble Homes, unless Nhimble Homes gives written permission.</p>
    <p className="my-2">4.&emsp;<u>Broker Relationship.</u> Nhimble Homes, acting as a broker, shall be compensated for referrals. Compensation will be negotiated separately and agreed upon by the Receiving Party and Nhimble Homes. Any real estate transaction between the Receiving Party and Nhimble Homes’ Purchase Sources must be negotiated with prior written agreement for Nhimble Homes’ compensation.</p>
    <p className="my-2">5.&emsp;<u>Damages.</u> In the event of any circumvention by Receiving Party, whether direct and/or indirect, intentional or not, Receiving Party expressly agrees that Nhimble Homes shall be entitled to monetary compensation equal to two times (2x) the maximum fee it would have realized from such a transaction, plus all legal fees and expenses incurred toward the pursuit and recovery of such compensation. Receiving Party further agrees that money damages may not be a sufficient remedy for breach of this Agreement, and that, in addition to any money damages Nhimble Homes may seek hereunder, Nhimble Homes shall also be entitled to seek equitable relief, including injunction and specific performance.</p>
    <p className="my-2">6.&emsp;<u>Term.</u> This agreement is valid for three years from the Effective Date and applies to all real estate transactions involving any Purchase Source or related individual or entity.</p>
    <p className="my-2">7.&emsp;<u>Governing Law.</u> This Agreement shall be governed and construed in accordance with the laws of the State of Arizona without regard to any provisions regarding conflict of laws.</p>
    <p className="my-2">8.&emsp;<u>Waiver of Jury Trial.</u> EACH PARTY KNOWINGLY, VOLUNTARILY AND INTENTIONALLY, WAIVES (TO THE EXTENT PERMITTED BY APPLICABLE LAW) ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY OF ANY DISPUTE ARISING UNDER OR RELATING TO THIS AGREEMENT AND AGREES THAT ANY SUCH DISPUTE SHALL BE TRIED BEFORE A JUDGE SITTING WITHOUT A JURY.</p>
    <p className="my-2">9.&emsp;<u>Binding on Successors and Assigns.</u> This Agreement shall be binding upon the parties hereto and in the case of individual parties, their respective heirs, administrators and executors and in the case of all corporate parties, their successors and assigns.</p>
    <p className="my-2">10.&emsp;<u>Assignment.</u> This Agreement, and the rights and obligations hereunder, may not be transferred or assigned by one party without the prior written consent of the other party. Any such attempt by one party without the prior written consent of the other party shall be void.</p>
    <p className="my-2">11.&emsp;<u>Entire Agreement.</u> This Agreement replaces all prior oral and written communications between the parties regarding the subject matter. If any provision is declared illegal or unenforceable, the remaining provisions will remain valid and enforceable, as long as they comply with the law.</p>
    <p className="my-2">12.&emsp;<u>Counterparts.</u> This agreement may be signed by multiple parties. Facsimile copies of this Agreement are considered legal originals and signatures thereon are legally binding.</p>
    <p className="my-2">This agreement applies to the following list of buyers and their associated entities:</p>
    <ul>
      <li>1Sharpe</li>
      <li>Adoor</li>
      <li>American Homes 4 Rent</li>
      <li>Amherst</li>
      <li>Ark Homes 4 Rent</li>
      <li>Arrived Homes</li>
      <li>Atlas Real Estate</li>
      <li>AvantStay</li>
      <li>Beacon Ridge Capital Management</li>
      <li>Bridge Single Family Rental</li>
      <li>Bridge Tower Residential</li>
      <li>C-Star</li>
      <li>Center Creek</li>
      <li>Coastal Up</li>
      <li>Concreit</li>
      <li>Darwin Homes</li>
      <li>Doorvest</li>
      <li>Evergreen Residential</li>
      <li>First Key Homes</li>
      <li>Fortune Acquisitions</li>
      <li>Fundrise</li>
      <li>Haven Investments</li>
      <li>Haven Realty Capital</li>
      <li>Hawkhill Homes</li>
      <li>Home Partners of America</li>
      <li>Hudson Homes</li>
      <li>ILE Homes</li>
      <li>Imagine Homes</li>
      <li>Invitation Homes</li>
      <li>Kairos Living</li>
      <li>Kalanchoe</li>
      <li>Lafayette RE</li>
      <li>Magnolia</li>
      <li>Main Street Renewal</li>
      <li>Man GPM</li>
      <li>Maymont</li>
      <li>More Residential</li>
      <li>Nhimble</li>
      <li>Offerpad</li>
      <li>OpenHouse Group</li>
      <li>Opendoor</li>
      <li>PCCP, LLC</li>
      <li>Pagaya</li>
      <li>Pathway</li>
      <li>Pintar Investment Company</li>
      <li>Pretium</li>
      <li>Prime Asset Fund</li>
      <li>Progress Residential</li>
      <li>Resicap</li>
      <li>Roofstock</li>
      <li>Roots</li>
      <li>Second Ave</li>
      <li>Sylvan Road Capital</li>
      <li>The Promise Homes Company</li>
      <li>Tricon Residential</li>
      <li>Trilogy Investment Company</li>
      <li>Truehold</li>
      <li>Up&Up</li>
      <li>Vinebrook Homes</li>
      <li>Wrightwell</li>
    </ul>
  </>
);
