import { useState, useEffect } from 'react';
import DataTable from 'components/shared/Table/DataTable';
import { formatCurrency, formatDate } from 'components/utils';
import classNames from 'classnames';
import { useUpdateOfferMutation } from 'redux/offerApiSlice';
import { showToast } from 'redux/toastSlice';
import { useDispatch } from 'react-redux';
import Modal from 'components/NewModal';
import OfferForm from 'components/deal/offer/OfferForm';
import Button from 'components/shared/NewButton';
import { Paperclip, Pencil, Plus, Trash } from 'components/icons';
import DeleteOfferModal, { CreateOfferModal } from 'components/deal/offer/OfferActions';
import OfferAttachmentModal from 'components/deal/offer/OfferAttachmentModal';
import DashboardModal from '../DashboardModal';

function OfferActionsCell(props) {
  const {
    row: { getValue, original: { id: offerId } },
    column: { columnDef: { meta: { onEdit, onConfirmAction } } },
  } = props;
  const status = getValue('status');

  switch (status) {
    // received, re_opened and presented_to_seller all have same options
    case 'received':
    case 're_opened':
    case 'presented_to_seller':
      return (
        <div className="flex gap-2">
          <Button onClick={() => onEdit(offerId, 'accepted')} small outlined label="Accept" />
          <Button onClick={() => onEdit(offerId, 'rejected')} danger small outlined label="Reject" />
          <Button onClick={() => onEdit(offerId, 'seller_counter')} danger small outlined label="Counter" />
        </div>
      );
    case 'rejected':
      return <Button onClick={() => onConfirmAction(offerId, 're_opened')} small outlined label="Re-Open" />;
    case 'accepted':
      return <Button onClick={() => onConfirmAction(offerId, 'terminated')} small outlined label="Terminate" />;
    default:
      return null;
  }
}

function MenuCell({ getValue, column: { columnDef: { meta: { onEdit, onDelete, onAttachment } } } }) {
  const offer = getValue();
  const attachmentCount = offer.attachments?.length || 0;

  return (
    <div className="relative">
      <div className="flex justify-center gap-x-1 items-center">
        <div
          className="relative flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 hover:text-blue-400 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onAttachment(offer);
          }}
        >
          <Paperclip className="w-6 h-6 mx-auto" />
          {attachmentCount > 0 && (
            <span className="absolute -top-1 left-4 inline-flex items-center justify-center px-1.5 py-1 text-xs font-bold leading-none text-primary-700 bg-primary-200 rounded-full">
              {attachmentCount}
            </span>
          )}
        </div>
        <div
          className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 hover:text-blue-400 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onEdit(offer);
          }}
        >
          <Pencil className="w-6 h-6 mx-auto" />
        </div>
        <div
          className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 hover:text-red-700 cursor-pointer"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onDelete(offer);
          }}
        >
          <Trash className="w-6 h-6 mx-auto" />
        </div>
      </div>
    </div>
  );
}

function OfferStatusBadge({ status }) {
  const badgeClass = classNames('m-1 text-xs px-2 rounded-lg py-0.5 font-bold', {
    'bg-green-500 text-white': status === 'accepted',
    'bg-yellow-500 text-white': status === 'pending',
    'bg-red-500 text-white': status === 'rejected',
    'bg-blue-500 text-white': status === 'presented_to_seller',
    'bg-orange-500 text-white': status === 'seller_counter',
    'bg-purple-500 text-white': status === 'buyer_counter',
    'bg-gray-600 text-white': status === 'terminated',
    'bg-teal-500 text-white': status === 're_opened',
    'bg-gray-400 text-white': ![
      'accepted',
      'pending',
      'rejected',
      'presented_to_seller',
      'seller_counter',
      'buyer_counter',
      'terminated',
      're_opened',
    ].includes(status),
  });
  const formattedStatus = status.split('_').join(' ');
  return <span className={badgeClass}>{formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1)}</span>;
}

function OfferStatusCell({ getValue }) {
  return <OfferStatusBadge status={getValue()} />;
}

export function EditOfferModal({ offerData: offerToEdit, onClose }) {
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();
  const [offerData, setOfferData] = useState(offerToEdit);
  const [updateOffer, { isLoading: isUpdatingOffer }] = useUpdateOfferMutation();

  const handleSubmit = async data => {
    try {
      await updateOffer(data).unwrap();
      dispatch(showToast({ type: 'danger', message: 'Offer updated successfully!', hasTimeouts: true }));
      onClose();
    } catch (error) {
      setAlert({ type: 'danger', text: 'Failed to update offer' });
    }
  };

  return (
    <Modal title={`Edit Offer ${offerToEdit.buyerAgentName}`} size="medium" onClose={onClose} show>
      <OfferForm
        alert={alert}
        isLoading={isUpdatingOffer}
        offerData={offerData}
        onSubmit={handleSubmit}
        setAlert={setAlert}
        setOfferData={setOfferData}
        showCloseAction={false}
        hideBuyerDetails
      />
    </Modal>
  );
}

function ConfirmationModal({ offerData, action, onConfirm, onClose, isUpdating }) {
  return (
    <DashboardModal
      title={`Confirm ${action} for ${offerData.buyerAgentName}`}
      onClose={onClose}
      action={(
        <Button
          danger
          filled
          label="Confirm"
          type="submit"
          isLoading={isUpdating}
        />
    )}
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onConfirm();
      }}
    >
      <p>
        Are you sure you want to
        {action.toLowerCase()}
        {' '}
        this offer?
      </p>
      <ul className="list-disc pl-5 space-y-2 mt-4">
        <li className="flex">
          <span className="font-bold w-32">Buyer Agent Name:</span>
          <span>{offerData.buyerAgentName}</span>
        </li>
        <li className="flex">
          <span className="font-bold w-32">Offer Price:</span>
          <span>{formatCurrency(offerData.price)}</span>
        </li>
        <li className="flex">
          <span className="font-bold w-32">Offer Status:</span>
          <OfferStatusBadge status={offerData.status} />
        </li>
      </ul>
    </DashboardModal>
  );
}

export default function OfferWorkflowTable({ offers }) {
  const dispatch = useDispatch();
  const [updateOffer, { isLoading: isUpdating }] = useUpdateOfferMutation();
  const [offerData, setOfferData] = useState({});
  const [editingOffer, setEditingOffer] = useState(null);
  const [confirmAction, setConfirmAction] = useState(null);
  const [modalState, setModalState] = useState({
    create: false,
    delete: false,
    edit: false,
    attachment: false,
  });

  const handleUpdateOfferStatus = async (offerId, newStatus) => {
    try {
      await updateOffer({ id: offerId, status: newStatus }).unwrap();
      dispatch(showToast({ type: 'danger', message: 'Offer status updated successfully!', hasTimeouts: true }));
    } catch (error) {
      console.error('Failed to update offer status:', error);
      dispatch(showToast({ type: 'danger', message: 'Failed to update offer status. Please try again.', hasTimeouts: true }));
    }
  };

  useEffect(() => {
    if (offerData.id) {
      const updatedOffer = offers.find(offer => offer.id === offerData.id);
      if (updatedOffer) {
        setOfferData(updatedOffer);
      }
    }
  }, [offers, offerData.id]);

  const handleNewOffer = () => {
    setOfferData({});
    setModalState({ ...modalState, create: true });
  };

  const handleEditOffer = (offerId, newStatus) => {
    const offerToEdit = offers.find(offer => offer.id === offerId);
    setEditingOffer({ ...offerToEdit, status: newStatus });
  };

  const handleConfirmAction = (offerId, newStatus) => {
    const offerToConfirm = offers.find(offer => offer.id === offerId);
    setConfirmAction({ offer: offerToConfirm, status: newStatus });
  };

  const handleCloseModal = (type) => {
    setModalState({ ...modalState, [type]: false });
  };

  const handleRowClick = (row) => {
    setOfferData(row.original || row);
    setModalState({ ...modalState, edit: true });
  };

  const handleDeleteOffer = (row) => {
    setOfferData(row);
    setModalState({ ...modalState, delete: true });
  };

  const handleOfferAttachment = (row) => {
    setOfferData(row);
    setModalState({ ...modalState, attachment: true });
  };

  const columns = [
    {
      id: 'buyerAgentName',
      header: 'Buyer Agent Name',
      accessorKey: 'buyerAgentName',
    },
    {
      id: 'status',
      header: 'Offer Status',
      accessorKey: 'status',
      cell: OfferStatusCell,
    },
    {
      id: 'price',
      header: 'Offer Price',
      accessorKey: 'price',
      cell: ({ getValue }) => formatCurrency(getValue()),
    },
    {
      id: 'offerDate',
      header: 'Offer Received',
      accessorKey: 'createdAt',
      cell: ({ getValue }) => formatDate(getValue(), 'MMM dd'),
    },
    {
      id: 'updatedAt',
      header: 'Offer Updated',
      accessorKey: 'updatedAt',
      cell: ({ getValue }) => formatDate(getValue(), 'MMM dd'),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: OfferActionsCell,
      meta: {
        onEdit: handleEditOffer,
        onConfirmAction: handleConfirmAction,
      },
    },
    {
      id: 'menu',
      header: '',
      enableSorting: false,
      accessorFn: (row) => row,
      cell: MenuCell,
      enableHiding: false,
      meta: {
        onEdit: handleRowClick,
        onDelete: handleDeleteOffer,
        onAttachment: handleOfferAttachment,
      },
    },
  ];

  return (
    <div className="w-full overflow-x-scroll">
      <div className="mt-8 mb-4 flex justify-between items-center">
        <div className="text-sm font-medium tracking-wider text-gray-700">Offers</div>
        <Button
          textOnly
          label="Add Offer"
          onClick={handleNewOffer}
          leadingIcon={<Plus className="w-4" />}
        />
      </div>
      <DataTable
        columns={columns}
        data={offers}
        tableContainerClassName="flex-1 whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0 rounded-lg"
        tdClassName="p-3"
        thClassName="p-3 font-bold"
        enableMultiRowSelection={false}
      />
      {editingOffer && <EditOfferModal offerData={editingOffer} onClose={() => setEditingOffer(null)} />}
      {confirmAction && (
        <ConfirmationModal
          offerData={confirmAction.offer}
          action={confirmAction.status === 're_opened' ? 'Re-Open' : 'Terminate'}
          onConfirm={() => {
            handleUpdateOfferStatus(confirmAction.offer.id, confirmAction.status);
            setConfirmAction(null);
          }}
          isUpdating={isUpdating}
          onClose={() => setConfirmAction(null)}
        />
      )}
      {modalState.delete && (
        <DeleteOfferModal
          offerData={offerData}
          onClose={() => handleCloseModal('delete')}
        />
      )}
      {modalState.edit && (
        <EditOfferModal
          offerData={offerData}
          onClose={() => handleCloseModal('edit')}
        />
      )}
      {modalState.create && (
        <CreateOfferModal
          offerData={offerData}
          onClose={() => handleCloseModal('create')}
        />
      )}
      {modalState.attachment && (
        <OfferAttachmentModal
          offerData={offerData}
          onClose={() => handleCloseModal('attachment')}
        />
      )}
    </div>
  );
}
