export const CURRENT_USER_TAG = 'CurrentUser';
export const DEAL_CONTEXT_TAG = 'DealContext';
export const HOME_BUILDERS_TAG = 'HomeBuilders';
export const HOME_MODELS_TAG = 'HomeModels';
export const HOME_MODEL_IMAGES_TAG = 'HomeModelImages';
export const IMAGES_TAG = 'Images';
export const OFFERS_TAG = 'Offers';
export const OFF_MARKET_LISTINGS_TAG = 'OffMarketListings';
export const OFF_MARKET_OFFERS_TAG = 'OffMarketOffers';
export const SUBDIVISIONS_TAG = 'subdivisions';
export const LISTING_ATTACHMENTS_TAG = 'ListingAttachments';
export const LISTING_IMAGES_TAG = 'ListingImages';
export const LISTING_NOTES_TAG = 'ListingNotes';
