import { NotesFilled, NotesOutlined } from 'components/icons';
import { InventoryNavItem } from 'components/inventory/InventorySideNav';
import { NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';

export default function ListingNotesNavItem() {
  return (
    <InventoryNavItem name="notes">
      <NavItemIcon>
        <NotesOutlined className="block group-aria-selected/nav:hidden" />
        <NotesFilled className="hidden group-aria-selected/nav:block" />
      </NavItemIcon>
      <NavItemLabel>Notes</NavItemLabel>
    </InventoryNavItem>
  );
}
