import Toast from 'components/shared/Toast';
import { camelCaseKeys } from 'components/utils';
import { CHANNEL_NEW_BUILD } from 'components/constants';
import { groupBy, isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useLocalStorage from 'hooks/useLocalStorage';
import { useFetchSelfQuery } from '../../redux/apiSlice';
import { defaultFilters, useDealSourcingItems, useUpdateDealStatusCache } from './dealSourcing';
import List from './List/List';
import Map from './Map';

const defaultSettings = (defaultMarket, portfolio) => ({
  channel: portfolio.channels[0],
  market: defaultMarket,
  portfolioId: portfolio.id,
  sortBy: 'listingContractDate-desc',
});

const LOCAL_STORAGE_DEAL_SOURCING_SETTINGS_VERSION = 2;
const LOCAL_STORAGE_DEAL_SOURCING_FILTERS_VERSION = 1;

export default function Base({
  currentUser,
  market: defaultMarket,
  markets,
  portfolio: portfolioRaw,
  portfolios: portfoliosRaw,
}) {
  const initialPortfolio = useMemo(() => camelCaseKeys(portfolioRaw), [portfolioRaw]);
  const portfolios = useMemo(() => camelCaseKeys(portfoliosRaw), [portfoliosRaw]);
  const initialSettings = defaultSettings(defaultMarket, initialPortfolio);
  // TODO: portfolio in localStorage could be stale, e.g. portfolio removed server-side, name/channel/market modified server-side
  const [settings, setSettings] = useLocalStorage('DealSourcing.settings', initialSettings, LOCAL_STORAGE_DEAL_SOURCING_SETTINGS_VERSION);
  const { channel, portfolioId } = settings;
  const portfolio = portfolios.find(p => p.id === portfolioId);

  if (!portfolio) {
    // HACK: We were not previously storing portfolio in settings, so need to make sure
    //       localStorage settings include the portfolio to prevent bugs
    // TODO: add localStorage item-specific "versioning" logic to more elegantly handle situations like this
    localStorage.removeItem('DealSourcing.settings');
    localStorage.removeItem('DealSourcing.filters');
    window.location.reload();
  }

  // sync server-side currentPortfolio value to localStorage
  const { currentData: { currentPortfolio } = {} } = useFetchSelfQuery(undefined, { refetchOnMountOrArgChange: true, refetchOnFocus: true });
  if (!isNil(currentPortfolio) && currentPortfolio.id !== portfolio.id) {
    setSettings((prevSettings) => {
      const currentPortfolioObj = portfolios.find(({ id }) => id === currentPortfolio.id);
      if (currentPortfolioObj === undefined) {
        // portfolio missing from portfolios array
        // it's probably a newly created portfolio, reload to fetch data
        window.location.reload();
      }

      return {
        ...prevSettings,
        portfolioId: currentPortfolioObj.id,
      };
    });
  }

  const initialFilters = defaultFilters(channel, portfolio);
  const [filters, setFilters] = useLocalStorage('DealSourcing.filters', initialFilters, LOCAL_STORAGE_DEAL_SOURCING_FILTERS_VERSION);
  const channelSetRef = useRef(false);

  // reset filters every time channel changes
  useEffect(() => {
    // do not trigger on initial effect run
    if (channelSetRef.current) {
      setFilters(defaultFilters(channel, portfolio));
    }
    channelSetRef.current = true;
  }, [channel]);

  const {
    filteredItems,
    propertySubTypes,
    homeModelBuilders,
    homeModelSubdivisions,
    isFetching,
  } = useDealSourcingItems({ settings, filters, portfolio });

  const [listHeading, setListHeading] = useState(null);
  const [selectedItem, setSelectedItemRaw] = useState(null);
  const [idVisibilityMap, setIdVisibilityMap] = useState(null);
  const { activeToast } = useSelector(state => state.toast);
  const listRef = useRef(null);
  const mapRef = useRef(null);

  const setSelectedItem = useCallback((newValue) => setSelectedItemRaw(prevValue => {
    if (newValue?.id === prevValue?.id) {
      return prevValue;
    }
    mapRef.current?.selectedItemChanged(prevValue, newValue);
    return newValue;
  }), []);

  const updateDealStatus = useUpdateDealStatusCache(portfolio);

  const mapItems = useMemo(() => (
    channel !== CHANNEL_NEW_BUILD ? (
      filteredItems
    ) : (
      Object
        .values(groupBy(filteredItems, 'subdivision.id'))
        .map((homeModels) => (
          Object.assign(homeModels, {
            ...homeModels[0].subdivision,
            homeBuilder: homeModels[0].homeBuilder,
            // assume that there is at most 1 deal per subdivision
            // find the first homeModel with a deal
            // a home model deal should include all home models in a subdivision but
            // home models can be added after a deal has been created
            dealId: homeModels.find(({ dealId }) => !isNil(dealId))?.dealId,
          })
        ))
    )
  ), [channel, filteredItems]);

  return (
    <main className="flex h-screen">
      <List
        ref={listRef}
        mapRef={mapRef}
        currentUser={currentUser}
        filters={filters}
        isFetching={isFetching}
        items={filteredItems}
        markets={markets}
        portfolio={portfolio}
        portfolios={portfolios}
        propertySubTypes={propertySubTypes}
        homeModelBuilders={homeModelBuilders}
        homeModelSubdivisions={homeModelSubdivisions}
        setFilters={setFilters}
        setSettings={setSettings}
        settings={settings}
        listHeading={listHeading}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        idVisibilityMap={idVisibilityMap}
      />
      <Map
        ref={mapRef}
        listRef={listRef}
        items={mapItems}
        filters={filters}
        setFilters={setFilters}
        settings={settings}
        setListHeading={setListHeading}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setIdVisibilityMap={setIdVisibilityMap}
        portfolio={portfolio}
      />
      {!isEmpty(activeToast) && <Toast hasTimeouts {...activeToast} />}
    </main>
  );
}
