import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, partial, some, xor } from 'lodash';
import Alert from 'components/Alert';
import { X } from 'components/icons';
import Input from 'components/Input';
import { naturalSortComparator, parseEventValue, validateEmail } from 'components/utils';
import { CHANNEL_LABELS, MARKETS } from 'components/constants';
import CheckboxList from 'components/shared/CheckboxList';
import Button from 'components/shared/NewButton';
import { useCreateOrganizationMutation } from 'redux/organizationApiSlice';
import { useFetchHomeBuildersQuery } from 'redux/homeBuilderApiSlice';

const CHANNEL_OPTIONS = Object.entries(CHANNEL_LABELS).map(entry => ({ label: entry[1], value: entry[0] }));

function FormInput({ className = '', label, name, onChange, value, type = 'text', ...inputProps }) {
  return (
    <div className={className}>
      <label htmlFor={name} className="font-medium text-sm">{label}</label>
      <Input
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        className="h-12 mt-2 mb-6 border"
        padding="py-1 px-2"
        width="w-full"
        {...inputProps}
      />
    </div>
  );
}

function UserRow({ user, updateUser, removeUser, index }) {
  const onChangeEmail = (event) => {
    updateUser({ ...user, email: parseEventValue(event) });
  };
  const { email } = user;
  return (
    <div className="grid grid-cols-8 gap-x-2 items-center">
      <Input
        className="col-span-7"
        type="text"
        value={email}
        onChange={onChangeEmail}
      />
      {(index > 0) && (
        <div className="flex w-full justify-end">
          <X
            className="p-1 w-8 hover:text-error-400 cursor-pointer"
            onClick={removeUser}
          />
        </div>
      )}
    </div>
  );
}

export default function OrganizationNew() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(null);
  const [organization, setOrganization] = useState({
    name: '',
    channels: [],
    markets: [],
    excludedHomeBuilders: [],
    users: [{ email: '' }],
    trialEndDate: null,
  });
  const [createOrganizationMutation, { isLoading: isCreating }] = useCreateOrganizationMutation();
  const { name, channels, markets, excludedHomeBuilders, users, trialEndDate } = organization;

  const { currentData: homeBuilders } = useFetchHomeBuildersQuery();
  const sortedHomeBuilders = useMemo(() => {
    const comparator = naturalSortComparator();
    return !homeBuilders?.length ? [] : homeBuilders.toSorted(({ name: left }, { name: right }) => comparator(left, right));
  }, [homeBuilders]);

  const onChange = (event) => {
    setOrganization({
      ...organization,
      [event.target.name]: parseEventValue(event),
    });
  };
  const updateUser = (index, user) => {
    const updatedUsers = [...users];
    updatedUsers.splice(index, 1, user);
    setOrganization({ ...organization, users: updatedUsers });
  };
  const removeUser = index => {
    const updatedUsers = [...users];
    updatedUsers.splice(index, 1);
    setOrganization({ ...organization, users: updatedUsers });
  };

  const toggleOption = (fieldName, value) => {
    setOrganization({
      ...organization,
      [fieldName]: xor(organization[fieldName], [value]),
    });
  };

  const setValidationWarning = (message) => setAlert({ type: 'warning', text: `Validation Error: ${message}` });

  const handleCreateOrganization = async () => {
    setAlert(null);
    if (!name) {
      setValidationWarning('Organization name cannot be blank');
      return;
    }
    if (isEmpty(channels)) {
      setValidationWarning('At least one channel must be selected');
      return;
    }
    if (isEmpty(markets)) {
      setValidationWarning('At least one market must be selected');
      return;
    }
    if (some(users, user => !validateEmail(user.email))) {
      setValidationWarning('User email must be a valid email address');
      return;
    }
    const response = await createOrganizationMutation(organization);
    if (response.data) { navigate('/organizations'); }
  };

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  return (
    <div className="py-12 flex justify-center">
      <div className="w-128 bg-white rounded p-4">
        <div className="text-lg">New Organization Info</div>
        <FormInput
          className="mt-12"
          label="Organization Name"
          name="name"
          onChange={onChange}
          value={name}
        />
        <label htmlFor="channels" className="font-medium text-sm">Channels</label>
        <CheckboxList
          className="my-3"
          allItems={CHANNEL_OPTIONS}
          selectedItems={channels}
          handleItemSelect={partial(toggleOption, 'channels')}
        />
        <label htmlFor="markets" className="font-medium text-sm">Markets</label>
        <CheckboxList
          className="my-3"
          allItems={MARKETS.map(market => ({ label: market, value: market }))}
          selectedItems={markets}
          handleItemSelect={partial(toggleOption, 'markets')}
        />
        <label className="font-medium text-sm">Excluded Builders</label>
        <CheckboxList
          className="my-3"
          allItems={sortedHomeBuilders?.map(({ name: label, id: value }) => ({ label, value })) ?? []}
          selectedItems={excludedHomeBuilders}
          handleItemSelect={partial(toggleOption, 'excludedHomeBuilders')}
        />
        <label htmlFor="users" className="font-medium text-sm">User Emails</label>
        <div className="my-3 flex flex-col gap-y-2">
          {users.map((user, index) => (
            <UserRow
              key={index}
              index={index}
              updateUser={partial(updateUser, index)}
              removeUser={partial(removeUser, index)}
              user={user}
            />
          ))}
        </div>
        <Button
          small
          textOnly
          label="Add User"
          onClick={() => updateUser(users.length, { email: '' })}
        />
        <FormInput
          className="mt-3"
          label="Trial End Date"
          name="trialEndDate"
          type="date"
          min={tomorrowDate.toISOString().split('T')[0]}
          onChange={onChange}
          value={trialEndDate}
        />
        {alert && <Alert {...alert} />}
        <div className="w-full mt-12 flex justify-center">
          <Button
            filled
            label="Create Organization"
            onClick={handleCreateOrganization}
            isLoading={isCreating}
          />
        </div>
      </div>
    </div>
  );
}
