import { useNavigate } from 'react-router-dom';
import { useFetchOffMarketMarketplaceDashboardStatisticsQuery } from 'redux/offMarketMarketplaceApiSlice';
import { DashboardCard } from 'components/NhimbleDashboard/Shared/ReturnMetricBoxes';
import EmptyLoadingState from 'components/shared/EmptyLoadingState';

function SectionHeader({ label }) {
  return <h2 className="text-xl text-gray-700">{label}</h2>;
}

function Section({ label, children }) {
  return (
    <>
      <SectionHeader label={label} />
      <div className="py-8">
        {children}
      </div>
    </>
  );
}

export default function OffMarketDashboard() {
  const navigate = useNavigate();
  const { data, isLoading } = useFetchOffMarketMarketplaceDashboardStatisticsQuery();

  if (isLoading) {
    return (
      <div className="w-full h-screen">
        <EmptyLoadingState />
      </div>
    );
  }

  const {
    all: {
      activeListings,
      activeOffers,
      closedListings,
      expiringWithin24,
      inPreInspection,
      inContract,
      offersNeedResponse,
    },
    recent: { newListings, expiredListings, offers },
  } = data;

  const navigateToInventory = () => navigate('/off_market_marketplace/inventory');
  const navigateToOffers = () => navigate('/off_market_marketplace/offers');
  // const navigateToImport = () => navigate('/off_market_marketplace/import');

  return (
    <div className="py-12 px-8 flex flex-col h-container w-container">
      <h1 className="mb-12 text-3xl text-gray-700">Marketplace Dashboard</h1>
      <Section label="Recent Activity - Past Week">
        <div className="flex gap-x-6">
          <DashboardCard label="New Listings" value={newListings} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="Expired Listings" value={expiredListings} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="Offers" value={offers} width="w-60" onClick={navigateToOffers} />
        </div>
      </Section>
      <Section label="Inventory">
        <div className="flex gap-x-6">
          <DashboardCard label="Active Listings" value={activeListings} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="Expiring in Next Day" value={expiringWithin24} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="Closed Listings" value={closedListings} width="w-60" />
        </div>
      </Section>
      <Section label="Offers">
        <div className="flex gap-x-6">
          <DashboardCard label="Active Offers" value={activeOffers} width="w-60" onClick={navigateToOffers} />
          <DashboardCard label="Offers Need Response" value={offersNeedResponse} width="w-60" onClick={navigateToOffers} />
          <DashboardCard label="In Pre-Inspection" value={inPreInspection} width="w-60" onClick={navigateToInventory} />
          <DashboardCard label="In Contract" value={inContract} width="w-60" onClick={navigateToOffers} />
        </div>
      </Section>
    </div>
  );
}
