import { memo, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import DataTable from 'components/shared/Table/DataTable';
import { DataTableContent } from 'components/shared/Table/DataTableContext';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import BooleanFilterChip from 'components/shared/newfilters/BooleanFilterChip';
import FilterToggle from 'components/shared/newfilters/FilterToggle';
import ChipGroup from 'components/shared/ChipGroup';
import { dataTableMeta } from 'components/shared/Table/table.helpers';
import { Check, Pencil, Search } from 'components/icons';
import { formatDate, parseEventValue } from 'components/utils';
import { CHANNEL_LABELS } from 'components/constants';
import useElementHeight from 'hooks/useElementHeight';
import InactiveFilterChip from './InactiveFilterChip';

const TABLE_INITIAL_STATE = {
  sorting: [{ id: 'lastActivityAt', desc: true }],
  columnFilters: [{ id: 'inactive', value: false }],
  columnVisibility: { inactive: false },
};

const arrayValue = (array) => {
  if (array.length > 3) {
    return `${array.slice(0, 2).join(', ')} + ${array.length - 2} more`;
  }
  return array.join(', ');
};

const inactiveFilterFn = (row, _, inactive) => {
  if (!inactive) {
    return !row.getValue('inactive');
  }
  return true;
};

const useColumns = (navigate) => useMemo(
  () => [{
    header: 'Name',
    accessorKey: 'name',
  }, {
    header: 'Channels',
    accessorKey: 'channels',
    cell: ({ getValue }) => getValue().map((channelId) => CHANNEL_LABELS[channelId] ?? channelId).join(', '),
  }, {
    header: 'Markets',
    accessorKey: 'markets',
    cell: ({ getValue }) => arrayValue(getValue()),
  }, {
    id: 'offMarketSellerOnly',
    header: 'Off-Market Seller',
    accessorFn: ({ config }) => config.importOffMarketMarketplace,
    cell: ({ getValue }) => (getValue() ? <div className="flex justify-center"><Check /></div> : null),
  }, {
    id: 'newBuildOnly',
    header: 'New Build Buyer',
    accessorFn: ({ config }) => config.newBuildOnly,
    cell: ({ getValue }) => (getValue() ? <div className="flex justify-center"><Check /></div> : null),
  }, {
    header: 'Active Deals',
    accessorKey: 'activeDeals',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Closed Deals',
    accessorKey: 'closedDeals',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Dead Deals',
    accessorKey: 'deadDeals',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Active Users',
    accessorKey: 'activeUsersCount',
    meta: { ...dataTableMeta.textRight },
  }, {
    header: 'Trial Users',
    accessorKey: 'trialUsersCount',
    meta: { ...dataTableMeta.textRight },
  }, {
    id: 'lastActivityAt',
    header: 'Last Activity',
    accessorFn: ({ lastActivityAt }) => (lastActivityAt ? parseISO(lastActivityAt) : null),
    cell: ({ getValue }) => formatDate(getValue(), 'yyyy-MM-dd HH:mm:ss zzz'),
    sortingFn: 'datetime',
    sortDescFirst: true,
  }, {
    id: 'inactive',
    accessorFn: ({ activeUsersCount }) => !activeUsersCount,
    filterFn: inactiveFilterFn,
  }, {
    id: 'menu',
    header: '',
    enableSorting: false,
    accessorFn: (row) => row,
    cell: memo(({ getValue }) => (
      <div className="relative">
        <div className="flex justify-center gap-x-1 items-center">
          <div className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer" onClick={(event) => { event.stopPropagation(); event.preventDefault(); navigate(`/organizations/${getValue().id}`); }}>
            <Pencil className="w-6 h-6 mx-auto" />
          </div>
        </div>
      </div>
    )),
  }],
  [navigate],
);

export default function OrganizationIndex({ organizations, isLoading }) {
  const [searchTerm, setSearchTerm] = useState(null);
  const navigate = useNavigate();
  const bodyRef = useRef();
  const bodyHeight = useElementHeight(bodyRef);
  const filterRef = useRef();
  const filterHeight = useElementHeight(filterRef);

  const filteredOrganizations = searchTerm
    ? organizations.filter(o => {
      const searchTermLower = searchTerm.toLowerCase();
      const nameIncludesSearchTerm = o.name.toLowerCase().includes(searchTermLower);
      if (nameIncludesSearchTerm) return true;
      const userEmailIncludesSearchTerm = o.users.map(u => u.email).find(email => email.toLowerCase().includes(searchTermLower));
      return userEmailIncludesSearchTerm;
    })
    : organizations;

  return (
    <div className="h-full" ref={bodyRef}>
      <DataTable
        virtual
        tableContainerClassName="whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
        tableHeight={bodyHeight - filterHeight}
        data={filteredOrganizations}
        columns={useColumns(navigate)}
        isLoading={isLoading}
        initialState={TABLE_INITIAL_STATE}
        emptyStateComponent={searchTerm?.length > 0 ? `No organizations were found for the search term '${searchTerm}'` : 'No organizations were found for the active filters'}
      >
        <div className="flex justify-between items-center py-1.5 px-3 border-b" ref={filterRef}>
          <div>
            <ReactTableFilter>
              <ChipGroup>
                <ReactTableColumnFilter
                  label="Off-Market Seller Only"
                  staticDisplayValue="Off-Market Seller Only"
                  columnId="offMarketSellerOnly"
                >
                  <BooleanFilterChip>
                    <FilterToggle />
                  </BooleanFilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="New Build Only"
                  staticDisplayValue="New Build Only"
                  columnId="newBuildOnly"
                >
                  <BooleanFilterChip>
                    <FilterToggle />
                  </BooleanFilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter columnId="inactive">
                  <InactiveFilterChip />
                </ReactTableColumnFilter>
              </ChipGroup>
            </ReactTableFilter>
          </div>
          <div className="flex items-center w-96">
            <span className="z-10 font-normal absolute text-center text-slate-300 rounded text-base items-center justify-center w-8 pl-3 py-2.5">
              <Search className="text-black w-6 h-6" />
            </span>
            <input
              value={searchTerm || ''}
              onChange={(e) => setSearchTerm(parseEventValue(e))}
              type="text"
              placeholder="Search for an organization by name or user email"
              className="px-2 py-2.5 rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
            />
          </div>
        </div>
        <DataTableContent />
      </DataTable>
    </div>
  );
}
