import { memo, useState, useCallback } from 'react';
import FileUploadArea from 'components/FileUploadArea';
import { Download, Plus, Trash, X } from 'components/icons';
import Modal from 'components/NewModal';
import Button from 'components/shared/NewButton';
import DataTable from 'components/shared/Table/DataTable';
import { fileSize } from 'components/utils';
import { useCreateOfferAttachmentMutation, useDeleteOfferAttachmentMutation } from 'redux/offerApiSlice';
import { showToast } from 'redux/toastSlice';
import { useDispatch } from 'react-redux';

function IconButton({ icon: Icon, onClick }) {
  return (
    <div
      className="flex justify-center items-center p-2 w-9 h-9 rounded-full hover:bg-gray-300 cursor-pointer"
      onClick={onClick}
    >
      <Icon className="w-6 h-6 mx-auto" />
    </div>
  );
}

const FileTypeCell = memo(({ filename }) => {
  const extension = filename.split('.').pop();
  return <div>{extension ? extension.toUpperCase() : 'Unknown'}</div>;
});

const createColumns = (onDownload, onRemove) => [
  {
    id: 'filename',
    header: 'File Name',
    accessorKey: 'filename',
  },
  {
    id: 'fileType',
    header: 'File Type',
    accessorKey: 'filename',
    cell: memo(({ getValue }) => <FileTypeCell filename={getValue()} />),
  },
  {
    id: 'menu',
    header: '',
    enableSorting: false,
    accessorFn: row => row,
    cell: memo(({ getValue }) => {
      const attachment = getValue();
      return (
        <div className="relative">
          <div className="flex justify-center gap-x-1 items-center">
            <IconButton icon={Download} onClick={() => onDownload(attachment)} />
            <IconButton icon={Trash} onClick={() => onRemove(attachment)} />
          </div>
        </div>
      );
    }),
    enableHiding: false,
  },
];

function UploadAttachmentModal({ offer, onClose }) {
  const dispatch = useDispatch()
  const [createOfferAttachment, { isLoading }] = useCreateOfferAttachmentMutation();
  const [files, setFiles] = useState([]);

  const onFileUpload = useCallback((newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const onFileRemove = useCallback((index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }, []);

  const handleUpload = async (event) => {
    event.preventDefault();
    if (files.length === 0) return;
    await createOfferAttachment({ offerId: offer.id, files });
    dispatch(showToast({ message: 'Attachment uploaded successfully.', hasTimeouts: true }));
    onClose();
  };

  return (
    <Modal title="Upload Document" show size="large" onClose={onClose}>
      <div className="p-3 space-y-3">
        {files.length === 0 && <FileUploadArea onFileUpload={onFileUpload} />}
        {files.map((file, index) => (
          <FileRow key={file.name} file={file} onRemove={() => onFileRemove(index)} />
        ))}
        <Button filled label="Upload" isLoading={isLoading} onClick={handleUpload} />
      </div>
    </Modal>
  );
}

function FileRow({ file, onRemove }) {
  return (
    <div className="w-full flex justify-between items-center gap-x-2 text-sm">
      <div className="flex-grow truncate">{file.name}</div>
      <div className="flex justify-end items-center gap-x-2">
        <div className="text-gray-500">{fileSize(file.size)}</div>
        <button type="button" onClick={onRemove} className="focus:outline-none">
          <X className="w-5 text-red-500 hover:text-red-400" />
        </button>
      </div>
    </div>
  );
}

function EmptyFiles({ onUploadClick }) {
  return (
    <div className="flex flex-col justify-center items-center p-6">
      <p className="text-gray-500 mb-4">No attachments available. Upload documents to get started.</p>
      <Button filled label="Upload Documents" onClick={onUploadClick} leadingIcon={<Plus className="w-4" />} />
    </div>
  );
}

function OfferAttachmentModal({ offerData, onClose }) {
  const dispatch = useDispatch()
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [deleteOfferAttachment] = useDeleteOfferAttachmentMutation();

  const handleDownload = useCallback((attachment) => {
    window.location.href = attachment.url; // Trigger file download
  }, []);

  const handleRemove = useCallback(async (attachment) => {
    await deleteOfferAttachment(attachment);
    dispatch(showToast({ message: 'Attachment removed successfully.', hasTimeouts: true }));
  }, [deleteOfferAttachment, dispatch]);

  return (
    <Modal title="Offer Attachments" size="large" onClose={onClose} show>
      <div className="mb-8">
        <DataTable
          emptyStateComponent={<EmptyFiles onUploadClick={() => setShowUploadModal(true)} />}
          columns={createColumns(handleDownload, handleRemove)}
          data={offerData.attachments}
          enableMultiRowSelection={false}
          tableContainerClassName="flex-1 whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
          tdClassName="p-1.5"
        />
        {offerData.attachments.length > 0 && !showUploadModal && (
          <Button
            filled
            label="Upload Documents"
            className="mx-auto items-center justify-center mt-4"
            onClick={() => setShowUploadModal(true)}
            leadingIcon={<Plus className="w-4" />}
          />
        )}
      </div>
      {showUploadModal && (
        <UploadAttachmentModal offer={offerData} onClose={() => setShowUploadModal(false)} />
      )}
    </Modal>
  );
}

export default memo(OfferAttachmentModal);
