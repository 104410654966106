import { createAction, nanoid } from '@reduxjs/toolkit';
import { deepStringifyDates } from '../components/utils';

export const showEditTransactionInfoModal = createAction(
  'deals/showEditTransactionInfoModal',
  (deal, transactionFieldName, header) => ({ payload: { showEditTransactionInfoModal: true, deal, header, transactionFieldName } }),
);
export const clearDealsModal = createAction('deals/resetDealsModal');
export const updateTaskModal = createAction(
  'deals/updateTaskModal',
  (task, isNew) => deepStringifyDates({ payload: { updateTask: true, task, isNew } }),
);
export const destroyTaskModal = createAction(
  'deals/destroyTaskModal',
  task => deepStringifyDates({ payload: { destroyTask: true, task } }),
);
export const submitTaskResponseModal = createAction(
  'deals/submitTaskResponseModal',
  (task, transactionInfo) => deepStringifyDates({ payload: { submitTaskResponse: true, transactionInfo, task } }),
);
export const createTaskAttachmentModal = createAction(
  'deals/createTaskAttachmentModal',
  task => deepStringifyDates({ payload: { createTaskAttachment: true, task } }),
);
export const destroyTaskAttachmentModal = createAction(
  'deals/destroyTaskAttachmentModal',
  (task, attachment) => deepStringifyDates({ payload: { destroyTaskAttachment: true, task, attachment } }),
);

export const sendTaskEmailModal = createAction(
  'deals/sendTaskEmailModal',
  (task) => deepStringifyDates({ payload: { sendTaskEmail: true, task } }),
);

export const createTaskModal = milestone => {
  const task = {
    dealId: milestone.dealId,
    name: null,
    completedAt: null,
    dueDate: null,
    id: nanoid(),
  };

  return updateTaskModal(task, true);
};

export const dismissDashboardAlert = createAction('deals/dismissDashboardAlert');
