import { useCallback, useState } from 'react';
import {
  useCreateListingAttachmentsMutation,
  useDeleteListingAttachmentMutation,
  useFetchListingAttachmentsQuery,
  useUpdateListingAttachmentMutation,
} from 'redux/listingApiSlice';
import { useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import { DetailPaneHeading, DetailPaneNoData } from 'components/Import/NewBuild/NewBuildInventoryDetail';
import { useCurrentSideNavPanel } from 'components/inventory/InventorySideNav';
import { Download, LoadingIndicator, Plus, Trash } from 'components/icons';
import Button from 'components/shared/NewButton';
import Select from 'components/Select';
import FileUploadModal from 'components/shared/FileUploadModal';
import { formatDate, parseEventValue } from 'components/utils';

const ATTACHMENT_TYPES = {
  a_to_b_agreement: 'A to B Agreement',
  disclosure: 'Disclosure',
  inspection_report: 'Inspection Report',
  other: 'Other',
};

function AddAttachmentButton({ listingId, isListReady }) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowUploadModal((prev) => !prev);
  }, []);

  const [triggerCreate, { isUninitialized, isLoading, isSuccess, reset }] = useCreateListingAttachmentsMutation();
  const onUpload = useCallback((files) => {
    triggerCreate({ id: listingId, files });
  }, [triggerCreate, listingId]);

  // include isListReady to wait for parent to finish re-fetching after a successful upload before dismissing modal
  const isUploading = !isUninitialized && (isLoading || !isListReady);
  if (isSuccess && !isUploading) {
    setShowUploadModal(false);
  }

  // reset hook after dismissing modal
  if (!showUploadModal && !isUninitialized) {
    reset();
  }

  return (
    <>
      <Button
        filled
        label="Upload Attachments"
        leadingIcon={<Plus className="size-5" />}
        onClick={toggleModal}
      />
      {showUploadModal && (
        <FileUploadModal
          toggleModal={toggleModal}
          isLoading={isUploading}
          onUpload={onUpload}
          title="Upload Attachments"
          accept="*"
        />
      )}
    </>
  );
}

export default function OffMarketAttachmentsPane() {
  const show = useCurrentSideNavPanel() === 'attachments';
  const [row] = useFilteredSelectedRows() ?? [];

  const { currentData, isFetching, isLoading } = useFetchListingAttachmentsQuery(row?.id, { skip: !show || !row });
  const [triggerDelete, { isLoading: isDeleting }] = useDeleteListingAttachmentMutation();
  const [triggerUpdate] = useUpdateListingAttachmentMutation();

  if (!show) {
    return null;
  }

  const onDestroy = async (attachment) => {
    if (isDeleting) return;
    await triggerDelete(attachment).unwrap().catch(error => {
      // TODO: better handle error
      console.error(error);
    });
  };

  const onAttachmentTypeChange = async (e, attachment) => {
    const value = parseEventValue(e);
    await triggerUpdate({
      id: attachment.id,
      listingId: attachment.listingId,
      attachmentType: value,
    }).unwrap().catch(error => {
      // TODO: better handle error
      console.error(error);
    });
  };

  return (
    <>
      <DetailPaneHeading>Attachments</DetailPaneHeading>
      {row ? (
        <div className="size-full overflow-auto">
          {isLoading ? (
            <LoadingIndicator className="size-8 mx-auto" />
          ) : (
            <div className="py-6">
              <div className="mb-12 flex justify-center">
                <AddAttachmentButton
                  listingId={row.id}
                  isListReady={!isFetching}
                />
              </div>
              <div className="divide-y divide-gray-200">
                {currentData?.map(({ id, attachmentName, attachmentType, attachmentUrl, createdAt, listingId }) => (
                  <div key={id} className="max-w-full p-3 flex justify-between gap-x-2">
                    <div className="min-w-0 shrink">
                      <div className="mb-1 text-sm text-medium truncate">{attachmentName}</div>
                      <div className="flex gap-x-3 items-center">
                        <Select
                          padding="py-1 px-2"
                          width="w-36"
                          className="text-xs"
                          value={attachmentType || ''}
                          options={[['', 'Uncategorized', true], ...Object.entries(ATTACHMENT_TYPES)]}
                          onChange={(e => onAttachmentTypeChange(e, { id, listingId }))}
                        />
                        <div className="text-gray-400 text-xs">{`Uploaded ${formatDate(createdAt)}`}</div>
                      </div>
                    </div>
                    <div className="flex-none flex justify-center items-center gap-x-3">
                      {isDeleting ? (
                        <LoadingIndicator className="size-7" />
                      ) : (
                        <>
                          <a className="flex justify-center items-center" download href={attachmentUrl}>
                            <Download className="size-5 text-primary-dark hover:opacity-80 cursor-pointer" />
                          </a>
                          <Trash
                            className="size-5 text-error-500 hover:text-error-400 cursor-pointer"
                            onClick={() => onDestroy({ id, listingId })}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <DetailPaneNoData>
          Select a row to see attachments
        </DetailPaneNoData>
      )}
    </>
  );
}
