import { mean, floor } from 'lodash';
import { formatCurrency, formatInteger } from '../utils';

function ShortSummaryItem({ label, value }) {
  return (
    <div className="flex flex-col items-center gap-y-0.5 my-2 px-4">
      <div className="text-xl">{value ?? '-'}</div>
      <div className="text-neutral-light font-medium text-xs tracking-wide">{label}</div>
    </div>
  );
}

export default function ShortSummary({ activeSaleCompSet, comps }) {
  const closedComps = comps.filter(comp => comp.closePrice);
  const averageClosePrice = closedComps.map(comp => comp.closePrice);

  return (
    <div className="flex flex-row justify-end flex-grow divide-x">
      <ShortSummaryItem label="Selected Comps" value={formatInteger(comps.length)} />
      <ShortSummaryItem label="Avg Close Price" value={averageClosePrice.length > 0 ? formatCurrency(floor(mean(averageClosePrice))) : '-'} />
      <ShortSummaryItem label="Underwritten Value" value={formatCurrency(activeSaleCompSet?.compPrice || '-')} />
    </div>
  );
}
