import { useState } from 'react';
import PhotoModal from '../property/components/PhotoModal';

export default function CompPhotoModal({ dismiss, comp }) {
  const [primaryPhotoIndex, setPrimaryPhotoIndex] = useState(0);
  const { media, streetAddress } = comp;

  return (
    <PhotoModal
      photos={media}
      primaryPhotoIndex={primaryPhotoIndex}
      setPrimaryPhotoIndex={setPrimaryPhotoIndex}
      showModal
      setShowModal={dismiss}
      title={streetAddress}
    />
  );
}
