import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, sortBy, uniqBy } from 'lodash';
import { useFetchRentCompSetsQuery } from 'redux/apiSlice';
import { setActiveRentCompSetId } from 'redux/rentCompsSlice';
import { CURRENT_COMP_SET } from 'components/rentComps/rentComps';
import CompSetTopActions from 'components/rentComps/CompSetTopActions';
import { parseUnitMixes, unitMixKey } from 'components/utils';

export default function CompSetSelector(props) {
  const {
    canTakeDealActions,
    deal,
    property,
    propertyManagementRecord,
    units,
    minimizeMap,
    setMinimizeMap,
  } = props;
  const dispatch = useDispatch();
  const { activeRentCompSetId } = useSelector(state => state.rentComps);

  const dealId = deal?.id;
  const propertyId = property.id;
  const propertyManagementRecordId = propertyManagementRecord?.id;
  const unitMixPairs = parseUnitMixes(units);
  const unitMixes = sortBy(uniqBy(unitMixPairs, unitMix => unitMixKey(unitMix)), unitMix => unitMixKey(unitMix));

  const [activeUnitMix, setActiveUnitMix] = useState(property.isSingleFamily ? null : unitMixes[0]);
  const skipRentCompSets = !dealId && !propertyManagementRecordId;
  const { data: rentCompSets } = useFetchRentCompSetsQuery({ propertyId, dealId, propertyManagementRecordId }, { skip: skipRentCompSets });

  const activeRentCompSet = rentCompSets?.find(rcs => rcs.id === activeRentCompSetId);

  // set the primary rent comp set as active after the initial load of rentCompSets
  useEffect(() => {
    if (isNil(activeRentCompSetId) && (!isNil(rentCompSets) || skipRentCompSets)) {
      const primaryRentCompSet = rentCompSets?.find(rentCompSet => rentCompSet.primary);
      const newActiveRentCompSetId = primaryRentCompSet?.id || CURRENT_COMP_SET.id;
      dispatch(setActiveRentCompSetId(newActiveRentCompSetId));
    }
  }, [activeRentCompSetId, dispatch, rentCompSets, skipRentCompSets]);

  if (isNil(activeRentCompSetId)) return <div className="h-18 w-full" />;

  return (
    <CompSetTopActions
      activeRentCompSet={activeRentCompSet}
      activeUnitMix={activeUnitMix}
      canTakeDealActions={canTakeDealActions}
      deal={deal}
      property={property}
      propertyManagementRecord={propertyManagementRecord}
      rentCompSets={rentCompSets}
      setActiveUnitMix={setActiveUnitMix}
      unitMixes={unitMixes}
      minimizeMap={minimizeMap}
      setMinimizeMap={setMinimizeMap}
    />
  )
}
