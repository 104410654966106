import { Paperclip } from 'components/icons';
import { NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';
import { InventoryNavItem } from './InventorySideNav';

export default function InventoryAttachmentsNavItem() {
  return (
    <InventoryNavItem name="attachments">
      <NavItemIcon>
        <Paperclip filled={false} className="block group-aria-selected/nav:hidden" />
        <Paperclip className="hidden group-aria-selected/nav:block" />
      </NavItemIcon>
      <NavItemLabel>Attachments</NavItemLabel>
    </InventoryNavItem>
  );
}
