/* eslint-disable jsx-a11y/alt-text */
import { TrendingFlat } from 'components/icons';
import { formatHourAgo } from 'components/utils';
import { isNil } from 'lodash';
import { format, endOfDay, intervalToDuration } from 'date-fns';
import MentionedInDealNotification from './MentionedInDealNotification';
import MentionedInListingNoteNotification from './MentionedInListingNoteNotification';
import PriceUpdateNotification from './PriceUpdateNotification';
import StatusUpdateNotification from './StatusUpdateNotification';
import ListingClosedNotification from './ListingClosedNotification';
import { CLOSING_DATE_APPROACHING_NOTIFICATION, CLOSING_DATE_PASSED_NOTIFICATION, DUE_DATE_APPROACHING_NOTIFICATION, DUE_DATE_PASSED_NOTIFICATION, LISTING_CLOSED_NOTIFICATION, MENTIONED_IN_DEAL_NOTIFICATION, MENTIONED_IN_LISTING_NOTE_NOTIFICATION, OFFER_RECEIVED_NOTIFICATION, PRICE_UPDATE_NOTIFICATION, STATUS_UPDATE_NOTIFICATION, TASK_ASSIGNED_NOTIFICATION, TASK_COMPLETED_NOTIFICATION, TASK_UNASSIGNED_NOTIFICATION, WORKFLOW_UPDATE_NOTIFICATION } from './notification.constant';

export function TaskOwnershipUpdatedNotification({ notification }) {
  const { dealId, dealStage, notificationType, taskOwnershipUpdatedAt, taskWhodunnitName, portfolioName, seenAt, taskName, property: { market, photoUrl, address } } = notification.attributes;
  return (
    <a href={`/deals/${dealId}`} className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">
            {notificationType === TASK_ASSIGNED_NOTIFICATION ? 'New Task' : 'Task Unassigned'}
          </div>
          <div className="mt-1 flex h-4 items-center">
            <span className="text-neutral-medium text-base">
              {taskName}
            </span>
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-1 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-xs ">{`${market} - ${portfolioName}`}</div>
          </div>
          <div className="mt-3 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-vxs ">{`${notificationType === TASK_ASSIGNED_NOTIFICATION ? 'Assigned' : 'Unassigned'} by ${taskWhodunnitName} • ${formatHourAgo(taskOwnershipUpdatedAt)}`}</div>
          </div>
        </div>
      </div>
    </a>
  );
}

export function TaskCompletedNotification({ notification }) {
  const { dealId, dealStage, taskOwnershipUpdatedAt, taskWhodunnitName, portfolioName, taskName, seenAt, property: { market, photoUrl, address } } = notification.attributes;
  return (
    <a href={`/deals/${dealId}`} className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">
            Task Completed
          </div>
          <div className="mt-1 flex h-4 items-center">
            <span className="text-neutral-medium text-base">
              {taskName}
            </span>
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-1 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-xs ">{`${market} - ${portfolioName}`}</div>
          </div>
          <div className="mt-3 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-vxs ">
              Completed by
              {' '}
              {taskWhodunnitName}
              {' • '}
              {formatHourAgo(taskOwnershipUpdatedAt)}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export function WorkflowUpdateNotification({ notification }) {
  const { currentDealStage, dealId, dealStage, taskOwnershipUpdatedAt, portfolioName, previousDealStage, seenAt, property: { market, photoUrl, address } } = notification.attributes;

  return (
    <a href={`/deals/${dealId}`} className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">
            Workflow Update
          </div>
          <div className="flex gap-x-1 h-8 items-center">
            <span className="text-neutral-light text-base">
              {previousDealStage}
            </span>
            <TrendingFlat />
            <span className="text-black text-base">
              {currentDealStage}
            </span>
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-1 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-xs ">{`${market} - ${portfolioName}`}</div>
          </div>
          <div className="mt-3 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-vxs ">
              {formatHourAgo(taskOwnershipUpdatedAt)}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export function DueDateNotification({ notification }) {
  const { dealId, dealStage, notificationType, taskDueDate, portfolioName, taskName, seenAt, property: { market, photoUrl, address } } = notification.attributes;
  return (
    <a href={`/deals/${dealId}`} className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">
            Task Due
          </div>
          <div className="mt-1 flex h-4 items-center">
            <span className="text-neutral-medium text-base">
              {taskName}
            </span>
            {notificationType === DUE_DATE_APPROACHING_NOTIFICATION && (
              <div className="ml-2.5 h-8 w-auto px-3 py-1.5 rounded-lg border border-gray-100 items-center text-center text-neutral-medium text-sm font-medium">
                Due
                {' '}
                {format(new Date(taskDueDate), 'MMM dd')}
              </div>
            )}
            {notificationType === DUE_DATE_PASSED_NOTIFICATION && (
            <div className="ml-2.5 h-8 w-auto px-3 py-1.5 rounded-lg items-center text-center text-neutral-medium text-sm font-medium text-error bg-error-100">
              Overdue
            </div>
            )}
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-1 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-xs ">{`${market} - ${portfolioName}`}</div>
          </div>
          <div className="mt-3 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-vxs ">
              Due
              {' '}
              {format(new Date(taskDueDate), 'MMM dd')}
              {' '}
              (
              {formatHourAgo(taskDueDate, true)}
              )
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export function ClosingNotification({ notification }) {
  const { dealId, closingDate, createdAt, dealStage, notificationType, portfolioName, seenAt, property: { market, photoUrl, address } } = notification.attributes;

  const closingIn = () => {
    try {
      const { days: daysRemaining } = intervalToDuration({ start: new Date(), end: endOfDay(new Date(closingDate)) });

      if (daysRemaining === 0) return 'Today';
      else if (daysRemaining === 1) return 'in 1 Day';
      else return 'in 2 Days';
    } catch (error) {
      // if start > end intervalToDuration throws date-fns RangeError exception which means the closing date approaching notifcation has passed the closing date
      const { days, hours } = intervalToDuration({ start: new Date(closingDate), end: new Date() });
      return `${days === 0 ? `before ${hours} hours` : `before ${days} day`}`;
    }
  };

  return (
    <a href={`/deals/${dealId}`} className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">
            {notificationType === CLOSING_DATE_PASSED_NOTIFICATION ? 'Closing Date Passed' : `Closing ${closingIn()}`}
          </div>
          <div className="mt-1 flex h-4 items-center">
            <span className="text-neutral-dark text-base">
              Closing Date:
              {' '}
              {closingDate}
            </span>
            {notificationType === CLOSING_DATE_PASSED_NOTIFICATION && (
            <div className="ml-2.5 h-8 w-auto px-3 py-1.5 rounded-lg items-center text-center text-neutral-medium text-sm font-medium text-error bg-error-100">
              Late
            </div>
            )}
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-1 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-xs ">{`${market} - ${portfolioName}`}</div>
          </div>
          <div className="mt-3 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-vxs ">
              {formatHourAgo(createdAt)}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default function OfferReceivedNotification({ notification }) {
  const { dealId, dealStage, offer, portfolioName, seenAt, property: { market, photoUrl, address } } = notification.attributes;

  return (
    <a href={`/deals/${dealId}`} className={`mb-3 p-4 cursor-pointer border border-gray-300 rounded-lg ${isNil(seenAt) ? 'bg-neutral-surface-light' : 'hover:bg-indigo-50/75'}`}>
      <div className="flex gap-x-4">
        <img className="h-14 w-14 rounded-lg object-cover" src={photoUrl} />
        <div className="flex flex-col">
          <div className="text-neutral-dark font-medium text-xl">
            New Offer Received
          </div>
          <div className="mt-1 flex h-4 items-center">
            <span className="text-neutral-medium text-base">
              Offer from
              {' '}
              {offer.buyerAgentName}
              {' '}
              for $
              {offer.price}
            </span>
          </div>
          <div className="mt-3 h-6 flex gap-x-2 items-center ">
            <div className="text-neutral-dark font-medium text-base ">{address}</div>
            <div className="px-3 py-px text-neutral-medium font-medium text-xs rounded-lg border border-gray-300">{dealStage}</div>
          </div>
          <div className="mt-1 h-4 flex gap-x-2 items-center">
            <div className="text-neutral-light font-medium text-xs ">{`${market} - ${portfolioName}`}</div>
          </div>
        </div>
      </div>
    </a>
  );
}

export function Notification({ notification }) {
  const { notificationType } = notification.attributes;
  if (notificationType === LISTING_CLOSED_NOTIFICATION) return <ListingClosedNotification notification={notification} />;
  if (notificationType === PRICE_UPDATE_NOTIFICATION) return <PriceUpdateNotification notification={notification} />;
  if (notificationType === STATUS_UPDATE_NOTIFICATION) return <StatusUpdateNotification notification={notification} />;
  if (notificationType === TASK_COMPLETED_NOTIFICATION) return <TaskCompletedNotification notification={notification} />;
  if (notificationType === WORKFLOW_UPDATE_NOTIFICATION) return <WorkflowUpdateNotification notification={notification} />;
  if (notificationType === MENTIONED_IN_DEAL_NOTIFICATION) return <MentionedInDealNotification notification={notification} />;
  if (notificationType === OFFER_RECEIVED_NOTIFICATION) return <OfferReceivedNotification notification={notification} />;
  if ([DUE_DATE_APPROACHING_NOTIFICATION, DUE_DATE_PASSED_NOTIFICATION].includes(notificationType)) return <DueDateNotification notification={notification} />;
  if ([CLOSING_DATE_APPROACHING_NOTIFICATION, CLOSING_DATE_PASSED_NOTIFICATION].includes(notificationType)) return <ClosingNotification notification={notification} />;
  if ([TASK_ASSIGNED_NOTIFICATION, TASK_UNASSIGNED_NOTIFICATION].includes(notificationType)) return <TaskOwnershipUpdatedNotification notification={notification} />;
  if (notificationType === MENTIONED_IN_LISTING_NOTE_NOTIFICATION) return <MentionedInListingNoteNotification notification={notification} />;
  throw new Error(`Undefined Notification Type: ${notificationType}`);
}
