import { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { difference, upperFirst } from 'lodash';
import { useFetchSelfQuery } from 'redux/apiSlice';
import { useFetchOffMarketMarketplaceOffersQuery, useUpdateOfferStatusMutation } from 'redux/offMarketMarketplaceApiSlice';
import {
  CurrencyCell,
  RouteLinkCell,
  ZonedDatetimeCell,
} from 'components/shared/Table/Cells';
import { dataTableMeta, tableConfigMeta } from 'components/shared/Table/table.helpers';
import Button from 'components/shared/NewButton';
import DataTable from 'components/shared/Table/DataTable';
import Modal from 'components/Modal';
import { FormField, InfoField } from 'components/Form';
import { formatCurrency, parseEventValue, titleCase } from 'components/utils';
import { Warning } from 'components/icons';
import {
  OFFER_STATUS_ACCEPTED,
  OFFER_STATUS_BUYER_COUNTERED,
  OFFER_STATUS_RE_OPENED,
  OFFER_STATUS_RECEIVED,
  OFFER_STATUS_REJECTED,
  OFFER_STATUS_SELLER_COUNTERED,
  OFFER_STATUS_TERMINATED,
} from 'components/constants';

/** @type {import('@tanstack/react-table').TableOptions['defaultColumn']} */
export const OFFER_DEFAULT_COLUMN = {
  sortUndefined: 'last',
};

const OFFER_ACTIONS = {
  [OFFER_STATUS_ACCEPTED]: [OFFER_STATUS_TERMINATED],
  [OFFER_STATUS_BUYER_COUNTERED]: [OFFER_STATUS_ACCEPTED, OFFER_STATUS_REJECTED, OFFER_STATUS_SELLER_COUNTERED],
  [OFFER_STATUS_RE_OPENED]: [OFFER_STATUS_ACCEPTED, OFFER_STATUS_REJECTED, OFFER_STATUS_SELLER_COUNTERED],
  [OFFER_STATUS_RECEIVED]: [OFFER_STATUS_ACCEPTED, OFFER_STATUS_REJECTED, OFFER_STATUS_SELLER_COUNTERED],
  [OFFER_STATUS_REJECTED]: [OFFER_STATUS_RE_OPENED],
  [OFFER_STATUS_SELLER_COUNTERED]: [OFFER_STATUS_ACCEPTED, OFFER_STATUS_REJECTED, OFFER_STATUS_BUYER_COUNTERED],
  seller_rejected: [OFFER_STATUS_REJECTED, OFFER_STATUS_SELLER_COUNTERED],
  seller_seller_counter: [OFFER_STATUS_ACCEPTED, OFFER_STATUS_REJECTED, OFFER_STATUS_BUYER_COUNTERED],
  [OFFER_STATUS_TERMINATED]: [OFFER_STATUS_RE_OPENED],
};

const OFFER_ACTION_LABEL = {
  [OFFER_STATUS_ACCEPTED]: 'Accept',
  [OFFER_STATUS_REJECTED]: 'Reject',
  [OFFER_STATUS_SELLER_COUNTERED]: 'Seller Counter',
  [OFFER_STATUS_BUYER_COUNTERED]: 'Buyer Counter',
  [OFFER_STATUS_TERMINATED]: 'Terminate',
  [OFFER_STATUS_RE_OPENED]: 'Reopen',
};

const INSPECTION_ACCESS_HELPER_TEXT = 'Should include information for the inspection contact name and phone number, the days available, lockbox information and any other relevant details';

function OfferActionCell(props) {
  const {
    row: { original },
    table: { options: { meta: { isSeller, setActionModalContext } } },
  } = props;

  const { id, offerStatus, sellerResponse } = original;
  const sellerStatus = sellerResponse && (isSeller ? sellerResponse : `seller_${sellerResponse}`);

  const actions = OFFER_ACTIONS[sellerStatus || offerStatus] || [];

  return (
    <div className="py-1 flex justify-between gap-x-2">
      {actions.map(action => (
        <Button
          key={action}
          filled
          small
          label={OFFER_ACTION_LABEL[action]}
          onClick={() => setActionModalContext({ action, id })}
        />
      ))}
    </div>
  );
}

function OfferStatusCell(props) {
  const {
    row: { original: { offerStatus, sellerResponse } },
    table: { options: { meta: { isSeller } } },
  } = props;
  if (isSeller) {
    return titleCase(sellerResponse || offerStatus);
  } else if (sellerResponse) {
    return titleCase(`seller${upperFirst(sellerResponse)}`);
  } else {
    return titleCase(offerStatus);
  }
}

function OfferDetails({ deal }) {
  const { earnestMoney, offerPrice } = deal;
  return (
    <>
      <InfoField
        label="Offer Price"
        value={formatCurrency(offerPrice)}
      />
      <InfoField
        className="mt-6"
        label="Offer Earnest Money"
        value={formatCurrency(earnestMoney)}
      />
    </>
  );
}

function ActionModal({ action, deal, onClose }) {
  const { address, id, preInspectionApproved } = deal;
  const [formState, setFormState] = useState({});
  const [updateOfferStatus, { isLoading }] = useUpdateOfferStatusMutation();

  const onChange = event => {
    const value = parseEventValue(event);
    setFormState(prevState => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const onSubmit = async () => {
    await updateOfferStatus({ offerStatus: action, id, ...formState }).unwrap().catch(e => {
      console.error(e);
    });
    onClose();
  };

  let formContent = null;
  let formDescription = null;
  if (action === OFFER_STATUS_ACCEPTED) {
    if (deal.aToBUploaded) {
      formDescription = 'Accepting this offer will notify the agent and buyer. Please fill out the following information to assist in the transaction process:';
      formContent = (
        <>
          <OfferDetails deal={deal} />
          {preInspectionApproved && (
            <FormField
              className="mt-6"
              label="Pre-Inspection"
              name="preInspection"
              value={formState.preInspection}
              type="checkbox"
              helperText="Buyer has approved a pre-inspection"
              onChange={onChange}
            />
          )}
          <FormField
            className="mt-6"
            label="Inspection Access Information"
            name="inspectionAccessInformation"
            value={formState.inspectionAccessInformation}
            type="text-area"
            rows={3}
            helperText={INSPECTION_ACCESS_HELPER_TEXT}
            onChange={onChange}
          />
        </>
      );
    } else {
      formContent = (
        <>
          <div className="mb-6 flex gap-x-4 items-center">
            <Warning className="fill-warning-500 size-6" />
            <h3 className="text-lg">{`No "A to B Agreement" uploaded for ${address}`}</h3>
          </div>
          <p>Before accepting an offer, an A to B Agreement must be uploaded.</p>
        </>
      );
    }
  } else if (action === OFFER_STATUS_REJECTED) {
    formDescription = 'Rejecting this offer will notify the agent and buyer';
    formContent = (
      <>
        <OfferDetails deal={deal} />
        <FormField
          className="mt-6"
          label="Rejection Reason"
          name="rejectionReason"
          value={formState.rejectionReason}
          type="select"
          options={[['price', 'Price'], ['terms', 'Terms'], ['other', 'Other']]}
          onChange={onChange}
        />
        {(formState.rejectionReason === 'other') && (
          <FormField
            className="mt-6"
            label="Rejection Reason Explanation"
            name="rejectionReasonOther"
            value={formState.rejectionReasonOther}
            type="text"
            onChange={onChange}
          />
        )}
      </>
    );
  } else if (action === OFFER_STATUS_SELLER_COUNTERED) {
    formDescription = 'This will notify the agent and buyer that the seller has countered';
    formContent = (
      <>
        <OfferDetails deal={deal} />
        <FormField
          className="mt-6"
          label="Counter Price"
          name="counterPrice"
          value={formState.counterPrice}
          type="currency"
          onChange={onChange}
        />
        <FormField
          className="mt-6"
          label="Counter Earnest Money"
          name="counterEarnestMoney"
          value={formState.counterEarnestMoney}
          type="currency"
          onChange={onChange}
        />
      </>
    );
  } else if (action === OFFER_STATUS_TERMINATED) {
    formDescription = 'This will notify the agent and buyer that the transaction is cancelled';
  } else if (action === OFFER_STATUS_RE_OPENED) {
    formDescription = 'This will notify the buyer and agent that the offer is re-opened and allow it to be accepted / rejected';
  }

  return (
    <Modal
      title={`${OFFER_ACTION_LABEL[action]} Offer on ${address}`}
      show
      width="w-180"
      onClose={onClose}
    >
      <div className="py-6">
        {formDescription && <p>{formDescription}</p>}
        {formContent && (
          <div className="mt-6 w-120 mx-auto">
            {formContent}
          </div>
        )}
      </div>
      <div className="mt-6 flex justify-center gap-x-12">
        <Button
          filled
          disabled={(action === OFFER_STATUS_ACCEPTED) && !deal.aToBUploaded}
          label={OFFER_ACTION_LABEL[action]}
          isLoading={isLoading}
          onClick={onSubmit}
        />
        <Button textOnly label="Cancel" onClick={onClose} />
      </div>
    </Modal>
  );
}

const columnHelper = createColumnHelper();

const listingOrgNameColumn = columnHelper.accessor('marketplaceOrganization.name', { header: 'Listed By' });
const offeringOrgNameColumn = columnHelper.accessor(row => row.clientName || row.organization.name, { header: 'Offered By' });
const dealLinkColumn = columnHelper.display({
  id: 'dealLink',
  cell: RouteLinkCell,
  meta: {
    linkPath: '/deals/:id',
    ...tableConfigMeta({ visibility: false, order: 'hidden' }),
  },
});

const offMarketBrokerColumns = [
  dealLinkColumn,
  listingOrgNameColumn,
  offeringOrgNameColumn,
  columnHelper.accessor('market', { header: 'Market', filterFn: 'equals' }),
  columnHelper.accessor('address', { header: 'Address' }),
  columnHelper.accessor('city', { header: 'City' }),
  columnHelper.accessor('state', { header: 'State' }),
  columnHelper.accessor('zip', {
    header: 'Zip',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('offerStatus', {
    header: 'Offer Status',
    cell: OfferStatusCell,
  }),
  columnHelper.accessor('offerActions', {
    header: 'Actions',
    cell: OfferActionCell,
  }),
  columnHelper.accessor('offerDate', { header: 'Offer Date', cell: ZonedDatetimeCell }),
  columnHelper.accessor('listingPrice', {
    header: 'List Price',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('offerPrice', {
    header: 'Offer Price',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('contractPrice', {
    header: 'Contract Price',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('earnestMoney', {
    header: 'Earnest Money',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('preInspectionApproved', {
    header: 'Pre-Inspection Approved',
  }),
  columnHelper.accessor('dueDiligenceDays', {
    header: 'Diligence Days',
  }),
  columnHelper.accessor('dueDiligenceEndDate', {
    header: 'Diligence End Date',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('buyerPreferredTitleCompany', {
    header: 'Buyer Preferred Title Company',
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Updated At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
  }),
];

const allColumnsSet = new Set(offMarketBrokerColumns);
const brokerOnlyColumnsSet = new Set([dealLinkColumn, listingOrgNameColumn, offeringOrgNameColumn]);

const offMarketSellerColumns = difference(Array.from(allColumnsSet), Array.from(brokerOnlyColumnsSet));

/** @type {import('@tanstack/react-table').InitialTableState} */
const tableInitialState = {};

/**
 * @param {string} tableContainerClassName
 * @param {import('react').ReactNode} children
 */
export default function OffMarketOfferTable({ children }) {
  const [actionModalContext, setActionModalContext] = useState(null);

  const { currentData, isUninitialized, isFetching } = useFetchOffMarketMarketplaceOffersQuery();
  const { currentData: currentUser, isFetching: isFetchingSelf } = useFetchSelfQuery();
  const isSeller = currentUser.organization.config.importOffMarketMarketplace;

  return (
    <DataTable
      virtual
      columns={isSeller === true ? offMarketSellerColumns : offMarketBrokerColumns}
      data={currentData}
      initialState={tableInitialState}
      defaultColumn={OFFER_DEFAULT_COLUMN}
      isLoading={(isUninitialized || isFetching || isFetchingSelf) && !currentData}
      tableContainerClassName="h-max max-h-container whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0"
      meta={{ isSeller, setActionModalContext }}
    >
      {children}
      {actionModalContext && (
        <ActionModal
          action={actionModalContext.action}
          deal={currentData.find(deal => deal.id === actionModalContext.id)}
          isSeller={isSeller}
          onClose={() => setActionModalContext(null)}
        />
      )}
    </DataTable>
  );
}
