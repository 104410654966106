import { useState } from 'react';
import { identity, isObject } from 'lodash';
import { Disclosure } from '@headlessui/react';
import { useAbodeMineTestMutation } from 'redux/addressApiSlice';
import Button from 'components/shared/NewButton';
import { Chevron, Search } from 'components/icons';
import { parseEventValue } from 'components/utils';

function Field({ label, value, formatter = identity }) {
  return (
    <div className="flex items-center gap-x-8">
      <div className="w-64 text-right text-sm text-gray-500 font-medium">{label}</div>
      {isObject(value) ? (
        <pre>{JSON.stringify(value, null, 2)}</pre>
      ) : <div>{formatter(value)}</div>}
    </div>
  );
}

function AssessorData({ data }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-64 mt-6 pl-12 flex items-center justify-between">
            <h2>Assessor</h2>
            <Chevron direction={open ? 'up' : 'down'} className="cursor-pointer size-8 hover:text-gray-500" />
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="flex flex-col gap-y-1">
              {Object.keys(data).sort().map(key => <Field key={key} label={key} value={data[key]} />)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function RecorderData({ data }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-64 mt-6 pl-12 flex items-center justify-between">
            <h2>Recorder</h2>
            <Chevron direction={open ? 'up' : 'down'} className="cursor-pointer size-8 hover:text-gray-500" />
          </Disclosure.Button>
          <Disclosure.Panel>
            {data.map(recorderRecord => (
              <div key={recorderRecord.transactionid} className="mt-6">
                <h3 className="w-64 text-right text-sm font-medium text-gray-500">{recorderRecord.transactionid}</h3>
                <div className="mt-6 flex flex-col gap-y-1">
                  {Object.keys(recorderRecord).sort().map(key => <Field key={key} label={key} value={recorderRecord[key]} />)}
                </div>
              </div>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function RentEstimateData({ data }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-64 mt-6 pl-12 flex items-center justify-between">
            <h2>Rent Estimate</h2>
            <Chevron direction={open ? 'up' : 'down'} className="cursor-pointer size-8 hover:text-gray-500" />
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="flex flex-col gap-y-1">
              {Object.keys(data).sort().map(key => <Field key={key} label={key} value={data[key]} />)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function SaleEstimateData({ data }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-64 mt-6 pl-12 flex items-center justify-between">
            <h2>Sale Estimate</h2>
            <Chevron direction={open ? 'up' : 'down'} className="cursor-pointer size-8 hover:text-gray-500" />
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="flex flex-col gap-y-1">
              {Object.keys(data).sort().map(key => <Field key={key} label={key} value={data[key]} />)}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function SearchResult({ data, error }) {
  if (error) {
    return (
      <>
        <div className="mb-6">Error returned from API:</div>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </>
    );
  }

  const {
    assessor,
    aupid,
    fulladdress,
    hits,
    recorder,
    rentEstimate,
    saleEstimate,
  } = data;

  return (
    <div className="flex flex-col gap-y-1">
      <Field label="AUPID" value={aupid} />
      <Field label="Address" value={fulladdress} />
      <Field label="Hits" value={hits} />
      <AssessorData data={assessor} />
      {saleEstimate && <SaleEstimateData data={saleEstimate} />}
      {rentEstimate && <RentEstimateData data={rentEstimate} />}
      {recorder && <RecorderData data={recorder[0]} />}
    </div>
  );
}

export default function AbodeMineTest() {
  const [address, setAddress] = useState('');
  const [searchAddress, { data, error, isLoading }] = useAbodeMineTestMutation();

  return (
    <div className="h-screen w-full bg-gray-100 pt-12 px-12">
      <div className="h-full bg-white rounded p-6 overflow-y-scroll">
        <h1 className="mb-12 text-gray-900 text-lg">Abode Mine API Test</h1>
        <div className="mb-16 flex items-center gap-x-8">
          <div className="flex items-center w-96">
            <span className="z-10 font-normal absolute text-center text-slate-300 rounded text-base items-center justify-center w-8 pl-3 py-2.5">
              <Search className="text-black w-6 h-6" />
            </span>
            <input
              value={address}
              onChange={(e) => setAddress(parseEventValue(e))}
              type="text"
              placeholder="Enter a full property address"
              className="px-2 py-2.5 rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
            />
          </div>
          <Button
            filled
            onClick={() => searchAddress(address)}
            disabled={!address.length}
            label="Search"
            isLoading={isLoading}
          />
        </div>
        {(data || error) && <SearchResult data={data} error={error} />}
      </div>
    </div>
  );
}
