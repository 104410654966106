import cx from 'classnames';
import { createContext, useContext, useMemo } from 'react';

const NavigationRailContext = createContext(undefined);
NavigationRailContext.displayName = 'NavigationRailContext';

export const useNavigationRailVariant = () => {
  const { variant } = useContext(NavigationRailContext);
  return variant;
};

/**
 * @param {'brand' | 'default' | (string & {})} [variant]
 * @param {string} className
 * @param {import('react').ReactNode} children
 */
export default function NavigationRail({ variant, className, children, ...props }) {
  const contextValue = useMemo(() => ({ variant }), [variant]);

  return (
    <NavigationRailContext.Provider value={contextValue}>
      <nav
        {...props}
        className={cx(
          'flex flex-col items-center gap-y-4 py-3 flex-nowrap overflow-clip w-20 flex-none',
          {
            'bg-truliv text-white': variant === 'brand',
            'bg-white text-black': variant !== 'brand',
          },
          className,
        )}
      >
        {children}
      </nav>
    </NavigationRailContext.Provider>
  );
}

export function NavItemIcon({ children }) {
  const variant = useNavigationRailVariant();

  return (
    <div
      className={cx(
        'relative flex justify-center items-center w-14 h-8 rounded-full fill-current',
        {
          'group-aria-selected/nav:bg-gray-400 group-hover/nav:bg-gray-600': variant === 'brand',
          'group-aria-selected/nav:bg-primary-light group-hover/nav:bg-primary-light': variant !== 'brand',
        },
      )}
    >
      {children}
    </div>
  );
}

export function NavItemLabel({ children }) {
  const variant = useNavigationRailVariant();

  return (
    <div className={cx('text-xs', { 'font-medium': variant === 'brand' })}>
      {children}
    </div>
  );
}

/**
 * @param {boolean} active
 * @param {import('react').ReactNode} children
 * @param {import('react').ComponentPropsWithRef<'button'>} props
 */
export function ButtonNavItem({ active, children, ...props }) {
  return (
    <BaseNavItem {...props} Component="button" type="button" active={active}>
      {children}
    </BaseNavItem>
  );
}

/**
 * @param {boolean} active
 * @param {string} href
 * @param {import('react').ReactNode} children
 * @param {import('react').ComponentPropsWithRef<'a'>} props
 */
export function LinkNavItem({ active, href, children, ...props }) {
  return (
    <BaseNavItem {...props} Component="a" href={href} active={active}>
      {children}
    </BaseNavItem>
  );
}

/**
 * @template T
 * @param {T} Component
 * @param {boolean} active
 * @param className
 * @param {import('react').ReactNode} children
 * @param {import('react').ComponentPropsWithRef<T>} props
 */
function BaseNavItem({ Component, active, className, children, ...props }) {
  return (
    <Component
      {...props}
      className={cx('group/nav flex flex-col gap-y-1 items-center select-none', className)}
      aria-selected={active ? 'true' : 'false'}
    >
      {children}
    </Component>
  );
}
