import classNames from 'classnames';
import { SelectorIcon } from './icons';

function SelectOne({ className, selectClassName, name, valueProperties, onChange, width, height, padding, disabled = false, required = false, children }) {
  const selectClazz = classNames(
    selectClassName,
    padding || 'py-2 pl-3 pr-8',
    height,
    'w-full rounded focus:outline-none appearance-none border border-black border-opacity-12 truncate',
  );
  return (
    <div className={classNames('relative', width ?? 'w-24', className)}>
      <select
        className={selectClazz}
        name={name}
        {...valueProperties}
        onChange={onChange}
        disabled={disabled}
        required={required}
      >
        {children}
      </select>
      <div className="absolute inset-y-0 right-0 pl-2 pr-4 flex items-center rounded-r pointer-events-none">
        <SelectorIcon className="text-black text-opacity-36" />
      </div>
    </div>
  );
}

function SelectMultiple({ className, name, valueProperties, onChange, width, height, padding, disabled = false, children }) {
  const clazz = classNames(
    className,
    padding || 'py-2 px-3',
    width || 'w-24',
    height,
    'rounded shadow focus:outline-none',
  );

  return (
    <select multiple className={clazz} name={name} {...valueProperties} onChange={onChange} disabled={disabled}>
      {children}
    </select>
  );
}

// options is an array of [value, label, disabled]
// Note that the required attribute is only enforced under certain conditions, see: https://html.spec.whatwg.org/multipage/form-elements.html#attr-select-required
export default function Select({ className, selectClassName, defaultValue, name, value = '', multiple = false, options, onChange, width, height, padding, disabled = false, required = false }) {
  const selectOnChange = (event) => {
    onChange({
      target: {
        name: event.target.name,
        value: event.target.value === '' ? null : event.target.value,
      },
    });
  };
  const valueProperties = defaultValue ? { defaultValue } : { value };
  const SelectComponent = multiple ? SelectMultiple : SelectOne;

  return (
    <SelectComponent
      className={className}
      selectClassName={selectClassName}
      name={name}
      valueProperties={valueProperties}
      onChange={selectOnChange}
      disabled={disabled}
      required={required}
      width={width}
      height={height}
      padding={padding}
    >
      {options.map(([optValue, optLabel, optDisabled]) => <option key={optValue} value={optValue} disabled={optDisabled}>{optLabel}</option>)}
    </SelectComponent>
  );
}
