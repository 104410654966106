import cx from 'classnames';
import { LoadingIndicator } from 'components/icons';

export default function EmptyLoadingState({ height = 'h-screen', text = 'Loading...' }) {
  return (
    <div className={cx(height, 'flex flex-col items-center justify-center')}>
      <LoadingIndicator className="w-6 text-primary-500" />
      {text && <p className="mt-2 text-base text-gray-600">{text}</p>}
    </div>
  );
}
