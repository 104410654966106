import cx from 'classnames';
import { uniqBy } from 'lodash';
import { useCallback, useMemo, useState, useTransition } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useFetchUsersQuery } from '../../redux/apiSlice';

const styleOverride = {
  suggestions: {
    zIndex: null,
    backgroundColor: null,
  },
};

function renderSuggestions(children) {
  return (
    <div
      className={cx(
        'contents',
        String.raw`[.m-input\_\_suggestions:has(>&)]:text-body-md [.m-input\_\_suggestions:has(>&)]:text-neutral-dark [.m-input\_\_suggestions:has(>&)]:pt-1 [.m-input\_\_suggestions:has(>&)]:z-50`,
        String.raw`[&_.m-input\_\_suggestions\_\_list]:bg-white [&_.m-input\_\_suggestions\_\_list]:border [&_.m-input\_\_suggestions\_\_list]:rounded [&_.m-input\_\_suggestions\_\_list]:overflow-clip [&_.m-input\_\_suggestions\_\_list]:shadow-md [&_.m-input\_\_suggestions\_\_list]:divide-y`,
        String.raw`[&_.m-input\_\_suggestions\_\_item]:px-2 [&_.m-input\_\_suggestions\_\_item]:py-1 [&_.m-input\_\_suggestions\_\_item--focused]:bg-primary-focus hover:[&_.m-input\_\_suggestions\_\_item]:bg-primary-hover`,
      )}
    >
      {children}
    </div>
  );
}

export default function MentionUsersTextarea({
  value,
  onChange,
  organizationId = undefined,
  additionalUsers = undefined,
  includeInactive = false,
  ...inputProps
}) {
  const [input, setInput] = useState('');
  const [isPending, startTransition] = useTransition();
  const onInputChange = useCallback((...props) => {
    const [, updatedValue] = props;
    setInput(updatedValue);
    startTransition(() => onChange?.(...props));
  }, [onChange]);

  const { currentData: users, isLoading = true } = useFetchUsersQuery({ organizationId, showInactive: false });
  const userOptions = useMemo(() => {
    const allUsersOptions = users
      ?.map(({ email, fullName }) => ({ id: email, display: fullName ?? email }))
      ?.concat(additionalUsers?.map(({ email, fullName }) => ({ id: email, display: fullName ?? email })) ?? [])
      ?.filter(({ deletedAt }) => includeInactive || !deletedAt);

    return uniqBy(allUsersOptions ?? [], 'id');
  }, [users, additionalUsers, includeInactive]);

  return (
    <MentionsInput
      {...inputProps}
      value={isPending ? input : value}
      onChange={onInputChange}
      suggestionsPortalHost={document.body}
      customSuggestionsContainer={renderSuggestions}
      style={styleOverride}
      className={cx(
        'm-input',
        String.raw`[&_.m-input\_\_control]:min-h-20 [&_.m-input\_\_control]:text-body-md [&_.m-input\_\_control]:text-neutral-dark`,
        String.raw`[&_.m-input\_\_highlighter]:p-1`,
        String.raw`[&_.m-input\_\_input]:p-1 [&_.m-input\_\_input]:placeholder:text-neutral-light [&_.m-input\_\_input]:border [&_.m-input\_\_input]:rounded [&_.m-input\_\_input]:shadow-sm`,
      )}
    >
      <Mention className="mention bg-primary-darken/50" data={userOptions} isLoading={isLoading} />
    </MentionsInput>
  );
}
