import { useState, useMemo } from 'react';
import { LoadingIndicator, X } from 'components/icons';
import { LAYOUT } from 'components/constants';
import { isEmpty } from 'lodash';
import { Notification } from './NotificationTypes';
import { groupedNotifications } from './notification.utils';
import { NOTIFICATION_TABS } from './notification.constant';

function DrawerHeader({ setShowNotification }) {
  return (
    <div className="h-16 mb-2 flex justify-between px-6 py-4.5">
      <div className="text-neutral-dark font-medium text-xl">Notifications</div>
      <X className="h-4 w-4 cursor-pointer" onClick={() => setShowNotification(false)} />
    </div>
  );
}

function TabBar({ activeTab, setActiveTab }) {
  return (
    <div className="h-12 grid grid-cols-3 text-sm font-medium text-neutral-dark">
      {NOTIFICATION_TABS.map((tab) => (
        <div
          key={tab}
          className={`h-full w-full py-3 text-center cursor-pointer ${activeTab === tab ? 'border-b-4 border-primary-dark' : 'border-b border-gray-200'}`}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
}

function NotificationList({ notifications }) {
  return (
    <div className="px-3 flex flex-col">
      {notifications.map((notification, index) => (
        <Notification key={index} notification={notification} />
      ))}
    </div>
  );
}

function NotificationSection({ title, notifications }) {
  return !isEmpty(notifications) && (
    <>
      <div className="px-4 pb-2 pt-6 text-neutral-dark text-sm font-medium">{title}</div>
      <NotificationList notifications={notifications} />
    </>
  );
}

function NoNotifications() {
  return (
    <div className="text-center mt-12">
      <div className="text-2xl text-neutral-black">No Notifications</div>
      <div className="text-sm text-neutral-light mt-4">Your notifications will appear here</div>
    </div>
  );
}

function NotificationFetching() {
  return (
    <div className="text-center mt-12">
      <LoadingIndicator className="inline h-6" />
      <div className="text-sm text-neutral-light mt-4">Loading notifications...</div>
    </div>
  );
}

function DrawerLayout({ activeTab, setActiveTab, setShowNotification, children }) {
  return (
    <div className="fixed h-full ml-20 left-0 top-0 flex drop-shadow-2xl" style={{ marginTop: '0px' }}>
      <div className="bg-white z-30 border-t border-r border-gray-200 shadow-xl" style={{ width: `${LAYOUT.notificationDrawerWidth}px` }}>
        <DrawerHeader setShowNotification={setShowNotification} />
        <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
        {children}
      </div>
      <div style={{ width: `calc(100vw - ${LAYOUT.notificationDrawerWidth + 80}px)` }} onClick={() => setShowNotification(false)} />
    </div>
  );
}

export default function NotificationsDrawer({ contentRef, isFetching, notifications = [], setShowNotification }) {
  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS[0]);
  const updatedNotifications = useMemo(() => groupedNotifications(activeTab, notifications), [activeTab, notifications]);
  const noNotificationsAvailable = (isEmpty(notifications) && !isFetching) || updatedNotifications.empty;

  return (
    <DrawerLayout activeTab={activeTab} setActiveTab={setActiveTab} setShowNotification={setShowNotification}>
      <div ref={contentRef} className="overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 140px)' }}>
        {noNotificationsAvailable && <NoNotifications />}
        <NotificationSection title="Today" notifications={updatedNotifications.todays} />
        <NotificationSection title="Past 7 Days" notifications={updatedNotifications.pastSevenDays} />
        <NotificationSection title="Earlier" notifications={updatedNotifications.earlier} />
        {isFetching && <NotificationFetching />}
      </div>
    </DrawerLayout>
  );
}
