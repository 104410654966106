import { useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import OrganizationHeader from 'components/organization/OrganizationHeader';
import OrganizationIndex from 'components/organization/OrganizationIndex';
import OrganizationNew from 'components/organization/OrganizationNew';
import OrganizationShow from 'components/organization/OrganizationShow';
import { useFetchOrganizationsQuery } from 'redux/organizationApiSlice';
import useElementHeight from 'hooks/useElementHeight';

export default function OrganizationBase() {
  const headerRef = useRef();
  const headerHeight = useElementHeight(headerRef);
  const location = useLocation();
  const { data: organizations, isLoading } = useFetchOrganizationsQuery();

  const organizationId = (location.pathname.split('/').length === 3) && location.pathname.split('/')[2];
  const isNew = organizationId === 'new';
  const organization = organizationId && !isNew && organizations?.find(o => o.id.toString() === organizationId);

  return (
    <div className="h-screen bg-gray-100">
      <OrganizationHeader organization={organization} isNew={isNew} elementRef={headerRef} />
      <div className="overflow-y-scroll" style={{ height: `calc(100vh - ${headerHeight}px)` }}>
        <Routes>
          <Route path="/" element={<OrganizationIndex isLoading={isLoading} organizations={organizations} headerHeight={headerHeight} />} />
          <Route path="/new" element={<OrganizationNew />} />
          <Route path="/:organizationId" element={<OrganizationShow isLoading={isLoading} organization={organization} />} />
        </Routes>
      </div>
    </div>
  );
}
