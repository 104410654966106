import { generatePath } from 'react-router-dom';
import { listingNoteApiPath } from 'components/routes';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice } from './apiSlice';
import { LISTING_NOTES_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchListingNotes: builder.query({
        query: ({ listingId }) => generatePath(listingNoteApiPath, { listingId }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: [LISTING_NOTES_TAG],
      }),
      createListingNotes: builder.mutation({
        query: ({ listingId, body }) => ({
          url: generatePath(listingNoteApiPath, { listingId }),
          method: 'POST',
          body: snakeCaseKeys({ body }),
        }),
        invalidatesTags: [LISTING_NOTES_TAG],
      }),
    };
  },
});

export const {
  useFetchListingNotesQuery,
  useCreateListingNotesMutation,
} = extendedApiSlice;
