import { DataTableContent } from 'components/shared/Table/DataTableContext';
import OffMarketOfferTable from './OffMarketOfferTable';
import OffMarketOfferTableHeader from './OffMarketOfferTableHeader';

export default function OffMarketOffers() {
  return (
    <div className="flex flex-col h-container w-container">
      <OffMarketOfferTable>
        <OffMarketOfferTableHeader />
        <div className="flex flex-row flex-1 [container-type:size] rounded-l-xl overflow-clip">
          <DataTableContent />
        </div>
      </OffMarketOfferTable>
    </div>
  );
}
