import { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { compact } from 'lodash';
import cx from 'classnames';
import { useAcceptAgreementsMutation } from 'redux/apiSlice';
import Modal from 'components/Modal';
import Button from 'components/shared/NewButton';
import { CheckCircle } from 'components/icons';
import { HTML as compensationHtml, VERSION as compensationVersion } from './agreements/compensation';
import { HTML as ndaHtml, VERSION as ndaVersion } from './agreements/nda';
import { HTML as nonCircumventionHtml, VERSION as nonCircumventionVersion } from './agreements/nonCircumvention';

function AgreementSection({ label, agreement, version, checked, setChecked }) {
  const onAgree = (close) => () => {
    close();
    setChecked();
  };

  return (
    <Disclosure>
      {({ close, open }) => (
        <>
          <Disclosure.Button className={cx('w-full flex justify-between p-3 rounded cursor-pointer hover:bg-gray-100', { 'bg-gray-100': open })}>
            <div>{label}</div>
            <CheckCircle className={cx('size-7', { 'text-success-700': checked })} filled={checked} />
          </Disclosure.Button>
          <Disclosure.Panel className="max-h-96 rounded overflow-y-scroll">
            <div className="p-3 bg-gray-50">
              <div className="flex justify-end italic text-sm font-light text-gray-700">
                {`Agreement Version: ${version}`}
              </div>
              <div className="py-3">
                {agreement}
              </div>
            </div>
            <div className="p-3 bg-gray-200">
              <label className="font-medium flex items-center cursor-pointer">
                <input
                  readOnly
                  className="mr-2"
                  type="checkbox"
                  name={label}
                  checked={checked}
                  onClick={onAgree(close)}
                />
                {`By clicking the checkbox, I agree to the terms of this ${label}`}
              </label>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default function ConsentModal() {
  const [consents, setConsents] = useState({
    compensation: false,
    nda: false,
    nonCircumvention: false,
  });

  const [acceptAgreements, { isLoading }] = useAcceptAgreementsMutation();
  const onAccept = async () => {
    await acceptAgreements();
    // use a browser refresh because the trigger to show the modal
    // is based on props passed from the view, so using the redux
    // invalidates tag does not work in this instance
    window.location.reload();
  };

  return (
    <Modal show showCloseAction={false} width="w-3/4">
      <div className="text-gray-900">
        <h1 className="text-2xl">The Hive - Agreements</h1>
        <p className="my-6">To participate in The Hive marketplace, please review and accept the following agreements:</p>
        <div className="divide-y">
          <AgreementSection
            label="Compensation Agreement"
            agreement={compensationHtml}
            version={compensationVersion}
            checked={consents.compensation}
            setChecked={() => setConsents({ ...consents, compensation: !consents.compensation })}
          />
          <AgreementSection
            label="Mutual Non-Disclosure Agreement"
            agreement={ndaHtml}
            version={ndaVersion}
            checked={consents.nda}
            setChecked={() => setConsents({ ...consents, nda: !consents.nda })}
          />
          <AgreementSection
            label="Non-Circumvention Agreement"
            agreement={nonCircumventionHtml}
            version={nonCircumventionVersion}
            checked={consents.nonCircumvention}
            setChecked={() => setConsents({ ...consents, nonCircumvention: !consents.nonCircumvention })}
          />
        </div>
        <div className="mt-6 w-full flex justify-center">
          <Button
            filled
            label="Confirm and Sign"
            disabled={compact(Object.values(consents)).length < 3}
            isLoading={isLoading}
            onClick={onAccept}
          />
        </div>
      </div>
    </Modal>
  );
}
